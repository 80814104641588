@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&amp;display=swap');
// @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

@import './icons.scss';

// Light Theme
@import './bootstrap.scss';
@import './app.scss';

@font-face {
  font-family: 'MYRIADPRO';
  src: url(../../assets/fonts/myriad-pro/MYRIADPRO-REGULAR.OTF);
}

@font-face {
  font-family: 'MYRIADPRO';
  src: url(../../assets/fonts/myriad-pro//MYRIADPRO-BOLD.OTF);
  font-weight: bold;
}

body {
  font-family: 'MYRIADPRO';
  padding: 0 !important;
}

// @import url(../vendor/bootstrap/icons/bootstrap-icons.css);
// @import url(../vendor/fontawesome/css/all.min.css);
// @import url(../vendor/jarallax/jarallax.css);
// @import url(../vendor/magnific/magnific-popup.css);
// @import url(../vendor/owl-carousel/css/owl.carousel.min.css);
// @import url(../vendor/themify-icons/themify-icons.css);
// @import url(../vendor/et-line/style.css);
// @import url(../vendor/wow/animate.css);

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #ffffff;
  --bs-gray: #718096;
  --bs-gray-dark: #2d3748;
  --bs-primary: #01a310;
  --bs-secondary: #15db95;
  --bs-success: #2dca8c;
  --bs-info: #50b5ff;
  --bs-warning: #ff9f1c;
  --bs-danger: #ff5c75;
  --bs-light: #f1f6fd;
  --bs-dark: #112233;
  /* --bs-font-sans-serif: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; */
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: 'MYRIADPRO';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #718096;
  background-color: #ffffff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #112233;
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #01a310;
  text-decoration: none;
}
a:hover {
  color: #0029a3;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
.fs-22 {
  font-size: 22px;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #273444;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

.container-fuild {
  margin: 0 5%;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #718096;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
.text-justify {
  text-align: justify;
}
label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.pl-10 {
  padding-left: 10%;
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/

a {
  color: #062575;
  text-decoration: none;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none;
}
.face-icon {
  position: absolute;
  right: 4%;
  top: 5px;
}
#home h1 {
  font-family: 'MYRIADPRO';
}
#about h3 {
  font-family: 'MYRIADPRO';
}
#about p {
  font-size: 22px;
  font-family: 'MYRIADPRO';
  color: #000;
  text-align: justify;
  line-height: 35px;
}
.bg-gradient-color {
  background-image: linear-gradient(to right, #01a310, #062575);
}
.mb-12 {
  margin-bottom: 8rem;
}
.land-footer {
  background: #fff;
  padding: 15px 0;
  position: absolute;
  bottom: 0;
}
.ra-10 {
  border-radius: 10px;
}
.land-footer p {
  color: #000;
}
.lead {
  font-size: 22px;
  font-weight: 400;
  color: #000;
  text-align: justify;
  font-family: 'MYRIADPRO';
  line-height: 35px;
  /* color:#6f6767 ; */
}

.pty-10 {
  padding-top: 5rem;
}
.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #718096;
}
.blockquote-footer::before {
  content: '\2014\00A0';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #718096;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 2.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 2.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 3.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 3.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 4rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 4rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 5rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 6rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 6rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 7rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 7rem;
}

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 7rem;
  }
  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 7rem;
  }
}

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 3rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 3rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 4rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 4rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 5rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 5rem;
  }
  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 6rem;
  }
  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 6rem;
  }
  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 7rem;
  }
  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 7rem;
  }
}

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 7rem;
  }
  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 7rem;
  }
}

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 7rem;
  }
  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 7rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 7rem;
  }
  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 7rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #718096;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #718096;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #718096;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #718096;
  vertical-align: top;
  border-color: #e2e8f0;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #ccd6f5;
  --bs-table-striped-bg: #c2cbe9;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #b8c1dd;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #bdc6e3;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #b8c1dd;
}

.table-secondary {
  --bs-table-bg: #d0f8ea;
  --bs-table-striped-bg: #c6ecde;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bbdfd3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c0e5d8;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #bbdfd3;
}

.table-success {
  --bs-table-bg: #d5f4e8;
  --bs-table-striped-bg: #cae8dc;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #c0dcd1;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c5e2d7;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #c0dcd1;
}

.table-info {
  --bs-table-bg: #dcf0ff;
  --bs-table-striped-bg: #d1e4f2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #c6d8e6;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ccdeec;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #c6d8e6;
}

.table-warning {
  --bs-table-bg: #ffecd2;
  --bs-table-striped-bg: #f2e0c8;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6d4bd;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ecdac2;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #e6d4bd;
}

.table-danger {
  --bs-table-bg: #ffdee3;
  --bs-table-striped-bg: #f2d3d8;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6c8cc;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #eccdd2;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #e6c8cc;
}

.table-light {
  --bs-table-bg: #f1f6fd;
  --bs-table-striped-bg: #e5eaf0;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #d9dde4;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #dfe4ea;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #d9dde4;
}

.table-dark {
  --bs-table-bg: #112233;
  --bs-table-striped-bg: #231f50;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #2e2b59;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #282555;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #2e2b59;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #112233;
  font-family: 'MYRIADPRO';
  font-size: 20px;
}
.text-white-51 {
  color: #ccc;
}
.col-form-label {
  padding-top: calc(0.65rem + 1px);
  padding-bottom: calc(0.65rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #718096;
}

.page-content .form-control {
  background-color: #efeaf9;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.65rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #062575;
  background-color: #efeaf9 !important;
  background-clip: padding-box;
  border: 1px solid #062575;
  appearance: none;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type='file'] {
  overflow: hidden;
}
.form-control[type='file']:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #062575;
  background-color: #ffffff !important;
  border-color: #062575;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(6, 37, 117, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #718096;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #eff2f7;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.65rem 1.25rem;
  margin: -0.65rem -1.25rem;
  margin-inline-end: 1.25rem;
  color: #718096;
  background-color: #eff2f7;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e3e6eb;
}
.form-control::-webkit-file-upload-button {
  padding: 0.65rem 1.25rem;
  margin: -0.65rem -1.25rem;
  margin-inline-end: 1.25rem;
  color: #718096;
  background-color: #eff2f7;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e3e6eb;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.65rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #718096;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.5rem 1.25rem;
  margin: -0.5rem -1.25rem;
  margin-inline-end: 1.25rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.5rem 1.25rem;
  margin: -0.5rem -1.25rem;
  margin-inline-end: 1.25rem;
}

.form-control-lg {
  min-height: calc(1.5em + (2rem + 2px));
  padding: 1rem 1.875rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 1rem 1.875rem;
  margin: -1rem -1.875rem;
  margin-inline-end: 1.875rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 1rem 1.875rem;
  margin: -1rem -1.875rem;
  margin-inline-end: 1.875rem;
}

textarea.form-control {
  min-height: calc(1.5em + (1.3rem + 2px));
}

textarea.form-control-sm {
  min-height: calc(1.5em + (1rem + 2px));
}

textarea.form-control-lg {
  min-height: calc(1.5em + (2rem + 2px));
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.65rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.65rem 3.75rem 0.65rem 1.25rem;
  -moz-padding-start: calc(1.25rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #718096;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232d3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: 16px 12px;
  border: 1px solid #cbd5e0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #8099e6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 51, 204, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size='1']) {
  padding-right: 1.25rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #eff2f7;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #718096;
}

.form-select-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.875rem;
  font-size: 1rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
}
.form-check-input[type='checkbox'] {
  border-radius: 0.25em;
}
.form-check-input[type='radio'] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8099e6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 51, 204, 0.25);
}
.form-check-input:checked {
  background-color: #01a310;
  border-color: #01a310;
}
.form-check-input:checked[type='checkbox'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type='radio'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type='checkbox']:indeterminate {
  background-color: #01a310;
  border-color: #01a310;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238099e6'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(0, 51, 204, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(0, 51, 204, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #01a310;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3c2f0;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e2e8f0;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #01a310;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3c2f0;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e2e8f0;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #a0aec0;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #a0aec0;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1.25rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 1.25rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  /* display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;  */
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.65rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #718096;
  text-align: center;
  white-space: nowrap;
  background-color: #eff2f7;
  border: 1px solid #cbd5e0;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1rem 1.875rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 5rem;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #2dca8c;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(45, 202, 140, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #2dca8c;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232dca8c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #2dca8c;
  box-shadow: 0 0 0 0.25rem rgba(45, 202, 140, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.3rem);
  background-position: top calc(0.375em + 0.325rem) right
    calc(0.375em + 0.325rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #2dca8c;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size='1'],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size='1'] {
  padding-right: 6.875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232d3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232dca8c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1.25rem center, center right 3.75rem;
  background-size: 16px 12px, calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #2dca8c;
  box-shadow: 0 0 0 0.25rem rgba(45, 202, 140, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #2dca8c;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #2dca8c;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(45, 202, 140, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #2dca8c;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ff5c75;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(255, 92, 117, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #ff5c75;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5c75'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5c75' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #ff5c75;
  box-shadow: 0 0 0 0.25rem rgba(255, 92, 117, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.3rem);
  background-position: top calc(0.375em + 0.325rem) right
    calc(0.375em + 0.325rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #ff5c75;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'] {
  padding-right: 6.875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232d3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5c75'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5c75' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1.25rem center, center right 3.75rem;
  background-size: 16px 12px, calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #ff5c75;
  box-shadow: 0 0 0 0.25rem rgba(255, 92, 117, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #ff5c75;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #ff5c75;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 92, 117, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #ff5c75;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #718096;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.65rem 1.25rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #718096;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 51, 204, 0.25);
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #ffffff;
  background-color: #01a310;
  border-color: #01a310;
  border-radius: 8px;
  box-shadow: 0px 4px 4px #00000040 !important;
}

.btn-primary:hover {
  color: #ffffff;
  background-color: #002bad;
  border-color: #0029a3;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #ffffff;
  background-color: #002bad;
  border-color: #0029a3;
  box-shadow: 0 0 0 0.25rem rgba(38, 82, 212, 0.5);
}
.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #0029a3;
  border-color: #002699;
}
.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 82, 212, 0.5);
}
.btn-primary:disabled,
.btn-primary.disabled {
  color: #ffffff;
  background-color: #01a310;
  border-color: #01a310;
}

.btn-secondary {
  color: #ffffff;
  background-color: #0029a3;
  border-color: #0029a3;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #0029a3;
  border-color: #0029a3;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #ffffff;
  background-color: #0029a3;
  border-color: #0029a3;
  box-shadow: none;
}
.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #0029a3;
  border-color: #0029a3;
}
.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}
.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #ffffff;
  background-color: #15db95;
  border-color: #15db95;
}

.btn-success {
  color: #ffffff;
  background-color: #2dca8c;
  border-color: #2dca8c;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #26ac77;
  border-color: #24a270;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #ffffff;
  background-color: #26ac77;
  border-color: #24a270;
  box-shadow: 0 0 0 0.25rem rgba(77, 210, 157, 0.5);
}
.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #24a270;
  border-color: #229869;
}
.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(77, 210, 157, 0.5);
}
.btn-success:disabled,
.btn-success.disabled {
  color: #ffffff;
  background-color: #2dca8c;
  border-color: #2dca8c;
}

.btn-info {
  color: #ffffff;
  background-color: #50b5ff;
  border-color: #50b5ff;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #449ad9;
  border-color: #4091cc;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #ffffff;
  background-color: #449ad9;
  border-color: #4091cc;
  box-shadow: 0 0 0 0.25rem rgba(106, 192, 255, 0.5);
}
.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #4091cc;
  border-color: #3c88bf;
}
.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(106, 192, 255, 0.5);
}
.btn-info:disabled,
.btn-info.disabled {
  color: #ffffff;
  background-color: #50b5ff;
  border-color: #50b5ff;
}

.btn-warning {
  color: #ffffff;
  background-color: #ff9f1c;
  border-color: #ff9f1c;
}
.btn-warning:hover {
  color: #ffffff;
  background-color: #d98718;
  border-color: #cc7f16;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #ffffff;
  background-color: #d98718;
  border-color: #cc7f16;
  box-shadow: 0 0 0 0.25rem rgba(255, 173, 62, 0.5);
}
.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning,
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #cc7f16;
  border-color: #bf7715;
}
.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 173, 62, 0.5);
}
.btn-warning:disabled,
.btn-warning.disabled {
  color: #ffffff;
  background-color: #ff9f1c;
  border-color: #ff9f1c;
}

.btn-danger {
  color: #ffffff;
  background-color: #ff5c75;
  border-color: #ff5c75;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #d94e63;
  border-color: #cc4a5e;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #ffffff;
  background-color: #d94e63;
  border-color: #cc4a5e;
  box-shadow: 0 0 0 0.25rem rgba(255, 116, 138, 0.5);
}
.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #cc4a5e;
  border-color: #bf4558;
}
.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 116, 138, 0.5);
}
.btn-danger:disabled,
.btn-danger.disabled {
  color: #ffffff;
  background-color: #ff5c75;
  border-color: #ff5c75;
}

.btn-light {
  color: #000000;
  background-color: #f1f6fd;
  border-color: #f1f6fd;
}
.btn-light:hover {
  color: #000000;
  background-color: #f3f7fd;
  border-color: #f2f7fd;
}
.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000000;
  background-color: #f3f7fd;
  border-color: #f2f7fd;
  box-shadow: 0 0 0 0.25rem rgba(205, 209, 215, 0.5);
}
.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000000;
  background-color: #f4f8fd;
  border-color: #f2f7fd;
}
.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(205, 209, 215, 0.5);
}
.btn-light:disabled,
.btn-light.disabled {
  color: #000000;
  background-color: #f1f6fd;
  border-color: #f1f6fd;
}

.btn-dark {
  color: #ffffff;
  background-color: #112233;
  border-color: #112233;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #14103c;
  border-color: #120f39;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #ffffff;
  background-color: #14103c;
  border-color: #120f39;
  box-shadow: 0 0 0 0.25rem rgba(58, 54, 99, 0.5);
}
.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #120f39;
  border-color: #110e35;
}
.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(58, 54, 99, 0.5);
}
.btn-dark:disabled,
.btn-dark.disabled {
  color: #ffffff;
  background-color: #112233;
  border-color: #112233;
}

.btn-outline-primary {
  color: #01a310;
  border-color: #01a310;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #01a310;
  border-color: #01a310;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 51, 204, 0.5);
}
.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #01a310;
  border-color: #01a310;
}
.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 51, 204, 0.5);
}
.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #01a310;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #15db95;
  border-color: #15db95;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #15db95;
  border-color: #15db95;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(21, 219, 149, 0.5);
}
.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #15db95;
  border-color: #15db95;
}
.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(21, 219, 149, 0.5);
}
.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #15db95;
  background-color: transparent;
}

.btn-outline-success {
  color: #2dca8c;
  border-color: #2dca8c;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #2dca8c;
  border-color: #2dca8c;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(45, 202, 140, 0.5);
}
.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #ffffff;
  background-color: #2dca8c;
  border-color: #2dca8c;
}
.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(45, 202, 140, 0.5);
}
.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #2dca8c;
  background-color: transparent;
}

.btn-outline-info {
  color: #50b5ff;
  border-color: #50b5ff;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #50b5ff;
  border-color: #50b5ff;
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(80, 181, 255, 0.5);
}
.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #ffffff;
  background-color: #50b5ff;
  border-color: #50b5ff;
}
.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(80, 181, 255, 0.5);
}
.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #50b5ff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ff9f1c;
  border-color: #ff9f1c;
}
.btn-outline-warning:hover {
  color: #ffffff;
  background-color: #ff9f1c;
  border-color: #ff9f1c;
}
.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 159, 28, 0.5);
}
.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #ffffff;
  background-color: #ff9f1c;
  border-color: #ff9f1c;
}
.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 159, 28, 0.5);
}
.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #ff9f1c;
  background-color: transparent;
}

.btn-outline-danger {
  color: #ff5c75;
  border-color: #ff5c75;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #ff5c75;
  border-color: #ff5c75;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 92, 117, 0.5);
}
.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #ffffff;
  background-color: #ff5c75;
  border-color: #ff5c75;
}
.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 92, 117, 0.5);
}
.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #ff5c75;
  background-color: transparent;
}

.btn-outline-light {
  color: #f1f6fd;
  border-color: #f1f6fd;
}
.btn-outline-light:hover {
  color: #000000;
  background-color: #f1f6fd;
  border-color: #f1f6fd;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 246, 253, 0.5);
}
.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000000;
  background-color: #f1f6fd;
  border-color: #f1f6fd;
}
.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 246, 253, 0.5);
}
.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #f1f6fd;
  background-color: transparent;
}

.btn-outline-dark {
  color: #112233;
  border-color: #112233;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #112233;
  border-color: #112233;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(23, 19, 71, 0.5);
}
.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #ffffff;
  background-color: #112233;
  border-color: #112233;
}
.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(23, 19, 71, 0.5);
}
.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #112233;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #01a310;
  text-decoration: none;
}
.btn-link:hover {
  color: #0029a3;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #718096;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 1rem 1.875rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #718096;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #273444;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #232f3d;
  background-color: #eff2f7;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #01a310;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #a0aec0;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #718096;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #273444;
}

.dropdown-menu-dark {
  color: #e2e8f0;
  background-color: #2d3748;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #e2e8f0;
}
.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #ffffff;
  background-color: #01a310;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #a0aec0;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #e2e8f0;
}
.dropdown-menu-dark .dropdown-header {
  color: #a0aec0;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.40625rem;
  padding-left: 1.40625rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #01a310;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: #0029a3;
}
.nav-link.disabled {
  color: #718096;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e2e8f0;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #eff2f7 #eff2f7 #e2e8f0;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #718096;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #4a5568;
  background-color: #ffffff;
  border-color: #e2e8f0 #e2e8f0 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #01a310;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.425rem;
  padding-bottom: 0.425rem;
  margin-right: 1rem;
  font-size: 1.1rem;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: #112233;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #eff2f7;
  border-radius: 0.5rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card h6 {
  color: #fff;
}
.card h4 {
  color: #112233;
  font-family: 'MYRIADPRO';
}
.card p {
  color: #fff;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.75rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.75rem;
}

.card-header {
  padding: 0.625rem 1.75rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid #eff2f7;
}
.card-header:first-child {
  border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
}

.card-footer {
  padding: 0.625rem 1.75rem;
  background-color: transparent;
  border-top: 1px solid #eff2f7;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px);
}

.card-header-tabs {
  margin-right: -0.875rem;
  margin-bottom: -0.625rem;
  margin-left: -0.875rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.875rem;
  margin-left: -0.875rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.5rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #112233;
  text-align: left;
  background-color: #ffffff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #01a310;
  background-color: #ffffff;
  box-shadow: inset 0 -1px 0 #eff2f7;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2303c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23718096'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #ffffff;
  border: 1px solid #eff2f7;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #718096;
  content: var(--bs-breadcrumb-divider, '/');
}

.breadcrumb-item.active {
  color: #718096;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #01a310;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #0029a3;
  background-color: #eff2f7;
  border-color: #e2e8f0;
}
.page-link:focus {
  z-index: 3;
  color: #0029a3;
  background-color: #eff2f7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 51, 204, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #01a310;
  border-color: #01a310;
}

.page-item.disabled .page-link {
  color: #718096;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #e2e8f0;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #001f7a;
  background-color: #ccd6f5;
  border-color: #b3c2f0;
}
.alert-primary .alert-link {
  color: #001962;
}

.alert-secondary {
  color: #0d8359;
  background-color: #d0f8ea;
  border-color: #b9f4df;
}
.alert-secondary .alert-link {
  color: #0a6947;
}

.alert-success {
  color: #1b7954;
  background-color: #d5f4e8;
  border-color: #c0efdd;
}
.alert-success .alert-link {
  color: #166143;
}

.alert-info {
  color: #306d99;
  background-color: #dcf0ff;
  border-color: #cbe9ff;
}
.alert-info .alert-link {
  color: #26577a;
}

.alert-warning {
  color: #995f11;
  background-color: #ffecd2;
  border-color: #ffe2bb;
}
.alert-warning .alert-link {
  color: #7a4c0e;
}

.alert-danger {
  color: #993746;
  background-color: #ffdee3;
  border-color: #ffced6;
}
.alert-danger .alert-link {
  color: #7a2c38;
}

.alert-light {
  color: #919498;
  background-color: #fcfdff;
  border-color: #fbfcfe;
}
.alert-light .alert-link {
  color: #74767a;
}

.alert-dark {
  color: #0e0b2b;
  background-color: #d1d0da;
  border-color: #b9b8c8;
}
.alert-dark .alert-link {
  color: #0b0922;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #eff2f7;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #01a310;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, '.') '. ';
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #4a5568;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #4a5568;
  text-decoration: none;
  background-color: #f1f6fd;
}
.list-group-item-action:active {
  color: #718096;
  background-color: #eff2f7;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.8rem 1rem;
  color: #273444;
  background-color: #ffffff;
  border: 1px solid #eff2f7;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #718096;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff !important;
  background-color: #01a310;
  border-color: #01a310;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #001f7a;
  background-color: #ccd6f5;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #001f7a;
  background-color: #b8c1dd;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #001f7a;
  border-color: #001f7a;
}

.list-group-item-secondary {
  color: #0d8359;
  background-color: #d0f8ea;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #0d8359;
  background-color: #bbdfd3;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #0d8359;
  border-color: #0d8359;
}

.list-group-item-success {
  color: #1b7954;
  background-color: #d5f4e8;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #1b7954;
  background-color: #c0dcd1;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #1b7954;
  border-color: #1b7954;
}

.list-group-item-info {
  color: #306d99;
  background-color: #dcf0ff;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #306d99;
  background-color: #c6d8e6;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #306d99;
  border-color: #306d99;
}

.list-group-item-warning {
  color: #995f11;
  background-color: #ffecd2;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #995f11;
  background-color: #e6d4bd;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #995f11;
  border-color: #995f11;
}

.list-group-item-danger {
  color: #993746;
  background-color: #ffdee3;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #993746;
  background-color: #e6c8cc;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #993746;
  border-color: #993746;
}

.list-group-item-light {
  color: #919498;
  background-color: #fcfdff;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #919498;
  background-color: #e3e4e6;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #919498;
  border-color: #919498;
}

.list-group-item-dark {
  color: #0e0b2b;
  background-color: #d1d0da;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #0e0b2b;
  background-color: #bcbbc4;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #0e0b2b;
  border-color: #0e0b2b;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 51, 204, 0.25);
  opacity: 1;
}
.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
  border-radius: 0.25rem;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #718096;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: inherit;
  pointer-events: auto;
  background-color: inherit;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e2e8f0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e2e8f0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^='top'] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^='right'] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^='bottom'] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^='left'] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #112233;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #718096;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}

.carousel-dark .carousel-caption {
  color: #000000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.link-primary {
  color: #01a310;
}
.link-primary:hover,
.link-primary:focus {
  color: #0029a3;
}

.link-secondary {
  color: #15db95;
}
.link-secondary:hover,
.link-secondary:focus {
  color: #11af77;
}

.link-success {
  color: #2dca8c;
}
.link-success:hover,
.link-success:focus {
  color: #24a270;
}

.link-info {
  color: #50b5ff;
}
.link-info:hover,
.link-info:focus {
  color: #4091cc;
}

.link-warning {
  color: #ff9f1c;
}
.link-warning:hover,
.link-warning:focus {
  color: #cc7f16;
}

.link-danger {
  color: #ff5c75;
}
.link-danger:hover,
.link-danger:focus {
  color: #cc4a5e;
}

.link-light {
  color: #f1f6fd;
}
.link-light:hover,
.link-light:focus {
  color: #f4f8fd;
}

.link-dark {
  color: #112233;
}
.link-dark:hover,
.link-dark:focus {
  color: #120f39;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: '';
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: '';
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}

.float-none {
  float: none;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow {
  box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-none {
  box-shadow: none;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-50 {
  bottom: 50%;
}

.bottom-100 {
  bottom: 100%;
}

.start-0 {
  left: 0;
}

.start-50 {
  left: 50%;
}

.start-100 {
  left: 100%;
}

.end-0 {
  right: 0;
}

.end-50 {
  right: 50%;
}

.end-100 {
  right: 100%;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.translate-middle-x {
  transform: translateX(-50%);
}

.translate-middle-y {
  transform: translateY(-50%);
}

.border {
  border: 1px solid #e2e8f0;
}

.border-0 {
  border: 0;
}

.border-top {
  border-top: 1px solid #e2e8f0;
}

.border-top-0 {
  border-top: 0;
}

.border-end {
  border-right: 1px solid #e2e8f0;
}

.border-end-0 {
  border-right: 0;
}

.border-bottom {
  border-bottom: 1px solid #e2e8f0;
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-start {
  border-left: 1px solid #e2e8f0;
}

.border-start-0 {
  border-left: 0;
}

.border-primary {
  border-color: #062575;
}

.border-secondary {
  border-color: #15db95;
}

.border-success {
  border-color: #2dca8c;
}

.border-info {
  border-color: #50b5ff;
}

.border-warning {
  border-color: #ff9f1c;
}

.border-danger {
  border-color: #ff5c75;
}

.border-light {
  border-color: #f1f6fd;
}

.border-dark {
  border-color: #112233;
}

.border-white {
  border-color: #ffffff;
}

.border-0 {
  border-width: 0;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.border-6 {
  border-width: 6;
}

.border-7 {
  border-width: 7px;
}

.border-8 {
  border-width: 8px;
}

.border-9 {
  border-width: 9px;
}

.border-10 {
  border-width: 10px;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.vw-100 {
  width: 100vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-100 {
  max-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 2rem;
}

.gap-6 {
  gap: 2.5rem;
}

.gap-7 {
  gap: 3rem;
}

.gap-8 {
  gap: 3.5rem;
}

.gap-9 {
  gap: 4rem;
}

.gap-10 {
  gap: 5rem;
}

.gap-11 {
  gap: 6rem;
}

.gap-12 {
  gap: 7rem;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.order-first {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-last {
  order: 6;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 1rem;
}

.m-4 {
  margin: 1.5rem;
}

.m-5 {
  margin: 2rem;
}

.m-6 {
  margin: 2.5rem;
}

.m-7 {
  margin: 3rem;
}

.m-8 {
  margin: 3.5rem;
}

.m-9 {
  margin: 4rem;
}

.m-10 {
  margin: 5rem;
}

.m-11 {
  margin: 6rem;
}

.m-12 {
  margin: 7rem;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-1 {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.mx-2 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.mx-3 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.mx-4 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.mx-5 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.mx-6 {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.mx-7 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.mx-8 {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}

.mx-9 {
  margin-right: 4rem;
  margin-left: 4rem;
}

.mx-10 {
  margin-right: 5rem;
  margin-left: 5rem;
}

.mx-11 {
  margin-right: 6rem;
  margin-left: 6rem;
}

.mx-12 {
  margin-right: 7rem;
  margin-left: 7rem;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-6 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-7 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-8 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-9 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-10 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-11 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-12 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 2rem;
}

.mt-6 {
  margin-top: 2.5rem;
}

.mt-7 {
  margin-top: 3rem;
}

.mt-8 {
  margin-top: 3.5rem;
}

.mt-9 {
  margin-top: 4rem;
}

.mt-10 {
  margin-top: 5rem;
}

.mt-11 {
  margin-top: 6rem;
}

.mt-12 {
  margin-top: 7rem;
}

.mt-auto {
  margin-top: auto;
}

.me-0 {
  margin-right: 0;
}

.me-1 {
  margin-right: 0.25rem;
}

.me-2 {
  margin-right: 0.5rem;
}

.me-3 {
  margin-right: 1rem;
}

.me-4 {
  margin-right: 1.5rem;
}

.me-5 {
  margin-right: 2rem;
}

.me-6 {
  margin-right: 2.5rem;
}

.me-7 {
  margin-right: 3rem;
}

.me-8 {
  margin-right: 3.5rem;
}

.me-9 {
  margin-right: 4rem;
}

.me-10 {
  margin-right: 5rem;
}

.me-11 {
  margin-right: 6rem;
}

.me-12 {
  margin-right: 7rem;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2rem;
}

.mb-6 {
  margin-bottom: 2.5rem;
}

.mb-7 {
  margin-bottom: 3rem;
}

.mb-8 {
  margin-bottom: 3.5rem;
}

.mb-9 {
  margin-bottom: 4rem;
}

.mb-10 {
  margin-bottom: 5rem;
}

.mb-11 {
  margin-bottom: 6rem;
}

.mb-12 {
  margin-bottom: 7rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: 0.25rem;
}

.ms-2 {
  margin-left: 0.5rem;
}

.ms-3 {
  margin-left: 1rem;
}

.ms-4 {
  margin-left: 1.5rem;
}

.ms-5 {
  margin-left: 2rem;
}

.ms-6 {
  margin-left: 2.5rem;
}

.ms-7 {
  margin-left: 3rem;
}

.ms-8 {
  margin-left: 3.5rem;
}

.ms-9 {
  margin-left: 4rem;
}

.ms-10 {
  margin-left: 5rem;
}

.ms-11 {
  margin-left: 6rem;
}

.ms-12 {
  margin-left: 7rem;
}

.ms-auto {
  margin-left: auto;
}

.m-n1 {
  margin: -0.25rem;
}

.m-n2 {
  margin: -0.5rem;
}

.m-n3 {
  margin: -1rem;
}

.m-n4 {
  margin: -1.5rem;
}

.m-n5 {
  margin: -2rem;
}

.m-n6 {
  margin: -2.5rem;
}

.m-n7 {
  margin: -3rem;
}

.m-n8 {
  margin: -3.5rem;
}

.m-n9 {
  margin: -4rem;
}

.m-n10 {
  margin: -5rem;
}

.m-n11 {
  margin: -6rem;
}

.m-n12 {
  margin: -7rem;
}

.mx-n1 {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

.mx-n2 {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.mx-n3 {
  margin-right: -1rem;
  margin-left: -1rem;
}

.mx-n4 {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}

.mx-n5 {
  margin-right: -2rem;
  margin-left: -2rem;
}

.mx-n6 {
  margin-right: -2.5rem;
  margin-left: -2.5rem;
}

.mx-n7 {
  margin-right: -3rem;
  margin-left: -3rem;
}

.mx-n8 {
  margin-right: -3.5rem;
  margin-left: -3.5rem;
}

.mx-n9 {
  margin-right: -4rem;
  margin-left: -4rem;
}

.mx-n10 {
  margin-right: -5rem;
  margin-left: -5rem;
}

.mx-n11 {
  margin-right: -6rem;
  margin-left: -6rem;
}

.mx-n12 {
  margin-right: -7rem;
  margin-left: -7rem;
}

.my-n1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.my-n2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.my-n3 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.my-n4 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.my-n5 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.my-n6 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.my-n7 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.my-n8 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}

.my-n9 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.my-n10 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.my-n11 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.my-n12 {
  margin-top: -7rem;
  margin-bottom: -7rem;
}

.mt-n1 {
  margin-top: -0.25rem;
}

.mt-n2 {
  margin-top: -0.5rem;
}

.mt-n3 {
  margin-top: -1rem;
}

.mt-n4 {
  margin-top: -1.5rem;
}

.mt-n5 {
  margin-top: -2rem;
}

.mt-n6 {
  margin-top: -2.5rem;
}

.mt-n7 {
  margin-top: -3rem;
}

.mt-n8 {
  margin-top: -3.5rem;
}

.mt-n9 {
  margin-top: -4rem;
}

.mt-n10 {
  margin-top: -5rem;
}

.mt-n11 {
  margin-top: -6rem;
}

.mt-n12 {
  margin-top: -7rem;
}

.me-n1 {
  margin-right: -0.25rem;
}

.me-n2 {
  margin-right: -0.5rem;
}

.me-n3 {
  margin-right: -1rem;
}

.me-n4 {
  margin-right: -1.5rem;
}

.me-n5 {
  margin-right: -2rem;
}

.me-n6 {
  margin-right: -2.5rem;
}

.me-n7 {
  margin-right: -3rem;
}

.me-n8 {
  margin-right: -3.5rem;
}

.me-n9 {
  margin-right: -4rem;
}

.me-n10 {
  margin-right: -5rem;
}

.me-n11 {
  margin-right: -6rem;
}

.me-n12 {
  margin-right: -7rem;
}

.mb-n1 {
  margin-bottom: -0.25rem;
}

.mb-n2 {
  margin-bottom: -0.5rem;
}

.mb-n3 {
  margin-bottom: -1rem;
}

.mb-n4 {
  margin-bottom: -1.5rem;
}

.mb-n5 {
  margin-bottom: -2rem;
}

.mb-n6 {
  margin-bottom: -2.5rem;
}

.mb-n7 {
  margin-bottom: -3rem;
}

.mb-n8 {
  margin-bottom: -3.5rem;
}

.mb-n9 {
  margin-bottom: -4rem;
}

.mb-n10 {
  margin-bottom: -5rem;
}

.mb-n11 {
  margin-bottom: -6rem;
}

.mb-n12 {
  margin-bottom: -7rem;
}

.ms-n1 {
  margin-left: -0.25rem;
}

.ms-n2 {
  margin-left: -0.5rem;
}

.ms-n3 {
  margin-left: -1rem;
}

.ms-n4 {
  margin-left: -1.5rem;
}

.ms-n5 {
  margin-left: -2rem;
}

.ms-n6 {
  margin-left: -2.5rem;
}

.ms-n7 {
  margin-left: -3rem;
}

.ms-n8 {
  margin-left: -3.5rem;
}

.ms-n9 {
  margin-left: -4rem;
}

.ms-n10 {
  margin-left: -5rem;
}

.ms-n11 {
  margin-left: -6rem;
}

.ms-n12 {
  margin-left: -7rem;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.p-5 {
  padding: 2rem;
}

.p-6 {
  padding: 2.5rem;
}

.p-7 {
  padding: 3rem;
}

.p-8 {
  padding: 3.5rem;
}

.p-9 {
  padding: 4rem;
}

.p-10 {
  padding: 5rem;
}

.p-11 {
  padding: 6rem;
}

.p-12 {
  padding: 7rem;
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-1 {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.px-2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.px-3 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.px-4 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.px-5 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.px-6 {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.px-7 {
  padding-right: 3rem;
  padding-left: 3rem;
}

.px-8 {
  padding-right: 3.5rem;
  padding-left: 3.5rem;
}

.px-9 {
  padding-right: 4rem;
  padding-left: 4rem;
}

.px-10 {
  padding-right: 5rem;
  padding-left: 5rem;
}

.px-11 {
  padding-right: 6rem;
  padding-left: 6rem;
}

.px-12 {
  padding-right: 7rem;
  padding-left: 7rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-5 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-6 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-7 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-8 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-9 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-10 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-11 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-12 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pt-5 {
  padding-top: 2rem;
}

.pt-6 {
  padding-top: 2.5rem;
}

.pt-7 {
  padding-top: 3rem;
}

.pt-8 {
  padding-top: 3.5rem;
}

.pt-9 {
  padding-top: 4rem;
}

.pt-10 {
  padding-top: 5rem;
}

.pt-11 {
  padding-top: 6rem;
}

.pt-12 {
  padding-top: 7rem;
}

.pe-0 {
  padding-right: 0;
}

.pe-1 {
  padding-right: 0.25rem;
}

.pe-2 {
  padding-right: 0.5rem;
}

.pe-3 {
  padding-right: 1rem;
}

.pe-4 {
  padding-right: 1.5rem;
}

.pe-5 {
  padding-right: 2rem;
}

.pe-6 {
  padding-right: 2.5rem;
}

.pe-7 {
  padding-right: 3rem;
}

.pe-8 {
  padding-right: 3.5rem;
}

.pe-9 {
  padding-right: 4rem;
}

.pe-10 {
  padding-right: 5rem;
}

.pe-11 {
  padding-right: 6rem;
}

.pe-12 {
  padding-right: 7rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pb-5 {
  padding-bottom: 2rem;
}

.pb-6 {
  padding-bottom: 2.5rem;
}

.pb-7 {
  padding-bottom: 3rem;
}

.pb-8 {
  padding-bottom: 3.5rem;
}

.pb-9 {
  padding-bottom: 4rem;
}

.pb-10 {
  padding-bottom: 5rem;
}

.pb-11 {
  padding-bottom: 6rem;
}

.pb-12 {
  padding-bottom: 7rem;
}

.ps-0 {
  padding-left: 0;
}

.ps-1 {
  padding-left: 0.25rem;
}

.ps-2 {
  padding-left: 0.5rem;
}

.ps-3 {
  padding-left: 1rem;
}

.ps-4 {
  padding-left: 1.5rem;
}

.ps-5 {
  padding-left: 2rem;
}

.ps-6 {
  padding-left: 2.5rem;
}

.ps-7 {
  padding-left: 3rem;
}

.ps-8 {
  padding-left: 3.5rem;
}

.ps-9 {
  padding-left: 4rem;
}

.ps-10 {
  padding-left: 5rem;
}

.ps-11 {
  padding-left: 6rem;
}

.ps-12 {
  padding-left: 7rem;
}

.font-monospace {
  font-family: var(--bs-font-monospace);
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw);
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw);
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw);
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw);
}

.fs-5 {
  font-size: 1.25rem;
}

.fs-6 {
  font-size: 1rem;
}

.fst-italic {
  font-style: italic;
}

.fst-normal {
  font-style: normal;
}

.fw-light {
  font-weight: 300;
}

.fw-lighter {
  font-weight: lighter;
}

.fw-normal {
  font-weight: 400;
}

.fw-bold {
  font-weight: 700;
}

.fw-bolder {
  font-weight: bolder;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.25;
}

.lh-base {
  line-height: 1.5;
}

.lh-lg {
  line-height: 2;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

/* rtl:end:remove */
.text-primary {
  color: #062575;
}

.text-secondary {
  color: #15db95;
}

.text-success {
  color: #2dca8c;
}

.text-info {
  color: #50b5ff;
}

.text-warning {
  color: #ff9f1c;
}

.text-danger {
  color: #ff5c75;
}

.text-light {
  color: #f1f6fd;
}

.text-dark {
  color: #112233;
}

.text-white {
  color: #ffffff;
}

.text-body {
  color: #718096;
}

.text-muted {
  color: #718096;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.text-reset {
  color: inherit;
}

.bg-primary {
  background-color: #062575;
}

.bg-secondary {
  background-color: #15db95;
}

.bg-success {
  background-color: #2dca8c;
}

.bg-info {
  background-color: #50b5ff;
}

.bg-warning {
  background-color: #ff9f1c;
}

.bg-danger {
  background-color: #ff5c75;
}

.bg-light {
  background-color: #f1f6fd;
}

.bg-dark {
  background-color: #062575;
}

.bg-body {
  background-color: #ffffff;
}

.bg-white {
  background-color: #ffffff;
}

.bg-transparent {
  background-color: transparent;
}

.bg-gradient {
  background-image: var(--bs-gradient);
}

.user-select-all {
  user-select: all;
}

.user-select-auto {
  user-select: auto;
}

.user-select-none {
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: 0.2rem;
}

.rounded-2 {
  border-radius: 0.25rem;
}

.rounded-3 {
  border-radius: 0.3rem;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: 50rem;
}

.rounded-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-end {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left;
  }
  .float-sm-end {
    float: right;
  }
  .float-sm-none {
    float: none;
  }
  .d-sm-inline {
    display: inline;
  }
  .d-sm-inline-block {
    display: inline-block;
  }
  .d-sm-block {
    display: block;
  }
  .d-sm-grid {
    display: grid;
  }
  .d-sm-table {
    display: table;
  }
  .d-sm-table-row {
    display: table-row;
  }
  .d-sm-table-cell {
    display: table-cell;
  }
  .d-sm-flex {
    display: flex;
  }
  .d-sm-inline-flex {
    display: inline-flex;
  }
  .d-sm-none {
    display: none;
  }
  .flex-sm-fill {
    flex: 1 1 auto;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-sm-grow-0 {
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }
  .flex-sm-wrap {
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .gap-sm-0 {
    gap: 0;
  }
  .gap-sm-1 {
    gap: 0.25rem;
  }
  .gap-sm-2 {
    gap: 0.5rem;
  }
  .gap-sm-3 {
    gap: 1rem;
  }
  .gap-sm-4 {
    gap: 1.5rem;
  }
  .gap-sm-5 {
    gap: 2rem;
  }
  .gap-sm-6 {
    gap: 2.5rem;
  }
  .gap-sm-7 {
    gap: 3rem;
  }
  .gap-sm-8 {
    gap: 3.5rem;
  }
  .gap-sm-9 {
    gap: 4rem;
  }
  .gap-sm-10 {
    gap: 5rem;
  }
  .gap-sm-11 {
    gap: 6rem;
  }
  .gap-sm-12 {
    gap: 7rem;
  }
  .justify-content-sm-start {
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    justify-content: center;
  }
  .justify-content-sm-between {
    justify-content: space-between;
  }
  .justify-content-sm-around {
    justify-content: space-around;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }
  .align-items-sm-start {
    align-items: flex-start;
  }
  .align-items-sm-end {
    align-items: flex-end;
  }
  .align-items-sm-center {
    align-items: center;
  }
  .align-items-sm-baseline {
    align-items: baseline;
  }
  .align-items-sm-stretch {
    align-items: stretch;
  }
  .align-content-sm-start {
    align-content: flex-start;
  }
  .align-content-sm-end {
    align-content: flex-end;
  }
  .align-content-sm-center {
    align-content: center;
  }
  .align-content-sm-between {
    align-content: space-between;
  }
  .align-content-sm-around {
    align-content: space-around;
  }
  .align-content-sm-stretch {
    align-content: stretch;
  }
  .align-self-sm-auto {
    align-self: auto;
  }
  .align-self-sm-start {
    align-self: flex-start;
  }
  .align-self-sm-end {
    align-self: flex-end;
  }
  .align-self-sm-center {
    align-self: center;
  }
  .align-self-sm-baseline {
    align-self: baseline;
  }
  .align-self-sm-stretch {
    align-self: stretch;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-last {
    order: 6;
  }
  .m-sm-0 {
    margin: 0;
  }
  .m-sm-1 {
    margin: 0.25rem;
  }
  .m-sm-2 {
    margin: 0.5rem;
  }
  .m-sm-3 {
    margin: 1rem;
  }
  .m-sm-4 {
    margin: 1.5rem;
  }
  .m-sm-5 {
    margin: 2rem;
  }
  .m-sm-6 {
    margin: 2.5rem;
  }
  .m-sm-7 {
    margin: 3rem;
  }
  .m-sm-8 {
    margin: 3.5rem;
  }
  .m-sm-9 {
    margin: 4rem;
  }
  .m-sm-10 {
    margin: 5rem;
  }
  .m-sm-11 {
    margin: 6rem;
  }
  .m-sm-12 {
    margin: 7rem;
  }
  .m-sm-auto {
    margin: auto;
  }
  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-sm-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-sm-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-sm-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-sm-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-sm-5 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-sm-6 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-sm-7 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-sm-8 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-sm-9 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-sm-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-sm-11 {
    margin-right: 6rem;
    margin-left: 6rem;
  }
  .mx-sm-12 {
    margin-right: 7rem;
    margin-left: 7rem;
  }
  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-sm-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-sm-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-sm-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-sm-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-sm-5 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-sm-6 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-sm-7 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-sm-8 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-sm-9 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-sm-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-sm-11 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .my-sm-12 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-sm-0 {
    margin-top: 0;
  }
  .mt-sm-1 {
    margin-top: 0.25rem;
  }
  .mt-sm-2 {
    margin-top: 0.5rem;
  }
  .mt-sm-3 {
    margin-top: 1rem;
  }
  .mt-sm-4 {
    margin-top: 1.5rem;
  }
  .mt-sm-5 {
    margin-top: 2rem;
  }
  .mt-sm-6 {
    margin-top: 2.5rem;
  }
  .mt-sm-7 {
    margin-top: 3rem;
  }
  .mt-sm-8 {
    margin-top: 3.5rem;
  }
  .mt-sm-9 {
    margin-top: 4rem;
  }
  .mt-sm-10 {
    margin-top: 5rem;
  }
  .mt-sm-11 {
    margin-top: 6rem;
  }
  .mt-sm-12 {
    margin-top: 7rem;
  }
  .mt-sm-auto {
    margin-top: auto;
  }
  .me-sm-0 {
    margin-right: 0;
  }
  .me-sm-1 {
    margin-right: 0.25rem;
  }
  .me-sm-2 {
    margin-right: 0.5rem;
  }
  .me-sm-3 {
    margin-right: 1rem;
  }
  .me-sm-4 {
    margin-right: 1.5rem;
  }
  .me-sm-5 {
    margin-right: 2rem;
  }
  .me-sm-6 {
    margin-right: 2.5rem;
  }
  .me-sm-7 {
    margin-right: 3rem;
  }
  .me-sm-8 {
    margin-right: 3.5rem;
  }
  .me-sm-9 {
    margin-right: 4rem;
  }
  .me-sm-10 {
    margin-right: 5rem;
  }
  .me-sm-11 {
    margin-right: 6rem;
  }
  .me-sm-12 {
    margin-right: 7rem;
  }
  .me-sm-auto {
    margin-right: auto;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem;
  }
  .mb-sm-3 {
    margin-bottom: 1rem;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem;
  }
  .mb-sm-5 {
    margin-bottom: 2rem;
  }
  .mb-sm-6 {
    margin-bottom: 2.5rem;
  }
  .mb-sm-7 {
    margin-bottom: 3rem;
  }
  .mb-sm-8 {
    margin-bottom: 3.5rem;
  }
  .mb-sm-9 {
    margin-bottom: 4rem;
  }
  .mb-sm-10 {
    margin-bottom: 5rem;
  }
  .mb-sm-11 {
    margin-bottom: 6rem;
  }
  .mb-sm-12 {
    margin-bottom: 7rem;
  }
  .mb-sm-auto {
    margin-bottom: auto;
  }
  .ms-sm-0 {
    margin-left: 0;
  }
  .ms-sm-1 {
    margin-left: 0.25rem;
  }
  .ms-sm-2 {
    margin-left: 0.5rem;
  }
  .ms-sm-3 {
    margin-left: 1rem;
  }
  .ms-sm-4 {
    margin-left: 1.5rem;
  }
  .ms-sm-5 {
    margin-left: 2rem;
  }
  .ms-sm-6 {
    margin-left: 2.5rem;
  }
  .ms-sm-7 {
    margin-left: 3rem;
  }
  .ms-sm-8 {
    margin-left: 3.5rem;
  }
  .ms-sm-9 {
    margin-left: 4rem;
  }
  .ms-sm-10 {
    margin-left: 5rem;
  }
  .ms-sm-11 {
    margin-left: 6rem;
  }
  .ms-sm-12 {
    margin-left: 7rem;
  }
  .ms-sm-auto {
    margin-left: auto;
  }
  .m-sm-n1 {
    margin: -0.25rem;
  }
  .m-sm-n2 {
    margin: -0.5rem;
  }
  .m-sm-n3 {
    margin: -1rem;
  }
  .m-sm-n4 {
    margin: -1.5rem;
  }
  .m-sm-n5 {
    margin: -2rem;
  }
  .m-sm-n6 {
    margin: -2.5rem;
  }
  .m-sm-n7 {
    margin: -3rem;
  }
  .m-sm-n8 {
    margin: -3.5rem;
  }
  .m-sm-n9 {
    margin: -4rem;
  }
  .m-sm-n10 {
    margin: -5rem;
  }
  .m-sm-n11 {
    margin: -6rem;
  }
  .m-sm-n12 {
    margin: -7rem;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-sm-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-sm-n5 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-sm-n6 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-sm-n7 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-sm-n8 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-sm-n9 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-sm-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-sm-n11 {
    margin-right: -6rem;
    margin-left: -6rem;
  }
  .mx-sm-n12 {
    margin-right: -7rem;
    margin-left: -7rem;
  }
  .my-sm-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-sm-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-sm-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-sm-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-sm-n5 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-sm-n6 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-sm-n7 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-sm-n8 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-sm-n9 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-sm-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-sm-n11 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .my-sm-n12 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem;
  }
  .mt-sm-n3 {
    margin-top: -1rem;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem;
  }
  .mt-sm-n5 {
    margin-top: -2rem;
  }
  .mt-sm-n6 {
    margin-top: -2.5rem;
  }
  .mt-sm-n7 {
    margin-top: -3rem;
  }
  .mt-sm-n8 {
    margin-top: -3.5rem;
  }
  .mt-sm-n9 {
    margin-top: -4rem;
  }
  .mt-sm-n10 {
    margin-top: -5rem;
  }
  .mt-sm-n11 {
    margin-top: -6rem;
  }
  .mt-sm-n12 {
    margin-top: -7rem;
  }
  .me-sm-n1 {
    margin-right: -0.25rem;
  }
  .me-sm-n2 {
    margin-right: -0.5rem;
  }
  .me-sm-n3 {
    margin-right: -1rem;
  }
  .me-sm-n4 {
    margin-right: -1.5rem;
  }
  .me-sm-n5 {
    margin-right: -2rem;
  }
  .me-sm-n6 {
    margin-right: -2.5rem;
  }
  .me-sm-n7 {
    margin-right: -3rem;
  }
  .me-sm-n8 {
    margin-right: -3.5rem;
  }
  .me-sm-n9 {
    margin-right: -4rem;
  }
  .me-sm-n10 {
    margin-right: -5rem;
  }
  .me-sm-n11 {
    margin-right: -6rem;
  }
  .me-sm-n12 {
    margin-right: -7rem;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem;
  }
  .mb-sm-n5 {
    margin-bottom: -2rem;
  }
  .mb-sm-n6 {
    margin-bottom: -2.5rem;
  }
  .mb-sm-n7 {
    margin-bottom: -3rem;
  }
  .mb-sm-n8 {
    margin-bottom: -3.5rem;
  }
  .mb-sm-n9 {
    margin-bottom: -4rem;
  }
  .mb-sm-n10 {
    margin-bottom: -5rem;
  }
  .mb-sm-n11 {
    margin-bottom: -6rem;
  }
  .mb-sm-n12 {
    margin-bottom: -7rem;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem;
  }
  .ms-sm-n3 {
    margin-left: -1rem;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem;
  }
  .ms-sm-n5 {
    margin-left: -2rem;
  }
  .ms-sm-n6 {
    margin-left: -2.5rem;
  }
  .ms-sm-n7 {
    margin-left: -3rem;
  }
  .ms-sm-n8 {
    margin-left: -3.5rem;
  }
  .ms-sm-n9 {
    margin-left: -4rem;
  }
  .ms-sm-n10 {
    margin-left: -5rem;
  }
  .ms-sm-n11 {
    margin-left: -6rem;
  }
  .ms-sm-n12 {
    margin-left: -7rem;
  }
  .p-sm-0 {
    padding: 0;
  }
  .p-sm-1 {
    padding: 0.25rem;
  }
  .p-sm-2 {
    padding: 0.5rem;
  }
  .p-sm-3 {
    padding: 1rem;
  }
  .p-sm-4 {
    padding: 1.5rem;
  }
  .p-sm-5 {
    padding: 2rem;
  }
  .p-sm-6 {
    padding: 2.5rem;
  }
  .p-sm-7 {
    padding: 3rem;
  }
  .p-sm-8 {
    padding: 3.5rem;
  }
  .p-sm-9 {
    padding: 4rem;
  }
  .p-sm-10 {
    padding: 5rem;
  }
  .p-sm-11 {
    padding: 6rem;
  }
  .p-sm-12 {
    padding: 7rem;
  }
  .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-sm-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-sm-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-sm-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-sm-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-sm-5 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-sm-6 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-sm-7 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-sm-8 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-sm-9 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-sm-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-sm-11 {
    padding-right: 6rem;
    padding-left: 6rem;
  }
  .px-sm-12 {
    padding-right: 7rem;
    padding-left: 7rem;
  }
  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-sm-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-sm-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-sm-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-sm-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-sm-5 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-sm-6 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-sm-7 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-sm-8 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-sm-9 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-sm-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-sm-11 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .py-sm-12 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .pt-sm-0 {
    padding-top: 0;
  }
  .pt-sm-1 {
    padding-top: 0.25rem;
  }
  .pt-sm-2 {
    padding-top: 0.5rem;
  }
  .pt-sm-3 {
    padding-top: 1rem;
  }
  .pt-sm-4 {
    padding-top: 1.5rem;
  }
  .pt-sm-5 {
    padding-top: 2rem;
  }
  .pt-sm-6 {
    padding-top: 2.5rem;
  }
  .pt-sm-7 {
    padding-top: 3rem;
  }
  .pt-sm-8 {
    padding-top: 3.5rem;
  }
  .pt-sm-9 {
    padding-top: 4rem;
  }
  .pt-sm-10 {
    padding-top: 5rem;
  }
  .pt-sm-11 {
    padding-top: 6rem;
  }
  .pt-sm-12 {
    padding-top: 7rem;
  }
  .pe-sm-0 {
    padding-right: 0;
  }
  .pe-sm-1 {
    padding-right: 0.25rem;
  }
  .pe-sm-2 {
    padding-right: 0.5rem;
  }
  .pe-sm-3 {
    padding-right: 1rem;
  }
  .pe-sm-4 {
    padding-right: 1.5rem;
  }
  .pe-sm-5 {
    padding-right: 2rem;
  }
  .pe-sm-6 {
    padding-right: 2.5rem;
  }
  .pe-sm-7 {
    padding-right: 3rem;
  }
  .pe-sm-8 {
    padding-right: 3.5rem;
  }
  .pe-sm-9 {
    padding-right: 4rem;
  }
  .pe-sm-10 {
    padding-right: 5rem;
  }
  .pe-sm-11 {
    padding-right: 6rem;
  }
  .pe-sm-12 {
    padding-right: 7rem;
  }
  .pb-sm-0 {
    padding-bottom: 0;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem;
  }
  .pb-sm-3 {
    padding-bottom: 1rem;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem;
  }
  .pb-sm-5 {
    padding-bottom: 2rem;
  }
  .pb-sm-6 {
    padding-bottom: 2.5rem;
  }
  .pb-sm-7 {
    padding-bottom: 3rem;
  }
  .pb-sm-8 {
    padding-bottom: 3.5rem;
  }
  .pb-sm-9 {
    padding-bottom: 4rem;
  }
  .pb-sm-10 {
    padding-bottom: 5rem;
  }
  .pb-sm-11 {
    padding-bottom: 6rem;
  }
  .pb-sm-12 {
    padding-bottom: 7rem;
  }
  .ps-sm-0 {
    padding-left: 0;
  }
  .ps-sm-1 {
    padding-left: 0.25rem;
  }
  .ps-sm-2 {
    padding-left: 0.5rem;
  }
  .ps-sm-3 {
    padding-left: 1rem;
  }
  .ps-sm-4 {
    padding-left: 1.5rem;
  }
  .ps-sm-5 {
    padding-left: 2rem;
  }
  .ps-sm-6 {
    padding-left: 2.5rem;
  }
  .ps-sm-7 {
    padding-left: 3rem;
  }
  .ps-sm-8 {
    padding-left: 3.5rem;
  }
  .ps-sm-9 {
    padding-left: 4rem;
  }
  .ps-sm-10 {
    padding-left: 5rem;
  }
  .ps-sm-11 {
    padding-left: 6rem;
  }
  .ps-sm-12 {
    padding-left: 7rem;
  }
  .text-sm-start {
    text-align: left;
  }
  .text-sm-end {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left;
  }
  .float-md-end {
    float: right;
  }
  .float-md-none {
    float: none;
  }
  .d-md-inline {
    display: inline;
  }
  .d-md-inline-block {
    display: inline-block;
  }
  .d-md-block {
    display: block;
  }
  .d-md-grid {
    display: grid;
  }
  .d-md-table {
    display: table;
  }
  .d-md-table-row {
    display: table-row;
  }
  .d-md-table-cell {
    display: table-cell;
  }
  .d-md-flex {
    display: flex;
  }
  .d-md-inline-flex {
    display: inline-flex;
  }
  .d-md-none {
    display: none;
  }
  .flex-md-fill {
    flex: 1 1 auto;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-grow-0 {
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1;
  }
  .flex-md-wrap {
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .gap-md-0 {
    gap: 0;
  }
  .gap-md-1 {
    gap: 0.25rem;
  }
  .gap-md-2 {
    gap: 0.5rem;
  }
  .gap-md-3 {
    gap: 1rem;
  }
  .gap-md-4 {
    gap: 1.5rem;
  }
  .gap-md-5 {
    gap: 2rem;
  }
  .gap-md-6 {
    gap: 2.5rem;
  }
  .gap-md-7 {
    gap: 3rem;
  }
  .gap-md-8 {
    gap: 3.5rem;
  }
  .gap-md-9 {
    gap: 4rem;
  }
  .gap-md-10 {
    gap: 5rem;
  }
  .gap-md-11 {
    gap: 6rem;
  }
  .gap-md-12 {
    gap: 7rem;
  }
  .justify-content-md-start {
    justify-content: flex-start;
  }
  .justify-content-md-end {
    justify-content: flex-end;
  }
  .justify-content-md-center {
    justify-content: center;
  }
  .justify-content-md-between {
    justify-content: space-between;
  }
  .justify-content-md-around {
    justify-content: space-around;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly;
  }
  .align-items-md-start {
    align-items: flex-start;
  }
  .align-items-md-end {
    align-items: flex-end;
  }
  .align-items-md-center {
    align-items: center;
  }
  .align-items-md-baseline {
    align-items: baseline;
  }
  .align-items-md-stretch {
    align-items: stretch;
  }
  .align-content-md-start {
    align-content: flex-start;
  }
  .align-content-md-end {
    align-content: flex-end;
  }
  .align-content-md-center {
    align-content: center;
  }
  .align-content-md-between {
    align-content: space-between;
  }
  .align-content-md-around {
    align-content: space-around;
  }
  .align-content-md-stretch {
    align-content: stretch;
  }
  .align-self-md-auto {
    align-self: auto;
  }
  .align-self-md-start {
    align-self: flex-start;
  }
  .align-self-md-end {
    align-self: flex-end;
  }
  .align-self-md-center {
    align-self: center;
  }
  .align-self-md-baseline {
    align-self: baseline;
  }
  .align-self-md-stretch {
    align-self: stretch;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-last {
    order: 6;
  }
  .m-md-0 {
    margin: 0;
  }
  .m-md-1 {
    margin: 0.25rem;
  }
  .m-md-2 {
    margin: 0.5rem;
  }
  .m-md-3 {
    margin: 1rem;
  }
  .m-md-4 {
    margin: 1.5rem;
  }
  .m-md-5 {
    margin: 2rem;
  }
  .m-md-6 {
    margin: 2.5rem;
  }
  .m-md-7 {
    margin: 3rem;
  }
  .m-md-8 {
    margin: 3.5rem;
  }
  .m-md-9 {
    margin: 4rem;
  }
  .m-md-10 {
    margin: 5rem;
  }
  .m-md-11 {
    margin: 6rem;
  }
  .m-md-12 {
    margin: 7rem;
  }
  .m-md-auto {
    margin: auto;
  }
  .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-md-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-md-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-md-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-md-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-md-5 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-md-6 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-md-7 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-md-8 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-md-9 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-md-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-md-11 {
    margin-right: 6rem;
    margin-left: 6rem;
  }
  .mx-md-12 {
    margin-right: 7rem;
    margin-left: 7rem;
  }
  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-md-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-md-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-md-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-md-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-md-5 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-md-6 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-md-7 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-md-8 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-md-9 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-md-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-md-11 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .my-md-12 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-md-0 {
    margin-top: 0;
  }
  .mt-md-1 {
    margin-top: 0.25rem;
  }
  .mt-md-2 {
    margin-top: 0.5rem;
  }
  .mt-md-3 {
    margin-top: 1rem;
  }
  .mt-md-4 {
    margin-top: 1.5rem;
  }
  .mt-md-5 {
    margin-top: 2rem;
  }
  .mt-md-6 {
    margin-top: 2.5rem;
  }
  .mt-md-7 {
    margin-top: 3rem;
  }
  .mt-md-8 {
    margin-top: 3.5rem;
  }
  .mt-md-9 {
    margin-top: 4rem;
  }
  .mt-md-10 {
    margin-top: 5rem;
  }
  .mt-md-11 {
    margin-top: 6rem;
  }
  .mt-md-12 {
    margin-top: 7rem;
  }
  .mt-md-auto {
    margin-top: auto;
  }
  .me-md-0 {
    margin-right: 0;
  }
  .me-md-1 {
    margin-right: 0.25rem;
  }
  .me-md-2 {
    margin-right: 0.5rem;
  }
  .me-md-3 {
    margin-right: 1rem;
  }
  .me-md-4 {
    margin-right: 1.5rem;
  }
  .me-md-5 {
    margin-right: 2rem;
  }
  .me-md-6 {
    margin-right: 2.5rem;
  }
  .me-md-7 {
    margin-right: 3rem;
  }
  .me-md-8 {
    margin-right: 3.5rem;
  }
  .me-md-9 {
    margin-right: 4rem;
  }
  .me-md-10 {
    margin-right: 5rem;
  }
  .me-md-11 {
    margin-right: 6rem;
  }
  .me-md-12 {
    margin-right: 7rem;
  }
  .me-md-auto {
    margin-right: auto;
  }
  .mb-md-0 {
    margin-bottom: 0;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem;
  }
  .mb-md-3 {
    margin-bottom: 1rem;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem;
  }
  .mb-md-5 {
    margin-bottom: 2rem;
  }
  .mb-md-6 {
    margin-bottom: 2.5rem;
  }
  .mb-md-7 {
    margin-bottom: 3rem;
  }
  .mb-md-8 {
    margin-bottom: 3.5rem;
  }
  .mb-md-9 {
    margin-bottom: 4rem;
  }
  .mb-md-10 {
    margin-bottom: 5rem;
  }
  .mb-md-11 {
    margin-bottom: 6rem;
  }
  .mb-md-12 {
    margin-bottom: 7rem;
  }
  .mb-md-auto {
    margin-bottom: auto;
  }
  .ms-md-0 {
    margin-left: 0;
  }
  .ms-md-1 {
    margin-left: 0.25rem;
  }
  .ms-md-2 {
    margin-left: 0.5rem;
  }
  .ms-md-3 {
    margin-left: 1rem;
  }
  .ms-md-4 {
    margin-left: 1.5rem;
  }
  .ms-md-5 {
    margin-left: 2rem;
  }
  .ms-md-6 {
    margin-left: 2.5rem;
  }
  .ms-md-7 {
    margin-left: 3rem;
  }
  .ms-md-8 {
    margin-left: 3.5rem;
  }
  .ms-md-9 {
    margin-left: 4rem;
  }
  .ms-md-10 {
    margin-left: 5rem;
  }
  .ms-md-11 {
    margin-left: 6rem;
  }
  .ms-md-12 {
    margin-left: 7rem;
  }
  .ms-md-auto {
    margin-left: auto;
  }
  .m-md-n1 {
    margin: -0.25rem;
  }
  .m-md-n2 {
    margin: -0.5rem;
  }
  .m-md-n3 {
    margin: -1rem;
  }
  .m-md-n4 {
    margin: -1.5rem;
  }
  .m-md-n5 {
    margin: -2rem;
  }
  .m-md-n6 {
    margin: -2.5rem;
  }
  .m-md-n7 {
    margin: -3rem;
  }
  .m-md-n8 {
    margin: -3.5rem;
  }
  .m-md-n9 {
    margin: -4rem;
  }
  .m-md-n10 {
    margin: -5rem;
  }
  .m-md-n11 {
    margin: -6rem;
  }
  .m-md-n12 {
    margin: -7rem;
  }
  .mx-md-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-md-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-md-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-md-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-md-n5 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-md-n6 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-md-n7 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-md-n8 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-md-n9 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-md-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-md-n11 {
    margin-right: -6rem;
    margin-left: -6rem;
  }
  .mx-md-n12 {
    margin-right: -7rem;
    margin-left: -7rem;
  }
  .my-md-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-md-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-md-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-md-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-md-n5 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-md-n6 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-md-n7 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-md-n8 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-md-n9 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-md-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-md-n11 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .my-md-n12 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .mt-md-n1 {
    margin-top: -0.25rem;
  }
  .mt-md-n2 {
    margin-top: -0.5rem;
  }
  .mt-md-n3 {
    margin-top: -1rem;
  }
  .mt-md-n4 {
    margin-top: -1.5rem;
  }
  .mt-md-n5 {
    margin-top: -2rem;
  }
  .mt-md-n6 {
    margin-top: -2.5rem;
  }
  .mt-md-n7 {
    margin-top: -3rem;
  }
  .mt-md-n8 {
    margin-top: -3.5rem;
  }
  .mt-md-n9 {
    margin-top: -4rem;
  }
  .mt-md-n10 {
    margin-top: -5rem;
  }
  .mt-md-n11 {
    margin-top: -6rem;
  }
  .mt-md-n12 {
    margin-top: -7rem;
  }
  .me-md-n1 {
    margin-right: -0.25rem;
  }
  .me-md-n2 {
    margin-right: -0.5rem;
  }
  .me-md-n3 {
    margin-right: -1rem;
  }
  .me-md-n4 {
    margin-right: -1.5rem;
  }
  .me-md-n5 {
    margin-right: -2rem;
  }
  .me-md-n6 {
    margin-right: -2.5rem;
  }
  .me-md-n7 {
    margin-right: -3rem;
  }
  .me-md-n8 {
    margin-right: -3.5rem;
  }
  .me-md-n9 {
    margin-right: -4rem;
  }
  .me-md-n10 {
    margin-right: -5rem;
  }
  .me-md-n11 {
    margin-right: -6rem;
  }
  .me-md-n12 {
    margin-right: -7rem;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem;
  }
  .mb-md-n3 {
    margin-bottom: -1rem;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem;
  }
  .mb-md-n5 {
    margin-bottom: -2rem;
  }
  .mb-md-n6 {
    margin-bottom: -2.5rem;
  }
  .mb-md-n7 {
    margin-bottom: -3rem;
  }
  .mb-md-n8 {
    margin-bottom: -3.5rem;
  }
  .mb-md-n9 {
    margin-bottom: -4rem;
  }
  .mb-md-n10 {
    margin-bottom: -5rem;
  }
  .mb-md-n11 {
    margin-bottom: -6rem;
  }
  .mb-md-n12 {
    margin-bottom: -7rem;
  }
  .ms-md-n1 {
    margin-left: -0.25rem;
  }
  .ms-md-n2 {
    margin-left: -0.5rem;
  }
  .ms-md-n3 {
    margin-left: -1rem;
  }
  .ms-md-n4 {
    margin-left: -1.5rem;
  }
  .ms-md-n5 {
    margin-left: -2rem;
  }
  .ms-md-n6 {
    margin-left: -2.5rem;
  }
  .ms-md-n7 {
    margin-left: -3rem;
  }
  .ms-md-n8 {
    margin-left: -3.5rem;
  }
  .ms-md-n9 {
    margin-left: -4rem;
  }
  .ms-md-n10 {
    margin-left: -5rem;
  }
  .ms-md-n11 {
    margin-left: -6rem;
  }
  .ms-md-n12 {
    margin-left: -7rem;
  }
  .p-md-0 {
    padding: 0;
  }
  .p-md-1 {
    padding: 0.25rem;
  }
  .p-md-2 {
    padding: 0.5rem;
  }
  .p-md-3 {
    padding: 1rem;
  }
  .p-md-4 {
    padding: 1.5rem;
  }
  .p-md-5 {
    padding: 2rem;
  }
  .p-md-6 {
    padding: 2.5rem;
  }
  .p-md-7 {
    padding: 3rem;
  }
  .p-md-8 {
    padding: 3.5rem;
  }
  .p-md-9 {
    padding: 4rem;
  }
  .p-md-10 {
    padding: 5rem;
  }
  .p-md-11 {
    padding: 6rem;
  }
  .p-md-12 {
    padding: 7rem;
  }
  .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-md-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-md-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-md-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-md-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-md-5 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-md-6 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-md-7 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-md-8 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-md-9 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-md-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-md-11 {
    padding-right: 6rem;
    padding-left: 6rem;
  }
  .px-md-12 {
    padding-right: 7rem;
    padding-left: 7rem;
  }
  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-md-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-md-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-md-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-md-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-md-5 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-md-6 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-md-7 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-md-8 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-md-9 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-md-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-md-11 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .py-md-12 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .pt-md-0 {
    padding-top: 0;
  }
  .pt-md-1 {
    padding-top: 0.25rem;
  }
  .pt-md-2 {
    padding-top: 0.5rem;
  }
  .pt-md-3 {
    padding-top: 1rem;
  }
  .pt-md-4 {
    padding-top: 1.5rem;
  }
  .pt-md-5 {
    padding-top: 2rem;
  }
  .pt-md-6 {
    padding-top: 2.5rem;
  }
  .pt-md-7 {
    padding-top: 3rem;
  }
  .pt-md-8 {
    padding-top: 3.5rem;
  }
  .pt-md-9 {
    padding-top: 4rem;
  }
  .pt-md-10 {
    padding-top: 5rem;
  }
  .pt-md-11 {
    padding-top: 6rem;
  }
  .pt-md-12 {
    padding-top: 7rem;
  }
  .pe-md-0 {
    padding-right: 0;
  }
  .pe-md-1 {
    padding-right: 0.25rem;
  }
  .pe-md-2 {
    padding-right: 0.5rem;
  }
  .pe-md-3 {
    padding-right: 1rem;
  }
  .pe-md-4 {
    padding-right: 1.5rem;
  }
  .pe-md-5 {
    padding-right: 2rem;
  }
  .pe-md-6 {
    padding-right: 2.5rem;
  }
  .pe-md-7 {
    padding-right: 3rem;
  }
  .pe-md-8 {
    padding-right: 3.5rem;
  }
  .pe-md-9 {
    padding-right: 4rem;
  }
  .pe-md-10 {
    padding-right: 5rem;
  }
  .pe-md-11 {
    padding-right: 6rem;
  }
  .pe-md-12 {
    padding-right: 7rem;
  }
  .pb-md-0 {
    padding-bottom: 0;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem;
  }
  .pb-md-3 {
    padding-bottom: 1rem;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem;
  }
  .pb-md-5 {
    padding-bottom: 2rem;
  }
  .pb-md-6 {
    padding-bottom: 2.5rem;
  }
  .pb-md-7 {
    padding-bottom: 3rem;
  }
  .pb-md-8 {
    padding-bottom: 3.5rem;
  }
  .pb-md-9 {
    padding-bottom: 4rem;
  }
  .pb-md-10 {
    padding-bottom: 5rem;
  }
  .pb-md-11 {
    padding-bottom: 6rem;
  }
  .pb-md-12 {
    padding-bottom: 7rem;
  }
  .ps-md-0 {
    padding-left: 0;
  }
  .ps-md-1 {
    padding-left: 0.25rem;
  }
  .ps-md-2 {
    padding-left: 0.5rem;
  }
  .ps-md-3 {
    padding-left: 1rem;
  }
  .ps-md-4 {
    padding-left: 1.5rem;
  }
  .ps-md-5 {
    padding-left: 2rem;
  }
  .ps-md-6 {
    padding-left: 2.5rem;
  }
  .ps-md-7 {
    padding-left: 3rem;
  }
  .ps-md-8 {
    padding-left: 3.5rem;
  }
  .ps-md-9 {
    padding-left: 4rem;
  }
  .ps-md-10 {
    padding-left: 5rem;
  }
  .ps-md-11 {
    padding-left: 6rem;
  }
  .ps-md-12 {
    padding-left: 7rem;
  }
  .text-md-start {
    text-align: left;
  }
  .text-md-end {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left;
  }
  .float-lg-end {
    float: right;
  }
  .float-lg-none {
    float: none;
  }
  .d-lg-inline {
    display: inline;
  }
  .d-lg-inline-block {
    display: inline-block;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-grid {
    display: grid;
  }
  .d-lg-table {
    display: table;
  }
  .d-lg-table-row {
    display: table-row;
  }
  .d-lg-table-cell {
    display: table-cell;
  }
  .d-lg-flex {
    display: flex;
  }
  .d-lg-inline-flex {
    display: inline-flex;
  }
  .d-lg-none {
    display: none;
  }
  .flex-lg-fill {
    flex: 1 1 auto;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-grow-0 {
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }
  .flex-lg-wrap {
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .gap-lg-0 {
    gap: 0;
  }
  .gap-lg-1 {
    gap: 0.25rem;
  }
  .gap-lg-2 {
    gap: 0.5rem;
  }
  .gap-lg-3 {
    gap: 1rem;
  }
  .gap-lg-4 {
    gap: 1.5rem;
  }
  .gap-lg-5 {
    gap: 2rem;
  }
  .gap-lg-6 {
    gap: 2.5rem;
  }
  .gap-lg-7 {
    gap: 3rem;
  }
  .gap-lg-8 {
    gap: 3.5rem;
  }
  .gap-lg-9 {
    gap: 4rem;
  }
  .gap-lg-10 {
    gap: 5rem;
  }
  .gap-lg-11 {
    gap: 6rem;
  }
  .gap-lg-12 {
    gap: 7rem;
  }
  .justify-content-lg-start {
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
  .justify-content-lg-between {
    justify-content: space-between;
  }
  .justify-content-lg-around {
    justify-content: space-around;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }
  .align-items-lg-start {
    align-items: flex-start;
  }
  .align-items-lg-end {
    align-items: flex-end;
  }
  .align-items-lg-center {
    align-items: center;
  }
  .align-items-lg-baseline {
    align-items: baseline;
  }
  .align-items-lg-stretch {
    align-items: stretch;
  }
  .align-content-lg-start {
    align-content: flex-start;
  }
  .align-content-lg-end {
    align-content: flex-end;
  }
  .align-content-lg-center {
    align-content: center;
  }
  .align-content-lg-between {
    align-content: space-between;
  }
  .align-content-lg-around {
    align-content: space-around;
  }
  .align-content-lg-stretch {
    align-content: stretch;
  }
  .align-self-lg-auto {
    align-self: auto;
  }
  .align-self-lg-start {
    align-self: flex-start;
  }
  .align-self-lg-end {
    align-self: flex-end;
  }
  .align-self-lg-center {
    align-self: center;
  }
  .align-self-lg-baseline {
    align-self: baseline;
  }
  .align-self-lg-stretch {
    align-self: stretch;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-last {
    order: 6;
  }
  .m-lg-0 {
    margin: 0;
  }
  .m-lg-1 {
    margin: 0.25rem;
  }
  .m-lg-2 {
    margin: 0.5rem;
  }
  .m-lg-3 {
    margin: 1rem;
  }
  .m-lg-4 {
    margin: 1.5rem;
  }
  .m-lg-5 {
    margin: 2rem;
  }
  .m-lg-6 {
    margin: 2.5rem;
  }
  .m-lg-7 {
    margin: 3rem;
  }
  .m-lg-8 {
    margin: 3.5rem;
  }
  .m-lg-9 {
    margin: 4rem;
  }
  .m-lg-10 {
    margin: 5rem;
  }
  .m-lg-11 {
    margin: 6rem;
  }
  .m-lg-12 {
    margin: 7rem;
  }
  .m-lg-auto {
    margin: auto;
  }
  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-lg-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-lg-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-lg-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-lg-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-lg-5 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-lg-6 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-lg-7 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-lg-8 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-lg-9 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-lg-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-lg-11 {
    margin-right: 6rem;
    margin-left: 6rem;
  }
  .mx-lg-12 {
    margin-right: 7rem;
    margin-left: 7rem;
  }
  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-lg-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-lg-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-lg-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-lg-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-lg-5 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-lg-6 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-lg-7 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-lg-8 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-lg-9 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-lg-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-lg-11 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .my-lg-12 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-lg-0 {
    margin-top: 0;
  }
  .mt-lg-1 {
    margin-top: 0.25rem;
  }
  .mt-lg-2 {
    margin-top: 0.5rem;
  }
  .mt-lg-3 {
    margin-top: 1rem;
  }
  .mt-lg-4 {
    margin-top: 1.5rem;
  }
  .mt-lg-5 {
    margin-top: 2rem;
  }
  .mt-lg-6 {
    margin-top: 2.5rem;
  }
  .mt-lg-7 {
    margin-top: 3rem;
  }
  .mt-lg-8 {
    margin-top: 3.5rem;
  }
  .mt-lg-9 {
    margin-top: 4rem;
  }
  .mt-lg-10 {
    margin-top: 5rem;
  }
  .mt-lg-11 {
    margin-top: 6rem;
  }
  .mt-lg-12 {
    margin-top: 7rem;
  }
  .mt-lg-auto {
    margin-top: auto;
  }
  .me-lg-0 {
    margin-right: 0;
  }
  .me-lg-1 {
    margin-right: 0.25rem;
  }
  .me-lg-2 {
    margin-right: 0.5rem;
  }
  .me-lg-3 {
    margin-right: 1rem;
  }
  .me-lg-4 {
    margin-right: 1.5rem;
  }
  .me-lg-5 {
    margin-right: 2rem;
  }
  .me-lg-6 {
    margin-right: 2.5rem;
  }
  .me-lg-7 {
    margin-right: 3rem;
  }
  .me-lg-8 {
    margin-right: 3.5rem;
  }
  .me-lg-9 {
    margin-right: 4rem;
  }
  .me-lg-10 {
    margin-right: 5rem;
  }
  .me-lg-11 {
    margin-right: 6rem;
  }
  .me-lg-12 {
    margin-right: 7rem;
  }
  .me-lg-auto {
    margin-right: auto;
  }
  .mb-lg-0 {
    margin-bottom: 0;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem;
  }
  .mb-lg-3 {
    margin-bottom: 1rem;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem;
  }
  .mb-lg-5 {
    margin-bottom: 2rem;
  }
  .mb-lg-6 {
    margin-bottom: 2.5rem;
  }
  .mb-lg-7 {
    margin-bottom: 3rem;
  }
  .mb-lg-8 {
    margin-bottom: 3.5rem;
  }
  .mb-lg-9 {
    margin-bottom: 4rem;
  }
  .mb-lg-10 {
    margin-bottom: 5rem;
  }
  .mb-lg-11 {
    margin-bottom: 6rem;
  }
  .mb-lg-12 {
    margin-bottom: 7rem;
  }
  .mb-lg-auto {
    margin-bottom: auto;
  }
  .ms-lg-0 {
    margin-left: 0;
  }
  .ms-lg-1 {
    margin-left: 0.25rem;
  }
  .ms-lg-2 {
    margin-left: 0.5rem;
  }
  .ms-lg-3 {
    margin-left: 1rem;
  }
  .ms-lg-4 {
    margin-left: 1.5rem;
  }
  .ms-lg-5 {
    margin-left: 2rem;
  }
  .ms-lg-6 {
    margin-left: 2.5rem;
  }
  .ms-lg-7 {
    margin-left: 3rem;
  }
  .ms-lg-8 {
    margin-left: 3.5rem;
  }
  .ms-lg-9 {
    margin-left: 4rem;
  }
  .ms-lg-10 {
    margin-left: 5rem;
  }
  .ms-lg-11 {
    margin-left: 6rem;
  }
  .ms-lg-12 {
    margin-left: 7rem;
  }
  .ms-lg-auto {
    margin-left: auto;
  }
  .m-lg-n1 {
    margin: -0.25rem;
  }
  .m-lg-n2 {
    margin: -0.5rem;
  }
  .m-lg-n3 {
    margin: -1rem;
  }
  .m-lg-n4 {
    margin: -1.5rem;
  }
  .m-lg-n5 {
    margin: -2rem;
  }
  .m-lg-n6 {
    margin: -2.5rem;
  }
  .m-lg-n7 {
    margin: -3rem;
  }
  .m-lg-n8 {
    margin: -3.5rem;
  }
  .m-lg-n9 {
    margin: -4rem;
  }
  .m-lg-n10 {
    margin: -5rem;
  }
  .m-lg-n11 {
    margin: -6rem;
  }
  .m-lg-n12 {
    margin: -7rem;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-lg-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-lg-n5 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-lg-n6 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-lg-n7 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-lg-n8 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-lg-n9 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-lg-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-lg-n11 {
    margin-right: -6rem;
    margin-left: -6rem;
  }
  .mx-lg-n12 {
    margin-right: -7rem;
    margin-left: -7rem;
  }
  .my-lg-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-lg-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-lg-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-lg-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-lg-n5 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-lg-n6 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-lg-n7 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-lg-n8 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-lg-n9 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-lg-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-lg-n11 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .my-lg-n12 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem;
  }
  .mt-lg-n3 {
    margin-top: -1rem;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem;
  }
  .mt-lg-n5 {
    margin-top: -2rem;
  }
  .mt-lg-n6 {
    margin-top: -2.5rem;
  }
  .mt-lg-n7 {
    margin-top: -3rem;
  }
  .mt-lg-n8 {
    margin-top: -3.5rem;
  }
  .mt-lg-n9 {
    margin-top: -4rem;
  }
  .mt-lg-n10 {
    margin-top: -5rem;
  }
  .mt-lg-n11 {
    margin-top: -6rem;
  }
  .mt-lg-n12 {
    margin-top: -7rem;
  }
  .me-lg-n1 {
    margin-right: -0.25rem;
  }
  .me-lg-n2 {
    margin-right: -0.5rem;
  }
  .me-lg-n3 {
    margin-right: -1rem;
  }
  .me-lg-n4 {
    margin-right: -1.5rem;
  }
  .me-lg-n5 {
    margin-right: -2rem;
  }
  .me-lg-n6 {
    margin-right: -2.5rem;
  }
  .me-lg-n7 {
    margin-right: -3rem;
  }
  .me-lg-n8 {
    margin-right: -3.5rem;
  }
  .me-lg-n9 {
    margin-right: -4rem;
  }
  .me-lg-n10 {
    margin-right: -5rem;
  }
  .me-lg-n11 {
    margin-right: -6rem;
  }
  .me-lg-n12 {
    margin-right: -7rem;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem;
  }
  .mb-lg-n5 {
    margin-bottom: -2rem;
  }
  .mb-lg-n6 {
    margin-bottom: -2.5rem;
  }
  .mb-lg-n7 {
    margin-bottom: -3rem;
  }
  .mb-lg-n8 {
    margin-bottom: -3.5rem;
  }
  .mb-lg-n9 {
    margin-bottom: -4rem;
  }
  .mb-lg-n10 {
    margin-bottom: -5rem;
  }
  .mb-lg-n11 {
    margin-bottom: -6rem;
  }
  .mb-lg-n12 {
    margin-bottom: -7rem;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem;
  }
  .ms-lg-n3 {
    margin-left: -1rem;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem;
  }
  .ms-lg-n5 {
    margin-left: -2rem;
  }
  .ms-lg-n6 {
    margin-left: -2.5rem;
  }
  .ms-lg-n7 {
    margin-left: -3rem;
  }
  .ms-lg-n8 {
    margin-left: -3.5rem;
  }
  .ms-lg-n9 {
    margin-left: -4rem;
  }
  .ms-lg-n10 {
    margin-left: -5rem;
  }
  .ms-lg-n11 {
    margin-left: -6rem;
  }
  .ms-lg-n12 {
    margin-left: -7rem;
  }
  .p-lg-0 {
    padding: 0;
  }
  .p-lg-1 {
    padding: 0.25rem;
  }
  .p-lg-2 {
    padding: 0.5rem;
  }
  .p-lg-3 {
    padding: 1rem;
  }
  .p-lg-4 {
    padding: 1.5rem;
  }
  .p-lg-5 {
    padding: 2rem;
  }
  .p-lg-6 {
    padding: 2.5rem;
  }
  .p-lg-7 {
    padding: 3rem;
  }
  .p-lg-8 {
    padding: 3.5rem;
  }
  .p-lg-9 {
    padding: 4rem;
  }
  .p-lg-10 {
    padding: 5rem;
  }
  .p-lg-11 {
    padding: 6rem;
  }
  .p-lg-12 {
    padding: 7rem;
  }
  .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-lg-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-lg-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-lg-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-lg-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-lg-5 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-lg-6 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-lg-7 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-lg-8 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-lg-9 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-lg-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-lg-11 {
    padding-right: 6rem;
    padding-left: 6rem;
  }
  .px-lg-12 {
    padding-right: 7rem;
    padding-left: 7rem;
  }
  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-lg-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-lg-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-lg-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-lg-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-lg-5 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-lg-6 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-lg-7 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-lg-8 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-lg-9 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-lg-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-lg-11 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .py-lg-12 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .pt-lg-0 {
    padding-top: 0;
  }
  .pt-lg-1 {
    padding-top: 0.25rem;
  }
  .pt-lg-2 {
    padding-top: 0.5rem;
  }
  .pt-lg-3 {
    padding-top: 1rem;
  }
  .pt-lg-4 {
    padding-top: 1.5rem;
  }
  .pt-lg-5 {
    padding-top: 2rem;
  }
  .pt-lg-6 {
    padding-top: 2.5rem;
  }
  .pt-lg-7 {
    padding-top: 3rem;
  }
  .pt-lg-8 {
    padding-top: 3.5rem;
  }
  .pt-lg-9 {
    padding-top: 4rem;
  }
  .pt-lg-10 {
    padding-top: 5rem;
  }
  .pt-lg-11 {
    padding-top: 6rem;
  }
  .pt-lg-12 {
    padding-top: 7rem;
  }
  .pe-lg-0 {
    padding-right: 0;
  }
  .pe-lg-1 {
    padding-right: 0.25rem;
  }
  .pe-lg-2 {
    padding-right: 0.5rem;
  }
  .pe-lg-3 {
    padding-right: 1rem;
  }
  .pe-lg-4 {
    padding-right: 1.5rem;
  }
  .pe-lg-5 {
    padding-right: 2rem;
  }
  .pe-lg-6 {
    padding-right: 2.5rem;
  }
  .pe-lg-7 {
    padding-right: 3rem;
  }
  .pe-lg-8 {
    padding-right: 3.5rem;
  }
  .pe-lg-9 {
    padding-right: 4rem;
  }
  .pe-lg-10 {
    padding-right: 5rem;
  }
  .pe-lg-11 {
    padding-right: 6rem;
  }
  .pe-lg-12 {
    padding-right: 7rem;
  }
  .pb-lg-0 {
    padding-bottom: 0;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem;
  }
  .pb-lg-3 {
    padding-bottom: 1rem;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem;
  }
  .pb-lg-5 {
    padding-bottom: 2rem;
  }
  .pb-lg-6 {
    padding-bottom: 2.5rem;
  }
  .pb-lg-7 {
    padding-bottom: 3rem;
  }
  .pb-lg-8 {
    padding-bottom: 3.5rem;
  }
  .pb-lg-9 {
    padding-bottom: 4rem;
  }
  .pb-lg-10 {
    padding-bottom: 5rem;
  }
  .pb-lg-11 {
    padding-bottom: 6rem;
  }
  .pb-lg-12 {
    padding-bottom: 7rem;
  }
  .ps-lg-0 {
    padding-left: 0;
  }
  .ps-lg-1 {
    padding-left: 0.25rem;
  }
  .ps-lg-2 {
    padding-left: 0.5rem;
  }
  .ps-lg-3 {
    padding-left: 1rem;
  }
  .ps-lg-4 {
    padding-left: 1.5rem;
  }
  .ps-lg-5 {
    padding-left: 2rem;
  }
  .ps-lg-6 {
    padding-left: 2.5rem;
  }
  .ps-lg-7 {
    padding-left: 3rem;
  }
  .ps-lg-8 {
    padding-left: 3.5rem;
  }
  .ps-lg-9 {
    padding-left: 4rem;
  }
  .ps-lg-10 {
    padding-left: 5rem;
  }
  .ps-lg-11 {
    padding-left: 6rem;
  }
  .ps-lg-12 {
    padding-left: 7rem;
  }
  .text-lg-start {
    text-align: left;
  }
  .text-lg-end {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left;
  }
  .float-xl-end {
    float: right;
  }
  .float-xl-none {
    float: none;
  }
  .d-xl-inline {
    display: inline;
  }
  .d-xl-inline-block {
    display: inline-block;
  }
  .d-xl-block {
    display: block;
  }
  .d-xl-grid {
    display: grid;
  }
  .d-xl-table {
    display: table;
  }
  .d-xl-table-row {
    display: table-row;
  }
  .d-xl-table-cell {
    display: table-cell;
  }
  .d-xl-flex {
    display: flex;
  }
  .d-xl-inline-flex {
    display: inline-flex;
  }
  .d-xl-none {
    display: none;
  }
  .flex-xl-fill {
    flex: 1 1 auto;
  }
  .flex-xl-row {
    flex-direction: row;
  }
  .flex-xl-column {
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xl-grow-0 {
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xl-wrap {
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .gap-xl-0 {
    gap: 0;
  }
  .gap-xl-1 {
    gap: 0.25rem;
  }
  .gap-xl-2 {
    gap: 0.5rem;
  }
  .gap-xl-3 {
    gap: 1rem;
  }
  .gap-xl-4 {
    gap: 1.5rem;
  }
  .gap-xl-5 {
    gap: 2rem;
  }
  .gap-xl-6 {
    gap: 2.5rem;
  }
  .gap-xl-7 {
    gap: 3rem;
  }
  .gap-xl-8 {
    gap: 3.5rem;
  }
  .gap-xl-9 {
    gap: 4rem;
  }
  .gap-xl-10 {
    gap: 5rem;
  }
  .gap-xl-11 {
    gap: 6rem;
  }
  .gap-xl-12 {
    gap: 7rem;
  }
  .justify-content-xl-start {
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    justify-content: center;
  }
  .justify-content-xl-between {
    justify-content: space-between;
  }
  .justify-content-xl-around {
    justify-content: space-around;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xl-start {
    align-items: flex-start;
  }
  .align-items-xl-end {
    align-items: flex-end;
  }
  .align-items-xl-center {
    align-items: center;
  }
  .align-items-xl-baseline {
    align-items: baseline;
  }
  .align-items-xl-stretch {
    align-items: stretch;
  }
  .align-content-xl-start {
    align-content: flex-start;
  }
  .align-content-xl-end {
    align-content: flex-end;
  }
  .align-content-xl-center {
    align-content: center;
  }
  .align-content-xl-between {
    align-content: space-between;
  }
  .align-content-xl-around {
    align-content: space-around;
  }
  .align-content-xl-stretch {
    align-content: stretch;
  }
  .align-self-xl-auto {
    align-self: auto;
  }
  .align-self-xl-start {
    align-self: flex-start;
  }
  .align-self-xl-end {
    align-self: flex-end;
  }
  .align-self-xl-center {
    align-self: center;
  }
  .align-self-xl-baseline {
    align-self: baseline;
  }
  .align-self-xl-stretch {
    align-self: stretch;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-last {
    order: 6;
  }
  .m-xl-0 {
    margin: 0;
  }
  .m-xl-1 {
    margin: 0.25rem;
  }
  .m-xl-2 {
    margin: 0.5rem;
  }
  .m-xl-3 {
    margin: 1rem;
  }
  .m-xl-4 {
    margin: 1.5rem;
  }
  .m-xl-5 {
    margin: 2rem;
  }
  .m-xl-6 {
    margin: 2.5rem;
  }
  .m-xl-7 {
    margin: 3rem;
  }
  .m-xl-8 {
    margin: 3.5rem;
  }
  .m-xl-9 {
    margin: 4rem;
  }
  .m-xl-10 {
    margin: 5rem;
  }
  .m-xl-11 {
    margin: 6rem;
  }
  .m-xl-12 {
    margin: 7rem;
  }
  .m-xl-auto {
    margin: auto;
  }
  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xl-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-xl-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-xl-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-xl-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-xl-5 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-xl-6 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-xl-7 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-xl-8 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-xl-9 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-xl-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-xl-11 {
    margin-right: 6rem;
    margin-left: 6rem;
  }
  .mx-xl-12 {
    margin-right: 7rem;
    margin-left: 7rem;
  }
  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-xl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-xl-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-xl-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-xl-5 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-xl-6 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-xl-7 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-xl-8 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-xl-9 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-xl-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-xl-11 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .my-xl-12 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xl-0 {
    margin-top: 0;
  }
  .mt-xl-1 {
    margin-top: 0.25rem;
  }
  .mt-xl-2 {
    margin-top: 0.5rem;
  }
  .mt-xl-3 {
    margin-top: 1rem;
  }
  .mt-xl-4 {
    margin-top: 1.5rem;
  }
  .mt-xl-5 {
    margin-top: 2rem;
  }
  .mt-xl-6 {
    margin-top: 2.5rem;
  }
  .mt-xl-7 {
    margin-top: 3rem;
  }
  .mt-xl-8 {
    margin-top: 3.5rem;
  }
  .mt-xl-9 {
    margin-top: 4rem;
  }
  .mt-xl-10 {
    margin-top: 5rem;
  }
  .mt-xl-11 {
    margin-top: 6rem;
  }
  .mt-xl-12 {
    margin-top: 7rem;
  }
  .mt-xl-auto {
    margin-top: auto;
  }
  .me-xl-0 {
    margin-right: 0;
  }
  .me-xl-1 {
    margin-right: 0.25rem;
  }
  .me-xl-2 {
    margin-right: 0.5rem;
  }
  .me-xl-3 {
    margin-right: 1rem;
  }
  .me-xl-4 {
    margin-right: 1.5rem;
  }
  .me-xl-5 {
    margin-right: 2rem;
  }
  .me-xl-6 {
    margin-right: 2.5rem;
  }
  .me-xl-7 {
    margin-right: 3rem;
  }
  .me-xl-8 {
    margin-right: 3.5rem;
  }
  .me-xl-9 {
    margin-right: 4rem;
  }
  .me-xl-10 {
    margin-right: 5rem;
  }
  .me-xl-11 {
    margin-right: 6rem;
  }
  .me-xl-12 {
    margin-right: 7rem;
  }
  .me-xl-auto {
    margin-right: auto;
  }
  .mb-xl-0 {
    margin-bottom: 0;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem;
  }
  .mb-xl-3 {
    margin-bottom: 1rem;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem;
  }
  .mb-xl-5 {
    margin-bottom: 2rem;
  }
  .mb-xl-6 {
    margin-bottom: 2.5rem;
  }
  .mb-xl-7 {
    margin-bottom: 3rem;
  }
  .mb-xl-8 {
    margin-bottom: 3.5rem;
  }
  .mb-xl-9 {
    margin-bottom: 4rem;
  }
  .mb-xl-10 {
    margin-bottom: 5rem;
  }
  .mb-xl-11 {
    margin-bottom: 6rem;
  }
  .mb-xl-12 {
    margin-bottom: 7rem;
  }
  .mb-xl-auto {
    margin-bottom: auto;
  }
  .ms-xl-0 {
    margin-left: 0;
  }
  .ms-xl-1 {
    margin-left: 0.25rem;
  }
  .ms-xl-2 {
    margin-left: 0.5rem;
  }
  .ms-xl-3 {
    margin-left: 1rem;
  }
  .ms-xl-4 {
    margin-left: 1.5rem;
  }
  .ms-xl-5 {
    margin-left: 2rem;
  }
  .ms-xl-6 {
    margin-left: 2.5rem;
  }
  .ms-xl-7 {
    margin-left: 3rem;
  }
  .ms-xl-8 {
    margin-left: 3.5rem;
  }
  .ms-xl-9 {
    margin-left: 4rem;
  }
  .ms-xl-10 {
    margin-left: 5rem;
  }
  .ms-xl-11 {
    margin-left: 6rem;
  }
  .ms-xl-12 {
    margin-left: 7rem;
  }
  .ms-xl-auto {
    margin-left: auto;
  }
  .m-xl-n1 {
    margin: -0.25rem;
  }
  .m-xl-n2 {
    margin: -0.5rem;
  }
  .m-xl-n3 {
    margin: -1rem;
  }
  .m-xl-n4 {
    margin: -1.5rem;
  }
  .m-xl-n5 {
    margin: -2rem;
  }
  .m-xl-n6 {
    margin: -2.5rem;
  }
  .m-xl-n7 {
    margin: -3rem;
  }
  .m-xl-n8 {
    margin: -3.5rem;
  }
  .m-xl-n9 {
    margin: -4rem;
  }
  .m-xl-n10 {
    margin: -5rem;
  }
  .m-xl-n11 {
    margin: -6rem;
  }
  .m-xl-n12 {
    margin: -7rem;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-xl-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-xl-n5 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-xl-n6 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-xl-n7 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-xl-n8 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-xl-n9 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-xl-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-xl-n11 {
    margin-right: -6rem;
    margin-left: -6rem;
  }
  .mx-xl-n12 {
    margin-right: -7rem;
    margin-left: -7rem;
  }
  .my-xl-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-xl-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-xl-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-xl-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-xl-n5 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-xl-n6 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-xl-n7 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-xl-n8 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-xl-n9 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-xl-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-xl-n11 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .my-xl-n12 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem;
  }
  .mt-xl-n3 {
    margin-top: -1rem;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem;
  }
  .mt-xl-n5 {
    margin-top: -2rem;
  }
  .mt-xl-n6 {
    margin-top: -2.5rem;
  }
  .mt-xl-n7 {
    margin-top: -3rem;
  }
  .mt-xl-n8 {
    margin-top: -3.5rem;
  }
  .mt-xl-n9 {
    margin-top: -4rem;
  }
  .mt-xl-n10 {
    margin-top: -5rem;
  }
  .mt-xl-n11 {
    margin-top: -6rem;
  }
  .mt-xl-n12 {
    margin-top: -7rem;
  }
  .me-xl-n1 {
    margin-right: -0.25rem;
  }
  .me-xl-n2 {
    margin-right: -0.5rem;
  }
  .me-xl-n3 {
    margin-right: -1rem;
  }
  .me-xl-n4 {
    margin-right: -1.5rem;
  }
  .me-xl-n5 {
    margin-right: -2rem;
  }
  .me-xl-n6 {
    margin-right: -2.5rem;
  }
  .me-xl-n7 {
    margin-right: -3rem;
  }
  .me-xl-n8 {
    margin-right: -3.5rem;
  }
  .me-xl-n9 {
    margin-right: -4rem;
  }
  .me-xl-n10 {
    margin-right: -5rem;
  }
  .me-xl-n11 {
    margin-right: -6rem;
  }
  .me-xl-n12 {
    margin-right: -7rem;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem;
  }
  .mb-xl-n5 {
    margin-bottom: -2rem;
  }
  .mb-xl-n6 {
    margin-bottom: -2.5rem;
  }
  .mb-xl-n7 {
    margin-bottom: -3rem;
  }
  .mb-xl-n8 {
    margin-bottom: -3.5rem;
  }
  .mb-xl-n9 {
    margin-bottom: -4rem;
  }
  .mb-xl-n10 {
    margin-bottom: -5rem;
  }
  .mb-xl-n11 {
    margin-bottom: -6rem;
  }
  .mb-xl-n12 {
    margin-bottom: -7rem;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem;
  }
  .ms-xl-n3 {
    margin-left: -1rem;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem;
  }
  .ms-xl-n5 {
    margin-left: -2rem;
  }
  .ms-xl-n6 {
    margin-left: -2.5rem;
  }
  .ms-xl-n7 {
    margin-left: -3rem;
  }
  .ms-xl-n8 {
    margin-left: -3.5rem;
  }
  .ms-xl-n9 {
    margin-left: -4rem;
  }
  .ms-xl-n10 {
    margin-left: -5rem;
  }
  .ms-xl-n11 {
    margin-left: -6rem;
  }
  .ms-xl-n12 {
    margin-left: -7rem;
  }
  .p-xl-0 {
    padding: 0;
  }
  .p-xl-1 {
    padding: 0.25rem;
  }
  .p-xl-2 {
    padding: 0.5rem;
  }
  .p-xl-3 {
    padding: 1rem;
  }
  .p-xl-4 {
    padding: 1.5rem;
  }
  .p-xl-5 {
    padding: 2rem;
  }
  .p-xl-6 {
    padding: 2.5rem;
  }
  .p-xl-7 {
    padding: 3rem;
  }
  .p-xl-8 {
    padding: 3.5rem;
  }
  .p-xl-9 {
    padding: 4rem;
  }
  .p-xl-10 {
    padding: 5rem;
  }
  .p-xl-11 {
    padding: 6rem;
  }
  .p-xl-12 {
    padding: 7rem;
  }
  .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xl-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-xl-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-xl-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-xl-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-xl-5 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-xl-6 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-xl-7 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-xl-8 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-xl-9 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-xl-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-xl-11 {
    padding-right: 6rem;
    padding-left: 6rem;
  }
  .px-xl-12 {
    padding-right: 7rem;
    padding-left: 7rem;
  }
  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-xl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-xl-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-xl-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-xl-5 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-xl-6 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-xl-7 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-xl-8 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-xl-9 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-xl-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-xl-11 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .py-xl-12 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .pt-xl-0 {
    padding-top: 0;
  }
  .pt-xl-1 {
    padding-top: 0.25rem;
  }
  .pt-xl-2 {
    padding-top: 0.5rem;
  }
  .pt-xl-3 {
    padding-top: 1rem;
  }
  .pt-xl-4 {
    padding-top: 1.5rem;
  }
  .pt-xl-5 {
    padding-top: 2rem;
  }
  .pt-xl-6 {
    padding-top: 2.5rem;
  }
  .pt-xl-7 {
    padding-top: 3rem;
  }
  .pt-xl-8 {
    padding-top: 3.5rem;
  }
  .pt-xl-9 {
    padding-top: 4rem;
  }
  .pt-xl-10 {
    padding-top: 5rem;
  }
  .pt-xl-11 {
    padding-top: 6rem;
  }
  .pt-xl-12 {
    padding-top: 7rem;
  }
  .pe-xl-0 {
    padding-right: 0;
  }
  .pe-xl-1 {
    padding-right: 0.25rem;
  }
  .pe-xl-2 {
    padding-right: 0.5rem;
  }
  .pe-xl-3 {
    padding-right: 1rem;
  }
  .pe-xl-4 {
    padding-right: 1.5rem;
  }
  .pe-xl-5 {
    padding-right: 2rem;
  }
  .pe-xl-6 {
    padding-right: 2.5rem;
  }
  .pe-xl-7 {
    padding-right: 3rem;
  }
  .pe-xl-8 {
    padding-right: 3.5rem;
  }
  .pe-xl-9 {
    padding-right: 4rem;
  }
  .pe-xl-10 {
    padding-right: 5rem;
  }
  .pe-xl-11 {
    padding-right: 6rem;
  }
  .pe-xl-12 {
    padding-right: 7rem;
  }
  .pb-xl-0 {
    padding-bottom: 0;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem;
  }
  .pb-xl-3 {
    padding-bottom: 1rem;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem;
  }
  .pb-xl-5 {
    padding-bottom: 2rem;
  }
  .pb-xl-6 {
    padding-bottom: 2.5rem;
  }
  .pb-xl-7 {
    padding-bottom: 3rem;
  }
  .pb-xl-8 {
    padding-bottom: 3.5rem;
  }
  .pb-xl-9 {
    padding-bottom: 4rem;
  }
  .pb-xl-10 {
    padding-bottom: 5rem;
  }
  .pb-xl-11 {
    padding-bottom: 6rem;
  }
  .pb-xl-12 {
    padding-bottom: 7rem;
  }
  .ps-xl-0 {
    padding-left: 0;
  }
  .ps-xl-1 {
    padding-left: 0.25rem;
  }
  .ps-xl-2 {
    padding-left: 0.5rem;
  }
  .ps-xl-3 {
    padding-left: 1rem;
  }
  .ps-xl-4 {
    padding-left: 1.5rem;
  }
  .ps-xl-5 {
    padding-left: 2rem;
  }
  .ps-xl-6 {
    padding-left: 2.5rem;
  }
  .ps-xl-7 {
    padding-left: 3rem;
  }
  .ps-xl-8 {
    padding-left: 3.5rem;
  }
  .ps-xl-9 {
    padding-left: 4rem;
  }
  .ps-xl-10 {
    padding-left: 5rem;
  }
  .ps-xl-11 {
    padding-left: 6rem;
  }
  .ps-xl-12 {
    padding-left: 7rem;
  }
  .text-xl-start {
    text-align: left;
  }
  .text-xl-end {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left;
  }
  .float-xxl-end {
    float: right;
  }
  .float-xxl-none {
    float: none;
  }
  .d-xxl-inline {
    display: inline;
  }
  .d-xxl-inline-block {
    display: inline-block;
  }
  .d-xxl-block {
    display: block;
  }
  .d-xxl-grid {
    display: grid;
  }
  .d-xxl-table {
    display: table;
  }
  .d-xxl-table-row {
    display: table-row;
  }
  .d-xxl-table-cell {
    display: table-cell;
  }
  .d-xxl-flex {
    display: flex;
  }
  .d-xxl-inline-flex {
    display: inline-flex;
  }
  .d-xxl-none {
    display: none;
  }
  .flex-xxl-fill {
    flex: 1 1 auto;
  }
  .flex-xxl-row {
    flex-direction: row;
  }
  .flex-xxl-column {
    flex-direction: column;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .gap-xxl-0 {
    gap: 0;
  }
  .gap-xxl-1 {
    gap: 0.25rem;
  }
  .gap-xxl-2 {
    gap: 0.5rem;
  }
  .gap-xxl-3 {
    gap: 1rem;
  }
  .gap-xxl-4 {
    gap: 1.5rem;
  }
  .gap-xxl-5 {
    gap: 2rem;
  }
  .gap-xxl-6 {
    gap: 2.5rem;
  }
  .gap-xxl-7 {
    gap: 3rem;
  }
  .gap-xxl-8 {
    gap: 3.5rem;
  }
  .gap-xxl-9 {
    gap: 4rem;
  }
  .gap-xxl-10 {
    gap: 5rem;
  }
  .gap-xxl-11 {
    gap: 6rem;
  }
  .gap-xxl-12 {
    gap: 7rem;
  }
  .justify-content-xxl-start {
    justify-content: flex-start;
  }
  .justify-content-xxl-end {
    justify-content: flex-end;
  }
  .justify-content-xxl-center {
    justify-content: center;
  }
  .justify-content-xxl-between {
    justify-content: space-between;
  }
  .justify-content-xxl-around {
    justify-content: space-around;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xxl-start {
    align-items: flex-start;
  }
  .align-items-xxl-end {
    align-items: flex-end;
  }
  .align-items-xxl-center {
    align-items: center;
  }
  .align-items-xxl-baseline {
    align-items: baseline;
  }
  .align-items-xxl-stretch {
    align-items: stretch;
  }
  .align-content-xxl-start {
    align-content: flex-start;
  }
  .align-content-xxl-end {
    align-content: flex-end;
  }
  .align-content-xxl-center {
    align-content: center;
  }
  .align-content-xxl-between {
    align-content: space-between;
  }
  .align-content-xxl-around {
    align-content: space-around;
  }
  .align-content-xxl-stretch {
    align-content: stretch;
  }
  .align-self-xxl-auto {
    align-self: auto;
  }
  .align-self-xxl-start {
    align-self: flex-start;
  }
  .align-self-xxl-end {
    align-self: flex-end;
  }
  .align-self-xxl-center {
    align-self: center;
  }
  .align-self-xxl-baseline {
    align-self: baseline;
  }
  .align-self-xxl-stretch {
    align-self: stretch;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-last {
    order: 6;
  }
  .m-xxl-0 {
    margin: 0;
  }
  .m-xxl-1 {
    margin: 0.25rem;
  }
  .m-xxl-2 {
    margin: 0.5rem;
  }
  .m-xxl-3 {
    margin: 1rem;
  }
  .m-xxl-4 {
    margin: 1.5rem;
  }
  .m-xxl-5 {
    margin: 2rem;
  }
  .m-xxl-6 {
    margin: 2.5rem;
  }
  .m-xxl-7 {
    margin: 3rem;
  }
  .m-xxl-8 {
    margin: 3.5rem;
  }
  .m-xxl-9 {
    margin: 4rem;
  }
  .m-xxl-10 {
    margin: 5rem;
  }
  .m-xxl-11 {
    margin: 6rem;
  }
  .m-xxl-12 {
    margin: 7rem;
  }
  .m-xxl-auto {
    margin: auto;
  }
  .mx-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .mx-xxl-3 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .mx-xxl-5 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .mx-xxl-6 {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  .mx-xxl-7 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .mx-xxl-8 {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }
  .mx-xxl-9 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .mx-xxl-10 {
    margin-right: 5rem;
    margin-left: 5rem;
  }
  .mx-xxl-11 {
    margin-right: 6rem;
    margin-left: 6rem;
  }
  .mx-xxl-12 {
    margin-right: 7rem;
    margin-left: 7rem;
  }
  .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xxl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .my-xxl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .my-xxl-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-xxl-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .my-xxl-5 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .my-xxl-6 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .my-xxl-7 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .my-xxl-8 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .my-xxl-9 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .my-xxl-10 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .my-xxl-11 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .my-xxl-12 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xxl-0 {
    margin-top: 0;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem;
  }
  .mt-xxl-3 {
    margin-top: 1rem;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem;
  }
  .mt-xxl-5 {
    margin-top: 2rem;
  }
  .mt-xxl-6 {
    margin-top: 2.5rem;
  }
  .mt-xxl-7 {
    margin-top: 3rem;
  }
  .mt-xxl-8 {
    margin-top: 3.5rem;
  }
  .mt-xxl-9 {
    margin-top: 4rem;
  }
  .mt-xxl-10 {
    margin-top: 5rem;
  }
  .mt-xxl-11 {
    margin-top: 6rem;
  }
  .mt-xxl-12 {
    margin-top: 7rem;
  }
  .mt-xxl-auto {
    margin-top: auto;
  }
  .me-xxl-0 {
    margin-right: 0;
  }
  .me-xxl-1 {
    margin-right: 0.25rem;
  }
  .me-xxl-2 {
    margin-right: 0.5rem;
  }
  .me-xxl-3 {
    margin-right: 1rem;
  }
  .me-xxl-4 {
    margin-right: 1.5rem;
  }
  .me-xxl-5 {
    margin-right: 2rem;
  }
  .me-xxl-6 {
    margin-right: 2.5rem;
  }
  .me-xxl-7 {
    margin-right: 3rem;
  }
  .me-xxl-8 {
    margin-right: 3.5rem;
  }
  .me-xxl-9 {
    margin-right: 4rem;
  }
  .me-xxl-10 {
    margin-right: 5rem;
  }
  .me-xxl-11 {
    margin-right: 6rem;
  }
  .me-xxl-12 {
    margin-right: 7rem;
  }
  .me-xxl-auto {
    margin-right: auto;
  }
  .mb-xxl-0 {
    margin-bottom: 0;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem;
  }
  .mb-xxl-5 {
    margin-bottom: 2rem;
  }
  .mb-xxl-6 {
    margin-bottom: 2.5rem;
  }
  .mb-xxl-7 {
    margin-bottom: 3rem;
  }
  .mb-xxl-8 {
    margin-bottom: 3.5rem;
  }
  .mb-xxl-9 {
    margin-bottom: 4rem;
  }
  .mb-xxl-10 {
    margin-bottom: 5rem;
  }
  .mb-xxl-11 {
    margin-bottom: 6rem;
  }
  .mb-xxl-12 {
    margin-bottom: 7rem;
  }
  .mb-xxl-auto {
    margin-bottom: auto;
  }
  .ms-xxl-0 {
    margin-left: 0;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem;
  }
  .ms-xxl-3 {
    margin-left: 1rem;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem;
  }
  .ms-xxl-5 {
    margin-left: 2rem;
  }
  .ms-xxl-6 {
    margin-left: 2.5rem;
  }
  .ms-xxl-7 {
    margin-left: 3rem;
  }
  .ms-xxl-8 {
    margin-left: 3.5rem;
  }
  .ms-xxl-9 {
    margin-left: 4rem;
  }
  .ms-xxl-10 {
    margin-left: 5rem;
  }
  .ms-xxl-11 {
    margin-left: 6rem;
  }
  .ms-xxl-12 {
    margin-left: 7rem;
  }
  .ms-xxl-auto {
    margin-left: auto;
  }
  .m-xxl-n1 {
    margin: -0.25rem;
  }
  .m-xxl-n2 {
    margin: -0.5rem;
  }
  .m-xxl-n3 {
    margin: -1rem;
  }
  .m-xxl-n4 {
    margin: -1.5rem;
  }
  .m-xxl-n5 {
    margin: -2rem;
  }
  .m-xxl-n6 {
    margin: -2.5rem;
  }
  .m-xxl-n7 {
    margin: -3rem;
  }
  .m-xxl-n8 {
    margin: -3.5rem;
  }
  .m-xxl-n9 {
    margin: -4rem;
  }
  .m-xxl-n10 {
    margin: -5rem;
  }
  .m-xxl-n11 {
    margin: -6rem;
  }
  .m-xxl-n12 {
    margin: -7rem;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .mx-xxl-n3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .mx-xxl-n5 {
    margin-right: -2rem;
    margin-left: -2rem;
  }
  .mx-xxl-n6 {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
  .mx-xxl-n7 {
    margin-right: -3rem;
    margin-left: -3rem;
  }
  .mx-xxl-n8 {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
  .mx-xxl-n9 {
    margin-right: -4rem;
    margin-left: -4rem;
  }
  .mx-xxl-n10 {
    margin-right: -5rem;
    margin-left: -5rem;
  }
  .mx-xxl-n11 {
    margin-right: -6rem;
    margin-left: -6rem;
  }
  .mx-xxl-n12 {
    margin-right: -7rem;
    margin-left: -7rem;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .my-xxl-n3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .my-xxl-n5 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .my-xxl-n6 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .my-xxl-n7 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .my-xxl-n8 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .my-xxl-n9 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .my-xxl-n10 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .my-xxl-n11 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .my-xxl-n12 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem;
  }
  .mt-xxl-n3 {
    margin-top: -1rem;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem;
  }
  .mt-xxl-n5 {
    margin-top: -2rem;
  }
  .mt-xxl-n6 {
    margin-top: -2.5rem;
  }
  .mt-xxl-n7 {
    margin-top: -3rem;
  }
  .mt-xxl-n8 {
    margin-top: -3.5rem;
  }
  .mt-xxl-n9 {
    margin-top: -4rem;
  }
  .mt-xxl-n10 {
    margin-top: -5rem;
  }
  .mt-xxl-n11 {
    margin-top: -6rem;
  }
  .mt-xxl-n12 {
    margin-top: -7rem;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem;
  }
  .me-xxl-n3 {
    margin-right: -1rem;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem;
  }
  .me-xxl-n5 {
    margin-right: -2rem;
  }
  .me-xxl-n6 {
    margin-right: -2.5rem;
  }
  .me-xxl-n7 {
    margin-right: -3rem;
  }
  .me-xxl-n8 {
    margin-right: -3.5rem;
  }
  .me-xxl-n9 {
    margin-right: -4rem;
  }
  .me-xxl-n10 {
    margin-right: -5rem;
  }
  .me-xxl-n11 {
    margin-right: -6rem;
  }
  .me-xxl-n12 {
    margin-right: -7rem;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem;
  }
  .mb-xxl-n5 {
    margin-bottom: -2rem;
  }
  .mb-xxl-n6 {
    margin-bottom: -2.5rem;
  }
  .mb-xxl-n7 {
    margin-bottom: -3rem;
  }
  .mb-xxl-n8 {
    margin-bottom: -3.5rem;
  }
  .mb-xxl-n9 {
    margin-bottom: -4rem;
  }
  .mb-xxl-n10 {
    margin-bottom: -5rem;
  }
  .mb-xxl-n11 {
    margin-bottom: -6rem;
  }
  .mb-xxl-n12 {
    margin-bottom: -7rem;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem;
  }
  .ms-xxl-n3 {
    margin-left: -1rem;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem;
  }
  .ms-xxl-n5 {
    margin-left: -2rem;
  }
  .ms-xxl-n6 {
    margin-left: -2.5rem;
  }
  .ms-xxl-n7 {
    margin-left: -3rem;
  }
  .ms-xxl-n8 {
    margin-left: -3.5rem;
  }
  .ms-xxl-n9 {
    margin-left: -4rem;
  }
  .ms-xxl-n10 {
    margin-left: -5rem;
  }
  .ms-xxl-n11 {
    margin-left: -6rem;
  }
  .ms-xxl-n12 {
    margin-left: -7rem;
  }
  .p-xxl-0 {
    padding: 0;
  }
  .p-xxl-1 {
    padding: 0.25rem;
  }
  .p-xxl-2 {
    padding: 0.5rem;
  }
  .p-xxl-3 {
    padding: 1rem;
  }
  .p-xxl-4 {
    padding: 1.5rem;
  }
  .p-xxl-5 {
    padding: 2rem;
  }
  .p-xxl-6 {
    padding: 2.5rem;
  }
  .p-xxl-7 {
    padding: 3rem;
  }
  .p-xxl-8 {
    padding: 3.5rem;
  }
  .p-xxl-9 {
    padding: 4rem;
  }
  .p-xxl-10 {
    padding: 5rem;
  }
  .p-xxl-11 {
    padding: 6rem;
  }
  .p-xxl-12 {
    padding: 7rem;
  }
  .px-xxl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xxl-1 {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .px-xxl-2 {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .px-xxl-3 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-xxl-4 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .px-xxl-5 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .px-xxl-6 {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .px-xxl-7 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .px-xxl-8 {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .px-xxl-9 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .px-xxl-10 {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .px-xxl-11 {
    padding-right: 6rem;
    padding-left: 6rem;
  }
  .px-xxl-12 {
    padding-right: 7rem;
    padding-left: 7rem;
  }
  .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xxl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-xxl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-xxl-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-xxl-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .py-xxl-5 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .py-xxl-6 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .py-xxl-7 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-xxl-8 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .py-xxl-9 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .py-xxl-10 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .py-xxl-11 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .py-xxl-12 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .pt-xxl-0 {
    padding-top: 0;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem;
  }
  .pt-xxl-3 {
    padding-top: 1rem;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem;
  }
  .pt-xxl-5 {
    padding-top: 2rem;
  }
  .pt-xxl-6 {
    padding-top: 2.5rem;
  }
  .pt-xxl-7 {
    padding-top: 3rem;
  }
  .pt-xxl-8 {
    padding-top: 3.5rem;
  }
  .pt-xxl-9 {
    padding-top: 4rem;
  }
  .pt-xxl-10 {
    padding-top: 5rem;
  }
  .pt-xxl-11 {
    padding-top: 6rem;
  }
  .pt-xxl-12 {
    padding-top: 7rem;
  }
  .pe-xxl-0 {
    padding-right: 0;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem;
  }
  .pe-xxl-3 {
    padding-right: 1rem;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem;
  }
  .pe-xxl-5 {
    padding-right: 2rem;
  }
  .pe-xxl-6 {
    padding-right: 2.5rem;
  }
  .pe-xxl-7 {
    padding-right: 3rem;
  }
  .pe-xxl-8 {
    padding-right: 3.5rem;
  }
  .pe-xxl-9 {
    padding-right: 4rem;
  }
  .pe-xxl-10 {
    padding-right: 5rem;
  }
  .pe-xxl-11 {
    padding-right: 6rem;
  }
  .pe-xxl-12 {
    padding-right: 7rem;
  }
  .pb-xxl-0 {
    padding-bottom: 0;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem;
  }
  .pb-xxl-5 {
    padding-bottom: 2rem;
  }
  .pb-xxl-6 {
    padding-bottom: 2.5rem;
  }
  .pb-xxl-7 {
    padding-bottom: 3rem;
  }
  .pb-xxl-8 {
    padding-bottom: 3.5rem;
  }
  .pb-xxl-9 {
    padding-bottom: 4rem;
  }
  .pb-xxl-10 {
    padding-bottom: 5rem;
  }
  .pb-xxl-11 {
    padding-bottom: 6rem;
  }
  .pb-xxl-12 {
    padding-bottom: 7rem;
  }
  .ps-xxl-0 {
    padding-left: 0;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem;
  }
  .ps-xxl-3 {
    padding-left: 1rem;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem;
  }
  .ps-xxl-5 {
    padding-left: 2rem;
  }
  .ps-xxl-6 {
    padding-left: 2.5rem;
  }
  .ps-xxl-7 {
    padding-left: 3rem;
  }
  .ps-xxl-8 {
    padding-left: 3.5rem;
  }
  .ps-xxl-9 {
    padding-left: 4rem;
  }
  .ps-xxl-10 {
    padding-left: 5rem;
  }
  .ps-xxl-11 {
    padding-left: 6rem;
  }
  .ps-xxl-12 {
    padding-left: 7rem;
  }
  .text-xxl-start {
    text-align: left;
  }
  .text-xxl-end {
    text-align: right;
  }
  .text-xxl-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem;
  }
  .fs-2 {
    font-size: 2rem;
  }
  .fs-3 {
    font-size: 1.75rem;
  }
  .fs-4 {
    font-size: 1.5rem;
  }
}

@media print {
  .d-print-inline {
    display: inline;
  }
  .d-print-inline-block {
    display: inline-block;
  }
  .d-print-block {
    display: block;
  }
  .d-print-grid {
    display: grid;
  }
  .d-print-table {
    display: table;
  }
  .d-print-table-row {
    display: table-row;
  }
  .d-print-table-cell {
    display: table-cell;
  }
  .d-print-flex {
    display: flex;
  }
  .d-print-inline-flex {
    display: inline-flex;
  }
  .d-print-none {
    display: none;
  }
}

/*.navbar-brand,
.navbar-brand > img {
	width: $px-logo-width;	
}*/
.header-main {
  background: #062575;
}
.header-dark .logo-dark {
  display: block;
}

.fixed-header .navbar {
  background: #ffffff;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.fixed-header .logo-light {
  display: none;
}

.fixed-header .logo-dark {
  display: block;
}

.header-main .navbar > .container {
  position: relative;
}

.header-main .navbar-nav > .nav-item {
  margin: 0 5px;
}
.fb-icon {
  line-height: 0;
  padding: 7px 10px;
  border-radius: 5px;
}
.header-main .navbar-nav > .nav-item > .nav-link {
  font-weight: 600;
  text-transform: none;
  font-size: 1.3rem;
}
.header-main .navbar-nav > .nav-item.dropdown > .nav-link:after {
  display: none;
}
.header-main .navbar-nav > .nav-item .mob-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
.header-main .navbar-nav > .nav-item .mob-menu:after {
  display: none;
}
.header-main .navbar-nav > .nav-item .mob-menu:before {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 18px;
  color: currentColor;
}

.header-main .navbar-nav .dropdown-menu {
  border: none;
  border-top: 2px solid #01a310;
  border-radius: 0;
  margin: 0;
}
.header-main .navbar-nav .dropdown-menu .dropdown-item {
  font-size: 0.95rem;
  padding: 0.4rem 1rem;
}

.header-light.header-toggle .navbar {
  background: #ffffff;
}

.header-dark.header-toggle:not(.fixed-header) .navbar {
  background: #112233;
}

.navbar-dark .mob-menu {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-light .mob-menu {
  color: rgba(0, 0, 0, 0.5);
}

.px-mega-menu {
  padding: 0;
}
.px-mega-menu .px-mm-left {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.px-mega-menu .px-mm-right {
  padding: 20px;
}
.px-mega-menu .mm-title {
  color: #01a310;
  font-size: 18px;
  font-weight: 700;
  padding: 0 0 10px;
  margin: 0;
}
.px-mega-menu .mm-link {
  margin: 0;
  padding: 0 0 15px;
}
.px-mega-menu .mm-link li {
  list-style: none;
  position: relative;
  padding: 5px 0;
}
.px-mega-menu .mm-link li > a {
  color: #718096;
  display: block;
  font-size: 0.95rem;
}
.px-mega-menu .mm-link li > a i {
  font-size: 14px;
  vertical-align: middle;
  width: 24px;
  display: inline-block;
}
.px-mega-menu .mm-link li:hover > a {
  color: #01a310;
}

@media (min-width: 1400px) {
  .header-main .navbar-expand-xxl .px-mega-menu {
    left: 15px;
    right: 15px;
    background: #ffffff;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
  }
  .header-main
    .navbar-expand-xxl
    .navbar-nav
    > .nav-item.dropdown
    > .nav-link:after {
    content: '\f078';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item.dropdown-xl {
    position: static;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-menu {
    display: none;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item:hover .dropdown-menu {
    display: block;
  }
  .header-main .navbar-expand-xxl .navbar-nav > .nav-item .mob-menu {
    display: none;
  }
}

@media (min-width: 1200px) {
  .header-main .navbar-expand-xl .px-mega-menu {
    left: 15px;
    right: 15px;
    background: #ffffff;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
  }
  .header-main
    .navbar-expand-xl
    .navbar-nav
    > .nav-item.dropdown
    > .nav-link:after {
    content: '\f078';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item.dropdown-xl {
    position: static;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item .dropdown-menu {
    display: none;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item:hover .dropdown-menu {
    display: block;
  }
  .header-main .navbar-expand-xl .navbar-nav > .nav-item .mob-menu {
    display: none;
  }
}

@media (min-width: 992px) {
  .header-main .navbar-expand-lg .px-mega-menu {
    left: 15px;
    right: 15px;
    background: #ffffff;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
    line-height: 25px;
    text-align: center;
  }

  .header-main
    .navbar-expand-lg
    .navbar-nav
    > .nav-item.dropdown
    > .nav-link:after {
    content: '\f078';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item.dropdown-xl {
    position: static;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item .dropdown-menu {
    display: none;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item:hover .dropdown-menu {
    display: block;
  }
  .header-main .navbar-expand-lg .navbar-nav > .nav-item .mob-menu {
    display: none;
  }
}

@media (min-width: 768px) {
  .header-main .navbar-expand-md .px-mega-menu {
    left: 15px;
    right: 15px;
    background: #ffffff;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
  }
  .header-main
    .navbar-expand-md
    .navbar-nav
    > .nav-item.dropdown
    > .nav-link:after {
    content: '\f078';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item.dropdown-xl {
    position: static;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item .dropdown-menu {
    display: none;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item:hover .dropdown-menu {
    display: block;
  }
  .header-main .navbar-expand-md .navbar-nav > .nav-item .mob-menu {
    display: none;
  }
}

@media (min-width: 576px) {
  .header-main .navbar-expand-sm .px-mega-menu {
    left: 15px;
    right: 15px;
    background: #ffffff;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
  }
  .header-main
    .navbar-expand-sm
    .navbar-nav
    > .nav-item.dropdown
    > .nav-link:after {
    content: '\f078';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 11px;
    border: none;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item.dropdown-xl {
    position: static;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item .dropdown-menu {
    display: none;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item:hover .dropdown-menu {
    display: block;
  }
  .header-main .navbar-expand-sm .navbar-nav > .nav-item .mob-menu {
    display: none;
  }
}

.header-main .navbar-expand-xs .px-mega-menu {
  left: 15px;
  right: 15px;
  background: #ffffff;
}

.header-main .navbar-expand-xs .navbar-nav > .nav-item > .nav-link {
  line-height: 60px;
}

.header-main
  .navbar-expand-xs
  .navbar-nav
  > .nav-item.dropdown
  > .nav-link:after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: currentColor;
  margin-left: 8px;
  display: inline-block;
  font-size: 11px;
  border: none;
}

.header-main .navbar-expand-xs .navbar-nav > .nav-item.dropdown-xl {
  position: static;
}

.header-main .navbar-expand-xs .navbar-nav > .nav-item .dropdown-menu {
  display: none;
}

.header-main .navbar-expand-xs .navbar-nav > .nav-item:hover .dropdown-menu {
  display: block;
}

.header-main .navbar-expand-xs .navbar-nav > .nav-item .mob-menu {
  display: none;
}

@media (max-width: 1399.98px) {
  .header-main .navbar-expand-xxl {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-main .navbar-expand-xxl .navbar-collapse {
    padding-top: 20px;
    max-height: 70vh;
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .header-main .navbar-expand-xl {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-main .navbar-expand-xl .navbar-collapse {
    padding-top: 20px;
    max-height: 70vh;
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .header-main .navbar-expand-lg {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-main .navbar-expand-lg .navbar-collapse {
    padding-top: 20px;
    max-height: 70vh;
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .header-main .navbar-expand-md {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-main .navbar-expand-md .navbar-collapse {
    padding-top: 20px;
    max-height: 70vh;
    overflow-y: auto;
  }
}

@media (max-width: 575.98px) {
  .header-main .navbar-expand-sm {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header-main .navbar-expand-sm .navbar-collapse {
    padding-top: 20px;
    max-height: 70vh;
    overflow-y: auto;
  }
}

.header-main .navbar-expand-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}
.header-main .navbar-expand-sm .navbar-collapse {
  padding-top: 20px;
  max-height: 70vh;
  overflow-y: auto;
}

.footer .footer-top {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 767.98px) {
  .footer .footer-top {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.footer .footer-bottom p {
  font-family: 'MYRIADPRO';
}
.footer .footer-bottom {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 20px;
  font-family: 'MYRIADPRO';
}

.footer .footer-bottom .nav li {
  margin-bottom: 0;
}
.footer .footer-bottom .nav li:not(:first-child),
.footer .footer-bottom .nav li:not(:last-child) {
  margin-right: 15px;
}

.footer h6,
.footer .h6 {
  margin-bottom: 20px;
  font-size: 1.12rem;
}

.footer .font-small {
  font-size: 0.8rem;
}

.footer a.theme2nd-color:hover {
  color: #ffffff;
}

.footer .footer-link-1 {
  margin: 0;
}
.footer .footer-link-1 li {
  margin: 0 0 10px;
}
.footer .footer-link-1 li a {
  position: relative;
}
.footer .footer-link-1 li a:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
  width: 0px;
  height: 1px;
  transition: ease all 0.35s;
  background: currentColor;
}
.footer .footer-link-1 li a:hover:after {
  width: 100%;
  left: 0;
  right: auto;
}
.footer .footer-link-1 li:last-child {
  margin-bottom: 0;
}

.footer .footer-border-light {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-border-dark {
  border-top: 1px solid rgba(23, 19, 71, 0.07);
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 99999;
}
.preloader .spinner-border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.display-1,
.display-2,
.display-3,
.display-4 {
  color: #112233;
}
.white-text .display-1,
.white-text .display-2,
.white-text .display-3,
.white-text .display-4 {
  color: #ffffff;
}

.text-white h1,
.text-white .h1,
.text-white h2,
.text-white .h2,
.text-white h3,
.text-white .h3,
.text-white h4,
.text-white .h4,
.text-white h5,
.text-white .h5,
.text-white h6,
.text-white .h6,
.text-white .h1,
.text-white .h2,
.text-white .h3,
.text-white .h4,
.text-white .h5,
.text-white .h6 {
  color: #ffffff;
}

mark,
.mark,
.mark {
  color: inherit;
  padding: 0;
  background: 0 0;
  background-image: linear-gradient(
    120deg,
    rgba(255, 193, 7, 0.4) 0,
    rgba(255, 193, 7, 0.4) 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 0.3em;
  background-position: 0 80%;
  position: relative;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

/* ----------------------
*   owl 
---------------------------*/
.owl-dots {
  text-align: center;
}
.owl-dots .owl-dot {
  display: inline-block;
  vertical-align: top;
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 1px solid #01a310;
  transition: ease all 0.55s;
  border-radius: 50%;
  margin: 0 5px;
}
.owl-carousel-white .owl-dots .owl-dot {
  border-color: #ffffff;
  background: none;
}
.owl-dots .owl-dot.active {
  background: #01a310;
}
.owl-carousel-white .owl-dots .owl-dot.active {
  background: #ffffff;
}

.owl-carousel .owl-item img {
  width: auto;
  max-width: 100%;
}

.owl-no-overflow .owl-stage-outer {
  overflow: inherit;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.z-index--1 {
  z-index: -1;
}

.z-index--2 {
  z-index: -2;
}

.price-table-01 .pt-head i {
  font-size: 60px;
  color: #ffffff;
  position: absolute;
  top: 30px;
  right: 20px;
  opacity: 0.5;
}

.price-table-01 .pt-head:after {
  content: '';
  position: absolute;
  top: -50px;
  left: -20px;
  right: -10px;
  bottom: 0;
  background: #062575;
  z-index: -1;
  transform: rotate(-7deg);
}

.price-table-01.active .pt-head:after {
  background: #01a310;
}

.list-type-01 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-type-01 li {
  position: relative;
  padding: 6px 25px 5px 0;
  font-weight: 500;
}
.list-type-01 li i {
  position: absolute;
  top: 13px;
  right: 0;
  color: #112233;
}
.list-type-01.theme li i {
  color: #01a310;
}
.list-type-01.white li i {
  color: #ffffff;
}

.list-type-02 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-type-02 li {
  position: relative;
  padding: 8px 0 8px 25px;
  font-family: 'MYRIADPRO';
  border-bottom: 1px solid #ccc;
  font-size: 22px;
  color: #000;
}
.list-type-02 li i {
  position: absolute;
  top: 13px;
  left: 0;
  color: #01a310;
}
.list-type-02.theme li i {
  color: #01a310;
}
.bb-0 li {
  border-bottom: none;
}
.list-type-03 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-type-03 li {
  position: relative;
  padding: 8px 0;
  display: flex;
  align-items: center;
}
.list-type-03 li i {
  max-width: 32px;
  flex: 0 0 32px;
  line-height: 32px;
  background: #15db95;
  text-align: center;
  border-radius: 50%;
  margin-right: 20px;
  color: #ffffff;
}

.list-type-04 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-type-04 li {
  position: relative;
  padding: 2px 0 2px 20px;
  color: #112233;
  font-size: 20px;
}
.list-type-04 li:after {
  content: '';
  width: 10px;
  height: 10px;
  border: 2px solid #15db95;
  position: absolute;
  top: 9px;
  left: 0;
  border-radius: 50%;
}

.list-type-05 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-type-05 li {
  position: relative;
  padding: 2px 0 2px 30px;
}
.list-type-05 li:after {
  content: '';
  width: 9px;
  height: 15px;
  border-right: 2px solid #01a310;
  position: absolute;
  top: 6px;
  left: 0;
  border-bottom: 2px solid #01a310;
  transform: rotate(45deg);
}

.list-type-06 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-type-06 li {
  padding: 6px 0;
}

.page-heading {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #fff;
  background-size: cover;
  position: relative;
}
.header-height + main .page-heading {
  padding-top: 6rem;
}
.page-heading .container {
  position: relative;
  z-index: 1;
}
.page-heading .breadcrumb li {
  color: #112233;
  font-weight: 600;
  font-size: 18px;
}

.page-heading .breadcrumb {
  border: none;
  padding: 0;
}
.page-heading .breadcrumb.white .breadcrumb-item {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.page-heading .breadcrumb.white .breadcrumb-item:before {
  color: #112233;
}
/* .page-heading .breadcrumb.white .breadcrumb-item a {
        color: #ffffff; } */

.links-white a:not(:hover) {
  color: rgba(255, 255, 255, 0.7);
}

.links-white a:hover {
  color: #ffffff;
}

.links-dark a:not(:hover) {
  color: #718096;
}

.links-dark a:hover {
  color: #112233;
}

.after-percentage:after {
  content: '%';
}

.pie_chart_in {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.pie_chart_in .middle {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/*Galletry*/
.gallery-box {
  position: relative;
  overflow: hidden;
}
.gallery-box .gallery-info {
  position: absolute;
  top: -50px;
  right: -50px;
  width: 100px;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: ease all 0.55s;
}
.gallery-box .gallery-info a {
  position: absolute;
  bottom: 13px;
  left: 16px;
  color: #112233;
}
.gallery-box:hover .gallery-info {
  opacity: 1;
}

/*Portfolio Filter*/
.portfolio-filter-01 .filter li {
  cursor: pointer;
  margin: 0 15px;
  color: #112233;
  position: relative;
  padding: 5px 0;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  line-height: normal;
}
.portfolio-filter-01 .filter li:after {
  content: '';
  width: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 2px;
  background: #01a310;
  position: absolute;
  transition: ease all 0.55s;
}
.portfolio-filter-01 .filter li:last-child {
  margin-right: 0;
}
.portfolio-filter-01 .filter li:first-child {
  margin-left: 0;
}
.portfolio-filter-01 .filter li:hover {
  color: #01a310;
}
.portfolio-filter-01 .filter li.active {
  color: #01a310;
}
.portfolio-filter-01 .filter li.active:after {
  width: 100%;
}

/*Portfolio Style 1*/
.portfolio-box {
  position: relative;
  overflow: hidden;
}
.portfolio-box:before {
  display: block;
  content: '';
  width: 100%;
  padding-top: 100%;
}
.portfolio-box.lg:before {
  padding-top: 125%;
}
.portfolio-box .portfolio-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-position: 50% 50%;
  background-size: cover;
  bottom: 0;
  transition: ease all 0.35s;
  transform: scale(1.2);
}
.portfolio-box .portfolio-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
}
.portfolio-box .portfolio-link h3,
.portfolio-box .portfolio-link .h3 {
  margin: 10px 0 0;
}
.portfolio-box .portfolio-link .portfolio-label {
  background: linear-gradient(to top, #000000, rgba(23, 19, 71, 0));
  width: 100%;
  padding: 25px 20px 20px;
}
.portfolio-box:hover .portfolio-bg {
  transform: scale(1.1);
}

/*Portfolio Style 2*/
.portfolio-box-01 {
  position: relative;
  overflow: hidden;
}
.portfolio-box-01 .portfolio-img {
  overflow: hidden;
  background: #ffffff;
}
.portfolio-box-01 .portfolio-img img {
  transition: ease all 0.35s;
  transform: scale(1);
}
.portfolio-box-01 .portfolio-info {
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  transition: ease all 0.35s;
}
.portfolio-box-01 .portfolio-icons {
  position: absolute;
  top: 0;
  right: 0px;
  z-index: 1;
  padding: 15px;
  transition: ease all 0.35s;
  opacity: 0;
}
.portfolio-box-01:hover .portfolio-img {
  overflow: hidden;
}
.portfolio-box-01:hover .portfolio-img img {
  transform: scale(1.1);
  opacity: 0.8;
}
.portfolio-box-01:hover .portfolio-icons {
  opacity: 1;
}
.portfolio-box-01:hover .portfolio-info {
  bottom: 0;
}

/*Portfolio Style 3*/
.portfolio-box-02 {
  position: relative;
  overflow: hidden;
}
.portfolio-box-02 .portfolio-img img {
  transition: ease all 0.55s;
  transform: scale(1);
}
.portfolio-box-02 .portfolio-info {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 51, 204, 0.8);
  transition: ease all 0.55s;
  opacity: 0;
}
.portfolio-box-02 .gallery-link {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 52px;
  border: 1px solid #ffffff;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  transition: ease all 0.55s;
}
.portfolio-box-02 .gallery-link i {
  display: inline-block;
}
.portfolio-box-02 .gallery-link:hover {
  background: #ffffff;
  color: #01a310;
}
.portfolio-box-02 .portfolio-desc {
  position: absolute;
  left: 20px;
  bottom: 20px;
  transition: ease all 0.55s;
  transition-delay: 0.15s;
  opacity: 0;
}
.portfolio-box-02 .portfolio-desc h5,
.portfolio-box-02 .portfolio-desc .h5 {
  font-size: 16px;
  margin: 0 0 15px;
  font-weight: 500;
}
.portfolio-box-02 .portfolio-desc h5 a,
.portfolio-box-02 .portfolio-desc .h5 a {
  color: #ffffff;
  text-decoration: none;
}
.portfolio-box-02 .portfolio-desc .pb-tag a {
  display: inline-block;
  vertical-align: top;
  background: #ffffff;
  border: 1px solid #ffffff;
  padding: 4px 15px;
  color: #01a310;
  border-radius: 3px;
  margin-right: 8px;
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;
  transition: ease all 0.55s;
}
.portfolio-box-02 .portfolio-desc .pb-tag a:hover {
  background: #01a310;
  color: #ffffff;
}
.portfolio-box-02:hover .portfolio-img img {
  transform: scale(1.2);
}
.portfolio-box-02:hover .portfolio-desc {
  opacity: 1;
}
.portfolio-box-02:hover .portfolio-info {
  position: absolute;
  opacity: 1;
}

.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-weight: 600;
  height: 3.125rem;
  width: 3.125rem;
}
.avatar-sm {
  height: 2.4375rem;
  width: 2.4375rem;
}
.avatar-lg {
  height: 4rem;
  width: 4rem;
}
.avatar-xl {
  height: 6rem;
  width: 6rem;
}
.avatar-group .avatar {
  margin-left: -1.25rem;
}
.avatar-group .avatar:hover {
  z-index: 1;
}
.avatar-parent {
  display: inline-block;
  position: relative;
}
.avatar-child {
  position: absolute;
  bottom: -0.45rem;
  border: 2px solid #ffffff;
  left: 0;
  right: 0;
  margin: auto;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
}

.avatar-220 {
  width: 220px;
  height: 220px;
}

.border-radius-effect {
  border-radius: 73% 27% 38% 62%/40% 43% 57% 60%;
}

.separated {
  overflow: hidden;
}
.separated > * {
  box-shadow: -1px -1px 0px 0px #eee;
}

.border-style {
  position: relative;
}
.border-style:after {
  content: '';
  display: block;
  width: 80%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 1px;
  background: radial-gradient(
    ellipse at center,
    #dee4ef 0,
    rgba(255, 255, 255, 0) 75%
  );
}
.border-style.bottom:after {
  bottom: 0;
}
.border-style.top:after {
  top: 0;
}
.border-style.light:after {
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 0.2) 0,
    rgba(31, 45, 61, 0) 75%
  );
}

.font-w-100 {
  font-weight: 100;
}

.font-w-200 {
  font-weight: 200;
}

.font-w-300 {
  font-weight: 300;
}

.font-w-400 {
  font-weight: 400;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-600 {
  font-weight: 600;
}

.font-w-700 {
  font-weight: 700;
}

.font-w-800 {
  font-weight: 800;
}

.font-w-900 {
  font-weight: 900;
}

.hover-top {
  transition: ease-in-out all 0.35s;
  position: relative;
  top: 0;
}
.hover-top:hover {
  top: -10px;
}

.shadow-hover {
  box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
  transition: ease all 0.35s;
}
.shadow-hover:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-only-hover {
  transition: ease all 0.35s;
}
// .shadow-only-hover:hover {
//   box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }

.arrow-hover {
  position: relative;
}
.arrow-hover .arrow-icon {
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 30px;
  margin: auto;
  transition: ease all 0.35s;
}
@media (prefers-reduced-motion: reduce) {
  .arrow-hover .arrow-icon {
    transition: none;
  }
}
.arrow-hover:hover .arrow-icon {
  opacity: 1;
  right: 15px;
}

.theme-hover-bg {
  background: #062575;
}
.theme-hover-bg:hover {
  background: #062575;
}
.theme-hover-bg:hover h6,
.theme-hover-bg:hover .h6 {
  color: #ffffff;
}
.theme-hover-bg:hover p {
  color: #ffffff;
}

.hover-top-in .hover-top--in {
  position: relative;
  top: 0;
  transition: ease all 0.35s;
}
.hover-top-in .hover-top--in.mt-n4 {
  margin-top: -24px;
}

.hover-top-in:hover .hover-top--in {
  top: -10px;
}

.grayscale-hover img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.7;
  transition: ease all 0.35s;
}

.grayscale-hover:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

.flip-box {
  position: relative;
  overflow: hidden;
}
.flip-box .flip-box-img {
  transition: ease all 0.35s;
  transform: rotateY(0deg);
}
.flip-box .flip-box-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: ease all 0.35s;
  transform: rotateY(180deg);
}
.flip-box:hover .flip-box-img {
  transform: rotateY(180deg);
  opacity: 0;
}
.flip-box:hover .flip-box-content {
  opacity: 1;
  transform: rotateY(0deg);
}

.line-hover {
  position: relative;
  overflow: hidden;
}
.line-hover:before,
.line-hover:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  transition: ease all 0.3s;
  pointer-events: none;
}
.line-hover:before {
  top: 0;
  left: 0;
  border-left: 5px solid #01a310;
  border-top: 5px solid #01a310;
}
.line-hover:after {
  bottom: 0;
  right: 0;
  border-right: 5px solid #01a310;
  border-bottom: 5px solid #01a310;
}
.line-hover:hover:after,
.line-hover:hover:before {
  opacity: 1;
  width: 100%;
  height: 100%;
}

.client-hover {
  position: relative;
}
.client-hover .client-bg:after {
  content: '';
  padding-bottom: 100%;
  display: inline-block;
  vertical-align: top;
}
.client-hover .client-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.client-hover .client-info p {
  opacity: 0;
  margin-bottom: 0;
  height: 0;
  transition: ease all 0.35s;
}
.client-hover:hover .client-info p {
  height: 100px;
  opacity: 1;
}

.feature-hover-1 {
  position: relative;
  overflow: hidden;
}
.feature-hover-1 .feature-content {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  padding: 10%;
  opacity: 0;
  transition: ease all 0.35s;
}
.feature-hover-1:hover .feature-content {
  opacity: 1;
  left: 0;
}

.feature-hover-2 {
  position: relative;
  overflow: hidden;
}
.feature-hover-2 .feature-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 8%;
}
.feature-hover-2 .feature-content .btn-bar {
  position: relative;
  margin-bottom: -30px;
  opacity: 0;
  transition: ease all 0.35s;
}
.feature-hover-2:hover .feature-content .btn-bar {
  opacity: 1;
  margin-bottom: 0;
}

.feature-hover-3 {
  position: relative;
  overflow: hidden;
}
.feature-hover-3 .feature-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 8%;
  padding-top: 12%;
  margin-bottom: -30px;
  opacity: 0;
  background: rgba(23, 19, 71, 0.6);
  transition: ease all 0.35s;
}
.feature-hover-3:hover .feature-content {
  opacity: 1;
  padding-top: 8%;
}

.feature-box-1 {
  position: relative;
}
.feature-box-1:not(.last):after {
  content: '';
  position: absolute;
  top: 24%;
  width: 60px;
  right: -30px;
  height: 1px;
  background: #000000;
  opacity: 0.2;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .feature-box-1[class*='col-lg']:nth-child(2n + 2):after {
    opacity: 0;
  }
}
@media (max-width: 767.98px) {
  .feature-box-1[class*='col-md']:after {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .feature-box-1[class*='col-sm']:after {
    display: none;
  }
}

.media {
  display: flex;
}
.media .media-body {
  flex: 1;
}

img {
  max-width: 100%;
}

.bg-center {
  background-position: center;
}

.bg-cover {
  background-size: cover;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-fit {
  -o-object-fit: cover;
  object-fit: cover;
}

@media (min-width: 992px) {
  .img-lg-120 {
    max-width: 120%;
  }
  .img-lg-140 {
    max-width: 140%;
  }
}

@media (min-width: 768px) {
  .img-md-120 {
    max-width: 120%;
  }
  .img-md-140 {
    max-width: 140%;
  }
}

/*------------------
*   Tab style 01
-------------------*/
.tab-style-1 .nav {
  border-bottom: 1px solid #eff2f7;
}
@media (max-width: 767.98px) {
  .tab-style-1 .nav {
    margin-bottom: 15px;
  }
}

.tab-style-1 .nav-item {
  margin: 0 10px;
}
@media (max-width: 767.98px) {
  .tab-style-1 .nav-item {
    margin: 0 5px;
  }
}
.tab-style-1 .nav-item a {
  color: #112233;
  position: relative;
  padding: 12px 15px;
  text-align: center;
  border-bottom: none;
  width: 100%;
  display: block;
}
@media (max-width: 767.98px) {
  .tab-style-1 .nav-item a {
    padding: 10px 12px;
  }
}
.tab-style-1 .nav-item a:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 1px;
  background: #01a310;
  transition: ease-in-out all 0.55s;
}
.tab-style-1 .nav-item a.active {
  color: #01a310;
}
.tab-style-1 .nav-item a.active:after {
  width: 100%;
}
@media (min-width: 768px) {
  .tab-style-1 .nav-item .icon {
    margin-bottom: 8px;
  }
}
.tab-style-1 .nav-item .icon svg {
  height: 38px;
  width: 38px;
}
@media (max-width: 767.98px) {
  .tab-style-1 .nav-item .icon svg {
    height: 30px;
    width: 30px;
  }
}
.tab-style-1 .nav-item span {
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  display: block;
}
@media (max-width: 767.98px) {
  .tab-style-1 .nav-item span {
    display: none;
  }
}

.tab-style-1 .tab-pane {
  padding-top: 20px;
}

/*------------------
*   Tab style 02
-------------------*/
.tab-style-2 .nav {
  margin-bottom: 15px;
  border: none;
}
.tab-style-2 .nav .nav-item {
  margin-right: 18px;
}
.tab-style-2 .nav .nav-item a {
  padding: 8px 0;
  font-weight: 600;
  color: #112233;
  position: relative;
  display: block;
}
.tab-style-2 .nav .nav-item a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0px;
  transition: ease-in-out all 0.55s;
  height: 2px;
  background: #15db95;
}
.tab-style-2 .nav .nav-item a.active {
  color: #01a310;
}
.tab-style-2 .nav .nav-item a.active:after {
  width: 100%;
}

/*------------------
*   Tab style 03
-------------------*/
.tab-style-3 .nav {
  background: #ffffff;
  border: none;
}
.tab-style-3 .nav a {
  display: block;
  padding: 15px 10px 18px;
  line-height: normal;
  position: relative;
  color: #718096;
}
.tab-style-3 .nav a:after,
.tab-style-3 .nav a:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: ease-in-out all 0.25s;
  margin: auto;
}
.tab-style-3 .nav a:after {
  width: 0px;
  height: 3px;
  background: #15db95;
}
.tab-style-3 .nav a:before {
  width: 20px;
  background: #ffffff;
  border-radius: 50%;
  z-index: 1;
  height: 20px;
  bottom: -8px;
  box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
}
.tab-style-3 .nav a.active {
  color: #15db95;
}
.tab-style-3 .nav a.active:after {
  width: 100%;
}
.tab-style-3 .nav .tab-icon {
  margin-bottom: 10px;
}
.tab-style-3 .nav .tab-icon svg {
  width: 35px;
  height: 35px;
}
.tab-style-3 .nav span {
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .tab-style-3 .nav span {
    display: none;
  }
}

.tab-style-3 .tab-content {
  padding-top: 30px;
}

/*------------------
*   Tab style 04
-------------------*/
.tab-style-4 .nav {
  margin-bottom: 15px;
  border-bottom: 2px solid #eff2f7;
}
.tab-style-4 .nav .nav-item .tb-icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 30px;
  color: #01a310;
}
.tab-style-4 .nav .nav-item span {
  margin-left: 10px;
  vertical-align: middle;
}
@media (max-width: 575.98px) {
  .tab-style-4 .nav .nav-item span {
    display: none;
  }
}
.tab-style-4 .nav .nav-item a {
  padding: 15px 0;
  font-weight: 600;
  color: #112233;
  position: relative;
  display: block;
}
.tab-style-4 .nav .nav-item a:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: auto;
  right: 0;
  width: 0px;
  transition: ease-in-out all 0.55s;
  height: 3px;
  background: #01a310;
}
.tab-style-4 .nav .nav-item a.active:after {
  left: 0;
  right: auto;
  width: 100%;
}

/*------------------
*   Tab style 05
-------------------*/
.tab-style-5 .nav-tabs {
  border: none;
}
.tab-style-5 .nav-tabs .nav-item {
  margin: 5px;
}
.tab-style-5 .nav-tabs .nav-item > a {
  position: relative;
  padding: 15px 10px;
  font-weight: 600;
  color: #112233;
  border: none;
  border-radius: 10px;
  background: #ffffff;
}
.tab-style-5 .nav-tabs .nav-item > a.active {
  color: #ffffff;
  background: #01a310;
}

/* particles-box
---------------------------*/
.particles-box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: 0.2;
  pointer-events: none;
}

/* Effect Section
------------------------*/
.effect-section {
  position: relative;
  overflow: hidden;
}
.effect-section .effect {
  pointer-events: none;
}
.effect-section .effect-img {
  position: absolute;
  right: -2px;
  left: -2px;
}
.effect-section .effect-img svg {
  color: currentColor;
}
.effect-section .effect-img img {
  width: 100%;
}
.effect-section .effect-img.bottom {
  bottom: -2px;
}
.effect-section .effect-img.top {
  top: -2px;
}
.effect-section .effect-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.effect-section .effect-bg-75 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 25%;
}
.effect-section .effect-bg-65 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 35%;
}
.effect-section .effect-bg-40 {
  position: absolute;
  top: 0;
  bottom: 60%;
  left: 0;
  right: 0;
}
.effect-section .effect-bg-40.bottom {
  top: 60%;
  bottom: 0;
}
.effect-section .effect-round {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 0 220px;
}
.effect-section .effect-skew {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50%;
  right: 31%;
  -moz-transform: skew(-35deg);
  -o-transform: skew(-35deg);
  -ms-transform: skew(-35deg);
  -webkit-transform: skew(-35deg);
  transform: skew(-35deg);
  border-radius: 0 0 180px 0;
}
@media (max-width: 575.98px) {
  .effect-section .effect-skew {
    right: -40%;
    left: -90%;
  }
}
.effect-section .effect-skew-01 {
  position: absolute;
  top: -10%;
  bottom: -30%;
  right: -50%;
  left: 40%;
  border-radius: 27% 73% 32% 68% / 52% 66% 34% 48%;
}
@media (max-width: 575.98px) {
  .effect-section .effect-skew-01 {
    right: -40%;
    left: -90%;
  }
}
.effect-section .effect-radius {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0% 100% 20% 80% / 73% 0% 100% 27%;
}
@media (max-width: 575.98px) {
  .effect-section .effect-radius {
    bottom: 30px;
    left: -50px;
    right: -40px;
  }
}
.effect-section .effect-middle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20%;
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
  -ms-transform: skew(30deg);
  -webkit-transform: skew(30deg);
  transform: skew(30deg);
  z-index: -1;
}
.effect-section .effect-shape {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.effect-section .effect-shape:after {
  content: '';
  position: absolute;
  bottom: -40%;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.08),
    rgba(2, 2, 2, 0.05)
  );
  right: -25%;
  margin: auto;
  transform: skew(30deg);
  top: -40%;
  left: 45%;
  border-radius: 50%;
}
.effect-section .effect-radius-bg {
  position: absolute;
  top: 58%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}
.effect-section .effect-radius-bg > div {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 23rem;
  height: 23rem;
  border-radius: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(227, 221, 246, 0.1)),
    color-stop(65%, rgba(227, 221, 246, 0.2)),
    to(rgba(227, 221, 246, 0.1))
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(227, 221, 246, 0.1) 0%,
    rgba(227, 221, 246, 0.2) 65%,
    rgba(227, 221, 246, 0.1) 100%
  );
  background-image: -o-linear-gradient(
    top,
    rgba(227, 221, 246, 0.1) 0%,
    rgba(227, 221, 246, 0.2) 65%,
    rgba(227, 221, 246, 0.1) 100%
  );
  background-image: linear-gradient(
    -180deg,
    rgba(227, 221, 246, 0.1) 0%,
    rgba(227, 221, 246, 0.2) 65%,
    rgba(227, 221, 246, 0.1) 100%
  );
  z-index: -1;
}
.effect-section .effect-radius-bg .radius-1 {
  -webkit-transform: translate(-50%, -50%) scale(0.67);
  -ms-transform: translate(-50%, -50%) scale(0.67);
  transform: translate(-50%, -50%) scale(0.67);
}
.effect-section .effect-radius-bg .radius-2 {
  -webkit-transform: translate(-50%, -50%) scale(1.05);
  -ms-transform: translate(-50%, -50%) scale(1.05);
  transform: translate(-50%, -50%) scale(1.05);
}
.effect-section .effect-radius-bg .radius-3 {
  -webkit-transform: translate(-50%, -50%) scale(1.63);
  -ms-transform: translate(-50%, -50%) scale(1.63);
  transform: translate(-50%, -50%) scale(1.63);
}
.effect-section .effect-radius-bg .radius-4 {
  -webkit-transform: translate(-50%, -50%) scale(2.1);
  -ms-transform: translate(-50%, -50%) scale(2.1);
  transform: translate(-50%, -50%) scale(2.1);
}
.effect-section .effect-radius-bg .radius-x {
  will-change: transform;
  -webkit-animation: pulsate 3s infinite;
  animation: pulsate 3s infinite;
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4);
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4);
  }
}

/* After
------------------------*/
.after-50px {
  position: relative;
}
.after-50px:after {
  width: 50px;
  height: 2px;
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
}
.text-center .after-50px:after {
  right: 0;
  margin: auto;
}

.hr-line {
  height: 3px;
  font-size: 0px;
}
.hr-line span {
  width: 50px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 3px;
  margin-right: 15px;
  border-radius: 3px;
}
.hr-line span:after {
  content: '';
  background: currentColor;
  width: 15px;
  height: 3px;
  margin-right: 10px;
  position: absolute;
  top: 0;
  right: -30px;
}

/* Sticky
------------------------*/
@media (min-width: 1200px) {
  .fixed-header ~ main .sticky-xl-top {
    top: 90px;
  }
}

@media (min-width: 992px) {
  .fixed-header ~ main .sticky-lg-top {
    top: 90px;
  }
}

@media (min-width: 768px) {
  .fixed-header ~ main .sticky-md-top {
    top: 90px;
  }
}

@media (min-width: 576px) {
  .fixed-header ~ main .sticky-sm-top {
    top: 90px;
  }
}

/* Parallax
--------------*/
.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  /* support for plugin https://github.com/bfred-it/object-fit-images */
  font-family: 'object-fit: cover;';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* Video Button
-----------------------*/
.video-btn {
  position: relative;
  width: 80px;
  height: 80px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  transition: ease all 0.55s;
  z-index: 1;
  border: none;
}
.video-btn:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  pointer-events: none;
  z-index: -1;
}
.video-btn.white {
  background: #ffffff;
}
.video-btn.white:after {
  background: #ffffff;
}
.video-btn.white span {
  color: #01a310;
}
.video-btn.theme {
  background: #01a310;
}
.video-btn.theme:after {
  background: #01a310;
}
.video-btn.theme span {
  color: #ffffff;
}
.video-btn span {
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 20px solid currentcolor;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 9px;
  right: 0;
  margin: auto;
  width: 0px;
  height: 0px;
}

@keyframes pulse-border {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 3rem;
  height: 3rem;
}
.icon i {
  font-size: 1.125rem;
}
.icon-sm {
  width: 2rem;
  height: 2rem;
}
.icon-sm i {
  font-size: 0.75rem;
}
.icon-xl {
  width: 6rem;
  height: 6rem;
}
.icon-xl i {
  font-size: 2.25rem;
}
.icon-lg {
  width: 4rem;
  height: 4rem;
}
.icon-lg i {
  font-size: 1.75rem;
}
.icon-xs {
  width: 1rem;
  height: 1rem;
}
.icon-xs i {
  font-size: 0.5rem;
}

.only-icon {
  display: inline-flex;
}
.only-icon i {
  font-size: 1.8rem;
}
.only-icon-sm i {
  font-size: 1rem;
}
.only-icon-lg i {
  font-size: 2.25rem;
}
.only-icon-xl i {
  font-size: 3rem;
}

.icon-primary {
  color: #ffffff;
  background-color: #01a310;
}

.icon-secondary {
  color: #ffffff;
  background-color: #15db95;
}

.icon-success {
  color: #ffffff;
  background-color: #2dca8c;
}

.icon-info {
  color: #ffffff;
  background-color: #50b5ff;
}

.icon-warning {
  color: #ffffff;
  background-color: #ff9f1c;
}

.icon-danger {
  color: #ffffff;
  background-color: #ff5c75;
}

.icon-light {
  color: #112233;
  background-color: #f1f6fd;
}

.icon-dark {
  color: #ffffff;
  background-color: #112233;
}

a.icon-primary:hover {
  color: #ffffff;
  background-color: #002699;
}

a.icon-secondary:hover {
  color: #ffffff;
  background-color: #11ac75;
}

a.icon-success:hover {
  color: #ffffff;
  background-color: #24a06f;
}

a.icon-info:hover {
  color: #ffffff;
  background-color: #1d9fff;
}

a.icon-warning:hover {
  color: #ffffff;
  background-color: #e88600;
}

a.icon-danger:hover {
  color: #ffffff;
  background-color: #ff294a;
}

a.icon-light:hover {
  color: #112233;
  background-color: #c4d9f7;
}

a.icon-dark:hover {
  color: #ffffff;
  background-color: #0a081f;
}

.icon-primary-light {
  color: #01a310;
  background-color: rgba(0, 51, 204, 0.2);
}

.icon-secondary-light {
  color: #15db95;
  background-color: rgba(21, 219, 149, 0.2);
}

.icon-success-light {
  color: #2dca8c;
  background-color: rgba(45, 202, 140, 0.2);
}

.icon-info-light {
  color: #50b5ff;
  background-color: rgba(80, 181, 255, 0.2);
}

.icon-warning-light {
  color: #ff9f1c;
  background-color: rgba(255, 159, 28, 0.2);
}

.icon-danger-light {
  color: #ff5c75;
  background-color: rgba(255, 92, 117, 0.2);
}

.icon-light-light {
  color: #f1f6fd;
  background-color: rgba(241, 246, 253, 0.2);
}

.icon-dark-light {
  color: #112233;
  background-color: rgba(23, 19, 71, 0.2);
}

.icon-after {
  position: relative;
  z-index: 1;
}
.icon-after:after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  width: 40px;
  height: 40px;
  border-radius: 60%;
  z-index: -1;
  opacity: 0.4;
}

.dots-icon {
  position: relative;
}

.dots {
  z-index: 0;
  animation: dots 6s infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.dots .dot {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -3px;
  margin-top: -3px;
}
.dots .dot1 {
  background-color: #8ad9fd;
  transform: translate(15px, 5px);
  animation: dot1 1.5s infinite alternate;
}
.dots .dot2 {
  background-color: #b8a6f7;
  transform: translate(-8px, 8px);
  animation: dot2 1.5s infinite alternate;
  animation-delay: 0.5s;
}
.dots .dot3 {
  background-color: #fac5a7;
  transform: translate(-5px, -12px);
  animation: dot3 1.5s infinite alternate;
  animation-delay: 1s;
}

@keyframes dots {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(0);
  }
}

@keyframes dot1 {
  from {
    transform: translate(15px, 5px);
    opacity: 1;
  }
  to {
    transform: translate(25px, 5px);
    opacity: 1;
  }
}

@keyframes dot2 {
  from {
    transform: translate(-8px, 8px);
    opacity: 1;
  }
  to {
    transform: translate(-19px, 19px);
    opacity: 1;
  }
}

@keyframes dot3 {
  from {
    transform: translate(-5px, -12px);
    opacity: 1;
  }
  to {
    transform: translate(-10px, -24px);
    opacity: 1;
  }
}

.arrow-icon {
  width: 32px;
  height: 12px;
  position: relative;
}
.arrow-icon:before {
  width: 32px;
  height: 2px;
  background: currentColor;
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  position: absolute;
}
.arrow-icon:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  margin: auto;
  right: 0;
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hover-rotate .hr-rotate {
  animation: lds-dual-ring 1.2s linear infinite;
  animation-play-state: paused;
}

.hover-rotate .hr-rotate-after {
  position: relative;
  z-index: 1;
}
.hover-rotate .hr-rotate-after:after {
  content: '';
  position: absolute;
  margin: auto;
  border-radius: 50px;
  transition: ease all 0.55s;
  z-index: -1;
  animation: lds-dual-ring 1.2s linear infinite;
  animation-play-state: paused;
}

.hover-rotate .ef-1:after {
  border-top: 50px solid transparent;
  border-left: 50px solid currentColor;
  top: -10px;
  left: -10px;
  border-right: 50px solid transparent;
  border-bottom: 50px solid transparent;
}

.hover-rotate .ef-2:after {
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 4px solid currentcolor;
  border-bottom: 4px solid transparent;
}

.hover-rotate .ef-3:after {
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border: 2px solid currentcolor;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
}

.hover-rotate .ef-4:after {
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border: 2px solid currentcolor;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
}

.hover-rotate:hover .hr-rotate {
  animation-play-state: running;
}

.hover-rotate:hover .hr-rotate-after:after {
  animation-play-state: running;
}

.rounded-style {
  border-radius: 73% 27% 38% 62%/40% 43% 57% 60%;
}

.bg-gray-100 {
  background-color: #f5f8fb;
}

.bg-gray-200 {
  background-color: #eff2f7;
}

.bg-gray-300 {
  background-color: #e2e8f0;
}

.bg-gray-400 {
  background-color: #cbd5e0;
}

.bg-gray-500 {
  background-color: #a0aec0;
}

.bg-gray-600 {
  background-color: #718096;
}

.bg-gray-700 {
  background-color: #4a5568;
}

.bg-gray-800 {
  background-color: #2d3748;
}

.bg-gray-900 {
  background-color: #273444;
}

.bg-primary-light {
  background-color: rgba(0, 51, 204, 0.2);
}

.bg-secondary-light {
  background-color: rgba(21, 219, 149, 0.2);
}

.bg-success-light {
  background-color: rgba(45, 202, 140, 0.2);
}

.bg-info-light {
  background-color: rgba(80, 181, 255, 0.2);
}

.bg-warning-light {
  background-color: rgba(255, 159, 28, 0.2);
}

.bg-danger-light {
  background-color: rgba(255, 92, 117, 0.2);
}

.bg-light-light {
  background-color: rgba(241, 246, 253, 0.2);
}

.bg-dark-light {
  background-color: rgba(23, 19, 71, 0.2);
}

.bg-primary-gradient {
  background: linear-gradient(180deg, #06257550, #002699 100%) repeat-x;
}

.bg-secondary-gradient {
  background: linear-gradient(180deg, #15db95 50%, #11ac75 100%) repeat-x;
}

.bg-success-gradient {
  background: linear-gradient(180deg, #2dca8c 50%, #24a06f 100%) repeat-x;
}

.bg-info-gradient {
  background: linear-gradient(180deg, #50b5ff 50%, #1d9fff 100%) repeat-x;
}

.bg-warning-gradient {
  background: linear-gradient(180deg, #ff9f1c 50%, #e88600 100%) repeat-x;
}

.bg-danger-gradient {
  background: linear-gradient(180deg, #ff5c75 50%, #ff294a 100%) repeat-x;
}

.bg-light-gradient {
  background: linear-gradient(180deg, #f1f6fd 50%, #c4d9f7 100%) repeat-x;
}

.bg-dark-gradient {
  background: linear-gradient(180deg, #112233 50%, #0a081f 100%) repeat-x;
}

/* .bg-primary-after:after {
  background-color: #01a310; } */

.bg-secondary-after:after {
  background-color: #15db95;
}

.bg-success-after:after {
  background-color: #2dca8c;
}

.bg-info-after:after {
  background-color: #50b5ff;
}

.bg-warning-after:after {
  background-color: #ff9f1c;
}

.bg-danger-after:after {
  background-color: #ff5c75;
}

.bg-light-after:after {
  background-color: #f1f6fd;
}

.bg-dark-after:after {
  background-color: #112233;
}

.section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 768px) {
  .section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media (min-width: 992px) {
  .section {
    padding-top: 3.25rem;
    padding-bottom: 6.25rem;
  }
}

.section-heading {
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .section-heading {
    margin-bottom: 2rem;
  }
}
@media (min-width: 992px) {
  .section-heading {
    margin-bottom: 3rem;
  }
}

.min-vh-50 {
  min-height: 50vh;
}

.min-h-150px {
  min-height: 150px;
}

.min-h-350px {
  min-height: 350px;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25%;
  }
  .w-sm-50 {
    width: 50%;
  }
  .w-sm-75 {
    width: 75%;
  }
  .w-sm-100 {
    width: 100%;
  }
  .w-sm-auto {
    width: auto;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25%;
  }
  .w-md-50 {
    width: 50%;
  }
  .w-md-75 {
    width: 75%;
  }
  .w-md-100 {
    width: 100%;
  }
  .w-md-auto {
    width: auto;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25%;
  }
  .w-lg-50 {
    width: 50%;
  }
  .w-lg-75 {
    width: 75%;
  }
  .w-lg-100 {
    width: 100%;
  }
  .w-lg-auto {
    width: auto;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25%;
  }
  .w-xl-50 {
    width: 50%;
  }
  .w-xl-75 {
    width: 75%;
  }
  .w-xl-100 {
    width: 100%;
  }
  .w-xl-auto {
    width: auto;
  }
}

.rd-mailform .has-error .invalid-feedback {
  display: block;
}

.rd-mailform .snackbars {
  border: 1px solid #eff2f7;
  background: #eff2f7;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  display: none;
}
.rd-mailform .snackbars.active {
  display: block;
}
.rd-mailform .snackbars.error {
  border: 1px solid #ff768b;
  background: #ff667e;
  color: #ff5c75;
}
.rd-mailform .snackbars.success {
  border: 1px solid #3dd498;
  background: #30d191;
  color: #2dca8c;
}
.rd-mailform .snackbars p {
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
}
.rd-mailform .snackbars p .s-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
}

.btn .btn--text:not(:last-child) {
  margin-right: 0.75em;
}

.btn .btn--text:not(:first-child) {
  margin-left: 0.75em;
}

.link-effect {
  position: relative;
  padding: 0;
  border: none;
  background: none;
}
.link-effect:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: auto;
  width: 100%;
  height: 1px;
  background: currentColor;
  transition: ease all 0.35s;
}
.link-effect:hover:after {
  width: 0;
  right: 0;
  left: auto;
}

.btn-primary-g {
  background: linear-gradient(to right, #01a310, #002db3 100%);
}

.btn-secondary-g {
  background: linear-gradient(to right, #15db95, #13c485 100%);
}

.btn-success-g {
  background: linear-gradient(to right, #2dca8c, #28b57e 100%);
}

.btn-info-g {
  background: linear-gradient(to right, #50b5ff, #37aaff 100%);
}

.btn-warning-g {
  background: linear-gradient(to right, #ff9f1c, #ff9403 100%);
}

.btn-danger-g {
  background: linear-gradient(to right, #ff5c75, #ff435f 100%);
}

.btn-light-g {
  background: linear-gradient(to right, #f1f6fd, #dbe8fa 100%);
}

.btn-dark-g {
  background: linear-gradient(to right, #112233, #100e33 100%);
}

.skill-lt:not(:last-child) {
  margin-bottom: 30px;
}

.skill-lt h6,
.skill-lt .h6 {
  font-size: 16px;
  margin: 0 0 10px;
}

.skill-lt .skill-bar {
  position: relative;
  background: #eee;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.skill-lt .skill-bar .skill-bar-in {
  width: 0px;
  transition: cubic-bezier(0.4, 0, 1, 1) all 0.95s;
  height: 5px;
  position: relative;
}
.skill-lt .skill-bar .skill-bar-in span {
  position: absolute;
  right: 0;
  top: -30px;
  font-size: 11px;
  background: #112233;
  border-radius: 3px 3px 0 3px;
  padding: 2px 7px;
  font-weight: 600;
  color: #ffffff;
}
.skill-lt .skill-bar .skill-bar-in span:after {
  content: '';
  width: 0;
  height: 0;
  border-top: 6px solid #112233;
  border-left: 8px solid transparent;
  position: absolute;
  right: 6px;
  bottom: -6px;
}

.skill-lt.md .skill-bar .skill-bar-in {
  height: 8px;
}

.skill-lt.lg .skill-bar .skill-bar-in {
  height: 10px;
}

.skill-lt.light .skill-bar {
  background: rgba(0, 0, 0, 0.5);
}

.section-heading {
  margin-bottom: 2.8125rem;
}

/* Mask
------------------------*/
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mask-90 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 10%;
}

.mask-80 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 20%;
}

.mask-75 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 25%;
}

.mask-65 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 35%;
}

.mask-50 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
}

.mask-40 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 40%;
}

@media (min-width: 992px) {
  .mask-50vw {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: calc(50vw + 15px);
  }
  .no-gutters .mask-50vw {
    width: 50vw;
  }
  .mask-50vw.left {
    right: -15px;
    left: auto;
  }
  .mask-50vw.right {
    left: -15px;
  }
}

@media (max-width: 991.98px) {
  .mask-50vw {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -15px;
    right: -15px;
    z-index: -1;
  }
}

/* My css */
.pages h3 {
  font-family: 'MYRIADPRO';
}
.pages p {
  font-family: 'MYRIADPRO';
}
.pricing h5 {
  font-family: 'MYRIADPRO';
  font-size: 30px;
}
.pricing .list-type-02 li span {
  font-family: 'MYRIADPRO';
  color: #000;
  font-weight: 500;
}
.bg-primary1 {
  background-color: #062575;
}
.b-0 {
  border: 0;
}
/* .pos-fix{position: relative;bottom: 0;} */
.pos-fix-img {
  position: absolute;
  bottom: 0;
  left: 50%;
}

.pos-fix {
  right: 0;
  position: absolute;
  bottom: 0;
}

.lan-btn {
  margin: 0;
  position: relative;
  top: 40px;
  color: #fff;
}

.dark-blue {
  color: #13375a;
}

/* ------ */

#que_id {
  color: red;
  font-weight: bold;
}

.bg-primary-gradient2 {
  background: linear-gradient(180deg, #062575 50%, #002699 100%) repeat-x;
}

.bg-gray-101 {
  background-color: #062575;
}

.text-c h4 {
  color: #fff;
}

.breadcrumb {
  display: inline-flex;
}

.btn-easy {
  margin: 0 10px;
  background: #00a310 !important;
  border-color: #00a310 !important;
  box-shadow: 0px 4px 4px #00000040 !important;
  border-radius: 0.25rem !important;
}

.w-20 {
  width: 20%;
}
.btn-primary {
  color: #ffffff;
  background-color: #062575;
  border-color: #062575;
  font-size: 18px;
  border-radius: 5px;
}

.btn-success {
  color: #ffffff;
  background-color: #062575;
  border-color: #062575;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #00a310;
  border-color: #00a310;
}

.input-group-text {
  position: absolute;
  right: -5px;
  top: -5px;
  padding: 0.7rem 0.3em;
  border-radius: 0;
  background: #fb0001;
  color: #fff;
  border-left-color: #fff !important;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #fb0001;
}

.view-dline {
  background: #ff0000;
  text-align: center;
  padding: 5px;
  color: #fff;
  border-radius: 5px !important;
  width: 63%;
}

.text-font {
  font-size: 18px;
}

.footer-auth {
  bottom: 0;
  padding: 30px calc(1.25rem / 2);
  position: absolute;
  right: 0;
  background: #fff;
  color: #062575;
  left: 0px;
  height: 80px;
  box-shadow: 0 0px 4px rgba(15, 34, 58, 0.12);
}
.text-green {
  color: #01ba13;
}
.activate p {
  padding: 20px 0;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  font-size: 22px;
  margin-top: 20px;
}

.select2-search-disable__value-container,
.select2-search-disable__value-container--is-multi,
.select2-selection__value-container {
  padding: 7px 20px !important;
  background-color: #fff !important;
  border: 1px solid #c3b7db;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 20px;
}
.select2-selection__indicators {
  border: 1px solid #c3b7db;
  border-left: 0;
}
.css-tj5bde-Svg {
  color: #112233;
}
.fs-20 {
  font-size: 20px;
}
.footer-page {
  font-size: 22px;
  background: rgba(6, 37, 117, 0.1);
  box-shadow: none;
  border-radius: 5px;
  padding: 40px;
  color: #062575;
}

.agree-check label {
  padding-left: 15px !important;
  font-size: 20px;
  color: #062575;
}
.field-icon {
  position: absolute;
  right: 25px;
  top: 50%;
  font-size: 20px;
}

.form-check-input {
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #00a310;
}
.regi-box {
  background: #efeaf9;
  height: 650px;
  overflow: scroll;
  border: 1px solid #ccc;
  box-shadow: 5px 16px 28px rgba(0, 0, 0, 0.2);
}
.regi-box p {
  font-size: 20px;
  color: #112233;
}

.regi-box p strong {
  font-size: 20px;
  color: #112233;
}

.regi-box h3 {
  font-size: 28px;
  margin: 35px 0;
  text-align: center;
  color: #112233;
}

.w-lg {
  padding: 0.7rem 5rem;
  font-size: 1.125rem;
  line-height: 1.625;
  border-radius: 5px;
}

.page-item.active .page-link {
  font-size: 20px !important;
  color: #ffffff;
  background-color: #062575;
  border-color: #062575;
}

.page-link {
  color: #062575;
  font-size: 20px !important;
  padding: 0.375rem 1rem;
  background-color: #ffffff;
  border: 1px solid #e0e0e1;
}

//Table Css
.min-h-80 {
  min-height: 80vh;
}
.min-h-85 {
  min-height: 85vh;
}
.p-r-3 {
  padding: 0 6px !important;
}
.mtb-4 {
  margin: 50px 0;
}
.table > :not(:last-child) > :last-child > * {
  font-size: 20px;
}
.cust-tab tbody tr td {
  color: #112233 !important;
}
.table-color {
  background-color: #062575;
  color: #fff;
}
.table > :not(caption) > * > * {
  padding: 1rem;
  font-size: 20px;
  vertical-align: middle !important;
}

.create-btn {
  background: #062575;
  font-size: 20px;
  letter-spacing: 1px;
  width: 100%;
}
.create-btn:hover {
  color: #fff !important;
  background-color: #00a310;
  border-color: #00a310;
  box-shadow: 0 0 10px -5px rgba(45, 206, 137, 0.5);
}

.form-check-label {
  font-size: 20px;
  color: #112233;
  padding: 3px 10px;
}
.text-balck {
  font-size: 20px;
}
.date-head {
  background: #000957 !important;
  border: #000957 !important;
  color: #fff !important;
}

//filter modal css
.filter-box {
  cursor: pointer;
}
.filter-modal {
  position: absolute;
  right: 10%;
  width: auto;
}

.filter-modal {
  max-width: 900px !important;
}
.filter-modal .modal-header {
  background-color: #000957;
}
.filter-modal .modal-body {
  background-color: #dfdfdf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.filter-modal .modal-title {
  color: #fff;
}

.flatpickr-months .flatpickr-month,
.flatpickr-weekdays {
  background: #00a310 !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: #00a310 !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #00a310;
  color: #fff;
  border-color: #00a310;
}
span.flatpickr-weekday {
  background: #00a310 !important;
  color: #fff !important;
}

.filter-modal .form-check-label {
  padding: 0 5px;
  font-size: 17px;
}
.filter-modal .form-check-input:focus {
  box-shadow: none;
}

.entry-box {
  background: #efeaf9;
  padding: 12px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #c3b7db;
  color: #112233;
  font-size: 20px;
}

.repeater .select2-selection__value-container {
  color: #112233;
  background-color: #ffffff !important;
  padding: 8px !important;
  line-height: 1;
  font-size: 20px;
}

.repeater .select2-selection__menu {
  background-color: #efeaf9 !important;
  margin: 0;
}

.disc-val {
  padding: 8px 18px;
  background: #f5be4e;
  border-radius: 5px;
}

.disc-pad {
  padding: 1rem 0 !important;
}
.disc-box {
  background: #f5be4e;
  padding: 20px;
  font-size: 20px;
  color: #112233;
  border-radius: 5px;
}
.total-box {
  background: #efeaf9;
}
.total-box td {
  font-weight: 600;
}
.w-15 {
  width: 15%;
}

.close-btn {
  background-color: #fb0001;
  border: none;
  color: white;
  padding: 2px;
  width: 32px;
  border: 1px solid #fb0001;
  cursor: pointer;
}

.btn-back {
  background: #112233 !important;
  margin: 0 10px;
  box-shadow: 0px 4px 4px #00000040 !important;
}

.filter-icon {
  float: right;
  font-size: 20px;
  padding: 5px 0;
}
.flt-btn {
  border-radius: 5px !important;
  font-size: 21px !important;
}
.flt-btn:active:focus {
  box-shadow: none !important;
}
.flt-modal i {
  right: 8%;
  position: absolute;
  color: #fff;
  font-size: 22px;
}
.flt-modal .close {
  border-radius: 7px;
}

.btn-red {
  background: #fb0001 !important;
  border-color: #fb0001 !important;
}
.count-num {
  width: 40%;
  margin: 0 auto;
}

.count-num button {
  border-radius: inherit;
  background: #e6e9f1;
  color: #112233;
  font-weight: 600;
}

.count-num button:hover {
  background: #e6e9f1;
  color: #112233;
}

.disc-collapse {
  border-bottom: 0 !important;
  border-color: #fff !important;
}
.disc-collapse td {
  padding: 0;
}

.disc-val-col {
  padding: 10px 5px 8px 14px;
  background: #f5be4e;
  border-radius: 5px;
}
.w-60 {
  width: 60%;
}
#about .shop-card {
  background: #e7f8e9;
  padding: 20px;
}
#about .shop-card h4 {
  font-size: 24px;
}
#about .shop-card p {
  font-size: 20px;
}
.sucess_modal .modal-content {
  background-image: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(0, 163, 16, 1)
  );
}

//Sucess Modal
.sucess_modal .modal-content {
  background-image: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(0, 163, 16, 1)
  );
}
.sucess_modal .modal-content p {
  font-size: 20px;
  color: #112233;
}
.sucess_modal .modal-header {
  border-bottom: none;
}

//Signup Modal
.signup_modal .modal-content {
  background: #00a310 !important;
}
.signup_modal .modal-content h4 {
  color: #fff;
}
.signup_modal .modal-content p {
  font-size: 20px;
  color: #fff;
}
.signup_modal .modal-header {
  border-bottom: none;
}

//Agree Modal
.agree_modal .modal-content {
  background: #062575 !important;
}
.agree_modal .modal-content p {
  font-size: 20px;
  color: #fff;
}
.agree_modal .modal-header {
  border-bottom: none;
}

// Manual Entry
.manual-fill-box {
  background: #062575;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #062575;
  text-transform: capitalize;
}

.manual-empty-box {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  color: #062575;
  border: 1px solid #062575;
  text-align: center;
}
.manual-empty-box span {
  color: #00a310;
}

.manual-row .manual-entry .up-box {
  padding: 10px 20px;
  background: #efeaf9;
  border-radius: 5px;
  border: 1px solid #c3b7db;
  color: #112233;
  text-align: center;
}

// .manual-entry .select2-selection__value-container {
//   padding: 7px 20px !important;
//   background: #fff !important;
//   border:none;
// }

.w-90 {
  width: 90%;
}
.manual-entry .select2-selection__value-container {
  padding: 7px 5px !important;
  background: #fff !important;
  border: none;
  line-height: 1;
}
.manual-entry .select2-selection__indicators {
  border: none;
}

.manual-entry .form-select {
  padding: 0.65rem 1.5rem 0.65rem 1rem !important;
  background-position: right 0.7rem center !important;
}

.manual-entry .form-control {
  border-color: #ccc;
}

.manual-sr {
  display: table;
  background: #efeaf9;
  border-radius: 10px;
}

.manual-sr p {
  display: table-cell;
  vertical-align: middle;
  text-align: center !important;
}
.easy-middle-btn {
  display: table !important;
}
.easy-middle-btn .select-box {
  display: table-cell;
  vertical-align: middle;
  text-align: center !important;
}

.easy-box p {
  text-align: center !important;
  line-height: 25px !important;
  margin: 0;
  padding: 10px 0;
  font-size: 20px;
}

.manual-entry .form-control {
  border-color: #ccc;
}
.pr-2 {
  padding-right: 10px;
}
.manual-entry .form-control:disabled,
.form-control[readonly] {
  background-color: #f1f1f1 !important;
  opacity: 1;
}

.form-control {
  font-size: 20px;
  line-height: 1.6;
  border: 1px solid #c3b7db;
  -webkit-appearance: none;
  background-color: #fff !important;
  color: #112233 !important;
}
.event-nav .nav-tabs .nav-link {
  cursor: pointer;
}
.box-disc ul {
  list-style-type: none;
}
.event-nav .nav-tabs .nav-link.active,
.event-nav .nav-tabs .nav-item.show .nav-link {
  color: #062575 !important;
  background-color: #f7f7f7;
  border-color: #082c5c #014299 #ffffff !important;
  border: 1.5px solid transparent;
  font-size: 20px !important;
  cursor: pointer;
  border-radius: 5px;
}
.mr-5 {
  margin-left: 5px;
}
.event-nav .tab-content {
  border: 1.5px solid #062575 !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.event-nav .nav-tabs .nav-link {
  color: #112233 !important;
  font-size: 20px;
}
.flatpickr-day.today {
  border-color: #bbb;
  background: #1fa310;
  color: #fff;
}
.tab-running {
  background: #efeaf9;
  padding: 20px 5px;
  text-align: center;
  font-size: 20px !important;
  line-height: 18px;
  color: #112233;
  font-weight: 500;
  border-radius: 5px;
}

.tab-run-show {
  background: #e7f8e9;
  padding: 20px 5px;
  text-align: center;
  font-size: 20px !important;
  line-height: 18px;
  color: #112233;
  font-weight: 500;
}

.page-content-auth {
  padding: calc(110px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2)
    $footer-height calc(#{$grid-gutter-width} / 2) !important;
}
.auto-fit {
  margin: 0 auto !important;
  max-width: 98% !important;
}
.bg-sr {
  background: #efeaf9 !important;
  text-align: center;
}
.w5 {
  width: 5% !important;
}
// Sub dropdown css
.header-main
  .navbar-expand-lg
  .navbar-nav
  > .nav-item.dropdown
  > .nav-link:after {
  color: #062575;
}
.header-main .navbar-nav .dropdown-menu {
  padding: 10px 15px;
  border-top: 2px solid #fce994;
  border: 1px solid #ccc;
}
.header-main .navbar-nav .dropdown-menu li {
  padding: 3px 0;
}
.header-main .navbar-nav .dropdown-menu li a:hover {
  color: #bb883f;
}

// Login tab view css
.user-tab .tab-pane {
  padding: 0;
}
.user-tab .card {
  margin-bottom: 0;
}
.bg-blue {
  background: #062575;
  border-radius: 10px;
}
.bg-white {
  background: #fff;
  border-radius: 0px 0px 10px 10px;
}
.user-tab .nav-link {
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  font-size: 21px;
  color: #fff;
}
.user-tab .nav-item {
  width: 50%;
  cursor: pointer;
  line-height: 40px;
}
.user-tab .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
}

// Forgot Password Css
.forgot-pwd .card-body {
  background: #062575;
  border-radius: 10px;
}
.forgot-pwd h3 {
  color: #fff;
}
.forgot-pwd .form-label {
  color: #fff;
}
.forgot-pwd .text-font a {
  color: #fff;
}
.forgot-pwd .btn-primary {
  background: #fff;
  color: #062575;
}

.not-allow {
  cursor: not-allowed;
}
.search-btn {
  position: absolute;
  right: 31px;
  top: 0;
  width: 3.3rem !important;
  height: 3.4rem !important;
  background: #062575;
  color: #fff6f6;
  padding: 10px;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}
.cust-tab tbody tr .inactive-user {
  color: #858585 !important;
}

.easy-select .form-select {
  padding: 0.65rem 1.5rem 0.65rem 0.5rem;
  background-position: right 0.25rem center;
}
.flatpickr-input {
  border-radius: 5px !important;
}

//easy Entry
.w4 {
  width: 4% !important;
}
.w5 {
  width: 5% !important;
}
.w6 {
  width: 6% !important;
}
.w7 {
  width: 7% !important;
}
.w8 {
  width: 8% !important;
}
.w9 {
  width: 9% !important;
}
.w10 {
  width: 10% !important;
}
.w12 {
  width: 12% !important;
}
.w13 {
  width: 13% !important;
}
.w14 {
  width: 14% !important;
}
.w15 {
  width: 15% !important;
}

@media (max-width: 1499px) {
  .w12 {
    width: 12% !important;
  }
  .w4 {
    width: 5% !important;
  }
  .w8 {
    width: 9% !important;
  }
  .w10 {
    width: 11% !important;
  }
  .w14 {
    width: 12% !important;
  }
}

@media (max-width: 1599px) {
  .view-dline .input-group-text {
    right: -5px;
  }
  .view-dline .deadline {
    font-size: 18px;
  }
}

@media (max-width: 1399px) {
  .view-dline .input-group-text {
    right: -10px;
  }
  .view-dline .deadline {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .w4 {
    width: 50% !important;
  }
  .w5 {
    width: 50% !important;
  }
  .w6 {
    width: 50% !important;
  }
  .w7 {
    width: 50% !important;
  }
  .w8 {
    width: 50% !important;
  }
  .w9 {
    width: 50% !important;
  }
  .w10 {
    width: 50% !important;
  }
  .w12 {
    width: 50% !important;
  }
  .w13 {
    width: 50% !important;
  }
  .w14 {
    width: 50% !important;
  }
  .w15 {
    width: 50% !important;
  }
}
@media (max-width: 767px) {
  .easy-select .w10 {
    width: 100%;
  }
}

.w4 .bg-sr {
  padding: 10px 5px !important;
}
.styled-select select {
  font-size: 18px !important;
}
.styled-select input {
  font-size: 18px !important;
}
.styled-select .bg-sr {
  font-size: 18px !important;
  background: #062575 !important;
  color: #fff !important;
}

.easy-select input {
  padding: 0.65rem 0.5rem 0.65rem 0.5rem;
}
.easy-select .entry-box {
  font-size: 18px;
  padding: 12px 2px;
  background: #062575;
  color: #fff;
}

.select-check .form-check-input {
  width: 2.4em;
  height: 2.4em;
  border: 2px solid #00a310;
}

.select-check .form-check-input:focus {
  border-color: #00a310;
  outline: 0;
  box-shadow: none;
}
.select-check .form-check-label {
  display: block;
}
// Easy Entry Participate

.cust-select {
  font-size: 18px;
  color: #112233;
}
.cust-select .css-107lb6w-singleValue {
  font-size: 18px;
  color: #112233;
}
.cust-select .css-1fhf3k1-control {
  padding: 6px 5px;
  border-color: #ccc;
}

.cust-select .css-1rhbuit-multiValue {
  font-size: 20px;
}

// .top-title span {
//   background: linear-gradient(90deg, rgba(196, 196, 196, 0) -75.31%, #062575 53.94%, rgba(196, 196, 196, 0) 143.25%);
//   padding: 15px 40px 10px;
//   color: #fff;
//   font-weight: 500;
//   font-size: 26px;
//   text-transform: uppercase;
//   font-family: 'Montserrat';
// }

.top-title {
  text-align: left;
}
.top-title span {
  text-transform: uppercase;
}
.page-title-box {
  text-align: left !important;
}
.manual-box .form-select {
  padding: 0.65rem 0.75rem 0.65rem 1rem;
  text-transform: capitalize !important;
}
@media (max-width: 1399px) {
  .easy-select .entry-box {
    font-size: 16px;
  }
  .easy-select .form-select {
    font-size: 16px;
  }
  .easy-select .bg-sr {
    font-size: 16px;
  }
}

@media (max-width: 2499px) {
  .search-btn {
    right: 60px;
  }
}
@media (max-width: 1920px) {
  .search-btn {
    right: 31px;
  }
}
.css-1pahdxg-control {
  min-height: 55px !important;
  font-size: 20px;
  border-width: 1px !important;
  box-shadow: none !important;
  border-color: #ccc;
}
.css-1pahdxg-control:hover {
  box-shadow: none !important;
  border-color: #ccc !important;
}
.css-g1d714-ValueContainer {
  -webkit-box-flex-wrap: nowrap !important;
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.css-yk16xz-control {
  box-shadow: none !important;
  border-color: #ccc !important;
  padding: 0rem 0.4rem 0rem 0.25rem;
  height: 55px;
  font-size: 20px;
}

.css-yk16xz-control:hover {
  box-shadow: none !important;
  border-color: #ccc !important;
}

.css-yk16xz-control {
  -webkit-box-flex-wrap: nowrap !important;
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
  color: #112233;
}

.css-12jo7m5 span {
  font-size: 18px !important;
}
.css-12jo7m5 {
  padding: 0px;
}

// .css-26l3qy-menu .css-4ljt47-MenuList div:hover{background-color: #00A310  !important;color: #fff !important;font-size: 18px !important;}

.css-26l3qy-menu .css-1n7v3ny-option {
  background-color: #eee !important;
  color: #112233;
  font-size: 18px !important;
}
.css-26l3qy-menu .css-9gakcf-option {
  background-color: #eee !important;
  color: #112233;
  font-size: 18px !important;
}
.css-26l3qy-menu .css-9gakcf-option label {
  color: #112233;
  font-size: 20px !important;
}

// .css-26l3qy-menu label:hover{background-color: #00A310  !important;color: #fff !important;font-size: 18px !important;}
//common select

.select2-selection__value-container--has-value {
  flex-wrap: nowrap !important;
}
.css-yk16xz-control div:first-child {
  -webkit-box-flex-wrap: nowrap !important;
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
  color: #112233;
  text-transform: capitalize !important;
}
.css-yk16xz-control div:nth-child(2) {
  -webkit-box-flex-wrap: nowrap !important;
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
  color: #112233;
  text-transform: capitalize !important;
}
.css-26l3qy-menu {
  text-transform: capitalize !important;
}
//  .css-2613qy-menu{
//    font-size: 45px;
//  }

.error {
  color: rgb(255, 22, 22) !important;
  font-size: larger;
}

.cart-qty {
  position: absolute;
  top: 14px;
  font-size: 14px;
  background: #ff0000;
  padding: 3px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  right: 30px;
  color: #fff;
  text-align: center;
}

.cart-qty-sponsor {
  position: absolute;
  top: 0px;
  font-size: 14px;
  background: #ff0000;
  padding: 3px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  right: 30px;
  color: #fff;
  text-align: center;
  right: 36%;
}
.cart-font {
  font-size: 30px;
}
.mt-12 {
  margin-top: 5rem !important;
}
body[data-layout='horizontal'] .page-content {
  margin-top: 30px;
}
.text-right {
  text-align: right;
}
.w-10 {
  width: 10%;
}
.title-center {
  display: table-cell;
  vertical-align: middle;
  text-align: center !important;
}

.pe-auto:hover {
  color: #01a310;
}

.tab-structure .nav-item .active {
  color: #000000 !important;
  cursor: pointer !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}
.tab-structure .nav-item .nav-link {
  color: #000000;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
}
.tab-structure .nav-tabs .nav-link.active,
.tab-structure .nav-tabs .nav-item.show .nav-link {
  color: #4a5568;
  background-color: #ffffff;
  border-color: #062575 #062575 #ffffff;
}
.tab-structure .nav-tabs {
  border-bottom: 1px solid #062575;
}
.tab-structure .tab-content {
  border: 1px solid #062575;
  border-top: 0;
}
.participate-str .table-color {
  background-color: #000 !important;
}
.check-btn a {
  background: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.25));
}
// .check-btn button, .check-btn button:hover {
//   background: #fff;
//   // color: black;
//   padding: 15px 20px;
//   border-radius: 5px;
//   filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.25));
// }

.log-btn a {
  background: #f5be4e;
  padding: 15px 20px;
  border-radius: 5px;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.25));
}

.middle-box {
  padding: 1rem;
  font-size: 20px;
  background: #000;
}

.middle-box .user-name {
  color: #fff;
  padding: 0 20px;
}
// body.modal-open{
// overflow: hidden !important;
// }
body.modal-open .flatpickr-calendar {
  position: fixed !important;
}

.pay-modal h4 {
  color: #00a310;
}
.w-80 {
  width: 80%;
}
.box-save {
  background: #afc8e7;
  padding: 20px 0px 0 20px;
  border-radius: 10px;
}

.box-save-new {
  background: #afc8e7 !important;
  padding: 20px !important;
  border-radius: 10px;
}

.box-save-new .form-control:disabled,
.box-save-new .form-control[readonly] {
  background-color: #e5e5e5 !important;
  cursor: not-allowed;
}

.css-1fhf3k1-control {
  height: 54px;
  border-color: #ccc !important;
  cursor: not-allowed;
  pointer-events: auto !important;
}

.parti-dis
  .select2-selection__control--is-disabled
  .select2-selection__multi-value__remove {
  pointer-events: none !important;
}

input:disabled {
  cursor: not-allowed !important;
}

.prev-pad .manual-entry {
  padding: 0 7px;
}
.p-r-2 {
  padding: 0 2px !important;
}

// 19-07-2022 - Satish

.cat-info .cat-info-head {
  text-align: center;
  background: #062575;
  color: #fff !important;
  padding: 10px;
  border-radius: 5px;
  margin: 20px 0;
  font-size: 20px;
}
.table-color-black {
  background-color: #000000;
  color: #fff;
}

.cat-info .cat-info-head-red {
  text-align: center;
  background: #ff0000;
  color: #fff !important;
  padding: 10px;
  border-radius: 5px;
  margin: 20px 0;
  font-size: 20px;
}

// 29 JUL 22 Purchase Plans

.top-title-plan span {
  padding: 25px;
  border: 1px solid #062067;
  font-size: 28px;
  font-weight: 500;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
}
.plan-structure {
  padding: 25px;
  border: 1px solid #062067;
  border-radius: 15px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
}
.plan-tab .cust-tab tbody tr td {
  padding: 0.5rem !important;
}

.final-pay {
  background: #112233;
  padding: 20px;
}
.final-pay h4 {
  color: #fff;
  margin-bottom: 0;
}
.final-pay span {
  background: #fff;
  padding: 15px 40px;
  font-size: 20px;
  border-radius: 10px;
  font-weight: bold;
}

.break {
  word-break: break-all !important;
  width: 30% !important;
}

// 10 AUG 2022
.is-invalid {
  background-image: none !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}

#about .part-cont .text-red {
  color: #ff0000 !important;
}
#about .part-cont .btn-success {
  background: #00a310 !important;
}
#about .grand-box {
  background: #ede7f7;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.35);
}
#about .grand-box-view {
  background: #062575;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.35);
}
#about .grand-box-view h4 {
  color: #fff;
}

.exist-modal-view .modal-content {
  text-align: center;
  background: rgb(3, 33, 114);
  color: rgb(255, 255, 255);
  padding: 10px;
}
.exist-modal-view .para {
  font-size: 22px !important;
}

.dataTables_paginate .disabled {
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.btn-danger:disabled,
.btn-danger.disabled {
  color: #ffffff;
  background-color: #ff0b31;
  border-color: #ff0b31;
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.list-type-none {
  list-style-type: none;
}

.no-show {
  font-size: 22px;
  color: #062575;
}

.view-dis .select2-selection--is-disabled .css-g1d714-ValueContainer {
  background-color: #f1f1f1 !important;
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.view-dis .css-107lb6w-singleValue {
  color: #112233 !important;
}
.box-save-new .select2-selection--is-disabled .css-g1d714-ValueContainer {
  background-color: #e5e5e5 !important;
}
.box-save-new .select2-selection--is-disabled .select2-selection__indicators {
  background-color: #e5e5e5 !important;
}

.disable-click {
  pointer-events: none !important;
  cursor: not-allowed !important;
}
.view-dis .select2-selection--is-disabled .css-1hwfws3 {
  background-color: #f1f1f1 !important;
  cursor: not-allowed !important;
  pointer-events: unset !important;
}
.text-view {
  color: #112233 !important;
  border-bottom: none !important;
}
.bg-green {
  background: #00a310 !important;
  border-color: #00a310 !important;
}
.dis-btn .btn:disabled,
.dis-btn .btn.disabled {
  cursor: not-allowed !important;
  pointer-events: unset !important;
}
.dis-btn-view {
  cursor: not-allowed !important;
  pointer-events: unset !important;
}
.boder-none .select2-selection__value-container--is-multi {
  border-color: #fff !important;
}
.boder-none .select2-selection__control {
  padding: 0 !important;
}

.multi-select .select2-selection__value-container--is-multi {
  // border-color: #fff !important;
  border: none !important;
}
.multi-select .select2-selection__control {
  padding: 0 !important;
  border-radius: 5px !important;
  // border-color: #c5c5c5 !important;
}
.multi-select .css-1hwfws3 {
  -webkit-box-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.cust-tab tbody tr .text-red-view {
  color: red !important;
}

.clerk-log {
  color: #ffffff;
  font-weight: normal !important;
  font-size: 24px !important;
}

.clerk-log-sub {
  background: #00a310 !important;
  border: 1px solid #00a310 !important;
}
.fullModal {
  max-width: 100% !important;
}

.form-check-input-v2:checked {
  background-color: #01a310 !important;
  border-color: #01a310;
}
.form-check-input-v2:checked[type='checkbox'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
}
.tab-heading-show-list {
  background-color: #e7f8e9 !important;
}

.tab-content-show-list {
  background: #e7f8e9;
}
.acc-tab .openArrow::after {
  background-color: white;
  border-radius: 50%;
  transform: rotate(180deg) !important;
}
.acc-tab .closeArrow::after {
  background-color: white;
  border-radius: 50%;
  transform: rotate(360deg) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-cap {
  text-transform: capitalize !important;
}

.text-warn {
  color: #ce9603;
}
#page-topbar {
  background-color: #1fa310;
}

.res-text {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.bg-golden {
  background-color: #ce9603;
}
.button__badge {
  background-color: #ff0000;
  color: white;
  position: absolute;
  top: -4px;
  width: 15px;
  border-radius: 50%;
  height: 15px;
  right: -4px;
}

//Pricing CSS
.pricingTable {
  margin-bottom: 40px;
}

.pre-exh .outer .pricingTable-header {
  background: #47186f;
  border-radius: 0;
  border-bottom: 8px solid #c2812c;
}

.herd-mgt .outer .pricingTable-header {
  background: #af0000;
  border-radius: 0;
  border-bottom: 8px solid #c2812c;
}

.pre_herd .outer .pricingTable-header {
  background: #0f43b1;
  padding: 40px 0;
}

.pre_herd .outer .pricingTable-header small {
  font-size: 18px;
  line-height: 2.5;
}
.pre-exh .outer .pricingTable-header:hover {
  background: #47186f;
  border-radius: 0;
  border-bottom: 8px solid #c2812c;
}

.herd-mgt .outer .pricingTable-header:hover {
  background: #af0000;
  border-radius: 0;
  border-bottom: 8px solid #c2812c;
}

.pre_herd .outer .pricingTable-header:hover {
  background: #0f43b1;
  border-radius: 0;
}

// .pre-exh{
//   padding: 20px;
//   background: red !important;
//   background: linear-gradient(217deg, rgb(234 202 102), rgb(200 155 49) 70.71%), linear-gradient(127deg, rgb(187 135 63), rgba(0, 255, 0, 0) 70.71%), linear-gradient(336deg, rgb(226 190 88), rgba(0, 0, 255, 0) 70.71%) !important;
// }

.outer {
  background: #fff;
}
// .inner .box{background: #fff;}

.inner {
  /* margin: 10px; */
  /* background: red; */
  border: 1px solid transparent;
}

.inner .box {
  // border: 5px solid #f0b566;
  // padding: 20px 20px 0 20px;
  margin: 0px 10px 10px 10px;
  border-top: 0;
  background: #fff;
}

.cont-color {
  padding-top: 25px;
}
.cont-color ul {
  background-color: #47186f;
}

.pricingTable .cont-color ul li {
  color: #deb95a !important;
  font-size: 24px !important;
  padding-top: 10px;
  margin-bottom: 0 !important;
}
.pricingTable .cont-color ul li:last-child {
  padding-bottom: 10px !important;
}

.hert-mgt-list .text-red {
  color: red !important;
  font-size: 20px;
}

.hert-mgt-list ul li {
  line-height: 28px !important;
}
.hert-mgt-list ul {
  padding-top: 25px !important;
}
// .pricingTable {
//   text-align: center;
//   background: #fff;
//   box-shadow: 0 0 10px #ababab;
//   border-radius: 0px;
//   color: #cad0de;
//   transform: scale(1);
//   transition: all 0.5s ease 0s;
//   padding: 20px;
//   background: linear-gradient(0.5turn, #c8892e, #d89f35, #f0e576)  !important;
//   background-image: linear-gradient(to bottom right, #f6f17f, #c1802d, #f6f17f, #c1802d );

// }

.pricingTable {
  text-align: center;
  background: #fff;
  box-shadow: 0 0 10px #ababab;
  border-radius: 10px;
  color: #cad0de;
  transform: scale(1);
  transition: all 0.5s ease 0s;
  border: 12px solid #d38d1d;
  width: 100%;
}

// .pricingTable:hover {
//   transform: scale(1.05);
//   z-index: 1;
// }
.pre_herd .pricingTable-header {
  border-radius: 0px 0px 50% 50% !important;
}
.pricingTable .pricingTable-header {
  padding: 20px 0;
  background: #f5f6f9;
  border-radius: 10px 10px 10px 10px;
  transition: all 0.5s ease 0s;
}

.pricingTable .pricingTable-header i {
  font-size: 50px;
  color: #858c9a;
  margin-bottom: 10px;
  transition: all 0.5s ease 0s;
}

.pricingTable .price-value {
  font-size: 35px;
  color: #ff9624;
  transition: all 0.5s ease 0s;
}

.pricingTable .month {
  display: block;
  font-size: 14px;
  color: #cad0de;
}

.pricingTable:hover .month,
.pricingTable:hover .price-value,
.pricingTable:hover .pricingTable-header i {
  color: #fff;
}

.pricingTable .heading {
  font-size: 32px;
  color: #fff;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.pricingTable .pricing-content ul {
  list-style: none;
  padding: 0 20px;
  margin-bottom: 30px;
}

.pricingTable .pricing-content ul li {
  line-height: 40px;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}

.pricingTable .pricingTable-notice p {
  font-size: 18px;
  color: #000;
  padding: 10px 15px;
  margin-bottom: 0;
}

.pricingTable.blue .heading,
.pricingTable.blue .price-value {
  color: #4b64ff;
}

.pricingTable.red .heading,
.pricingTable.red .price-value {
  color: #ff4b4b;
}

.pricingTable.green .heading,
.pricingTable.green .price-value {
  color: #40c952;
}

.pricingTable.blue:hover .price-value,
.pricingTable.green:hover .price-value,
.pricingTable.red:hover .price-value {
  color: #fff;
}

.btn-pre-exh {
  color: #ffffff;
  background-color: #47186f !important;
  border-color: #47186f !important;
  font-size: 18px;
  border-radius: 5px;
}

.btn-herd-mgt {
  color: #ffffff;
  background-color: #af0000 !important;
  border-color: #af0000 !important;
  font-size: 18px;
  border-radius: 5px;
}
.btn-pre-herd-mgt {
  color: #ffffff;
  background-color: #0f43b1 !important;
  border-color: #0f43b1 !important;
  font-size: 18px;
  border-radius: 5px;
}

.herd-mgt .herd-mgt-msg {
  color: red !important;
  font-weight: bold;
  line-height: 1.2 !important;
  font-size: 18px !important;
}
.pre_herd .pre-herd-msg {
  color: red !important;
  font-weight: bold;
  line-height: 1.2 !important;
  font-size: 18px !important;
}

.pricingTable-footer-price .dis-price {
  color: #000;
  font-size: 24px;
  line-height: 20px;
}
.pricingTable-footer-price small {
  font-size: 16px;
}
.pricingTable-footer-price .only-shows {
  text-align: right;
  padding: 0 20px;
  font-weight: bold;
}
// RIBBON
.hght-5 {
  height: 105px;
}
.ribbon {
  font-size: 24px;
  font-weight: normal;
  color: #fff;
  /* padding: 5px; */
  width: 90%;
  padding-top: 10px;
}
.ribbon small {
  font-size: 16px;
  line-height: 20px;
}
.ribbon {
  --f: 0.5em;
  --r: 0.8em;
  position: absolute;
  bottom: 225px;
  left: calc(2 * var(--f));
  // padding-inline: .25em;
  padding: 1em 0 20px;
  line-height: 20px;
  // background: #9a0101;
  background-image: linear-gradient(to right, #c30101, #c30101);
  border-top: var(--f) solid #0005;
  border-right: var(--r) solid #0000;
  clip-path: polygon(
    0 100%,
    0 var(--f),
    var(--f) 0,
    var(--f) var(--f),
    100% var(--f),
    calc(100% - var(--r)) calc(50% + var(--f) / 2),
    100% 100%
  );
}

// RIBBON exclusive
.ribbon-ex {
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
.ribbon-ex {
  --f: 0.5em; /* control the folded part */
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.8;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  border-image: conic-gradient(#0008 0 0) 51% / var(--f);
  clip-path: polygon(
    100% calc(100% - var(--f)),
    100% 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    var(--f) calc(100% - var(--f)),
    0 100%,
    0 calc(100% - var(--f)),
    999px calc(100% - var(--f) - 999px),
    calc(100% - 999px) calc(100% - var(--f) - 999px)
  );
  transform: translate(calc((cos(45deg) - 1) * 100%), -100%) rotate(-45deg);
  transform-origin: 100% 100%;
  background-color: #d38d1d; /* the main color  */
}

.w-break {
  word-break: break-all !important;
}
.deceased:before {
  content: 'X';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  font-size: 75px; // adjust this a little though it will be clipped by the div
  text-align: center;
  line-height: 100%;
  overflow: hidden;
  color: red;
  opacity: 0.5;
}

.res-until {
  font-size: 16px !important;
  font-weight: normal;
}

.price-f {
  font-size: 30px;
}
.price-hei {
  line-height: 1;
}

// .box-flex{display: flex; flex-direction: column;}
.box-flex {
  display: flex;
}
.pos-relative {
  position: relative;
  min-height: 60px;
  border-top: 0 !important;
}
.fixex-btn {
  bottom: 15px;
  position: fixed;
  left: 35%;
}

.pedigree-view .bg-blue:disabled,
.pedigree-view .bg-blue.disabled {
  color: #ffffff;
  background-color: #2a4796;
  border-color: #082575;
  cursor: not-allowed;
  pointer-events: all;
}

.pedigree-view .bg-green:disabled,
.pedigree-view .bg-green.disabled {
  cursor: not-allowed;
  pointer-events: all;
}
.pedigree-sidebar .mm-active .active {
  color: #fff !important;
}

.mm-active .active i {
  color: #fff !important;
}

.pedigree-sidebar .mm-active > a {
  background-color: #0129a3;
  color: #5b73e8 !important;
}

.link-color {
  color: #0f42b1;
  text-decoration: underline !important;
}

.text-red-const {
  color: #ff0000 !important;
}
.text-yellow-basic {
  color: #cc881c !important;
}

.dis-btn-caps button:disabled,
.dis-btn-caps .disabled {
  text-transform: uppercase !important;
  cursor: not-allowed;
  pointer-events: all;
}

.help-div {
  padding: 15px !important;
  border: 1px solid #000 !important;
  margin: 0 15px;
}
.help-box h4 {
  background: #00ff00;
  padding: 15px;
  color: #000;
}
.help-box strong {
  font-size: 22px;
  font-family: 'MYRIADPRO';
}
.help-box span {
  font-size: 18px;
  padding: 0 20px;
  font-family: 'MYRIADPRO';
}

@media screen and (max-width: 1699px) {
  .pricingTable .heading {
    font-size: 24px;
  }
  .pricingTable .pricing-content ul li {
    font-size: 20px;
  }
  .pricingTable .cont-color ul li {
    font-size: 20px !important;
  }
  .pricingTable-footer-price .dis-price {
    font-size: 22px;
  }
  .hert-mgt-list .text-red {
    font-size: 18px;
  }
}

@media screen and (max-width: 1499px) {
  .ribbon-ex {
    font-size: 16px !important;
  }
  .res-until {
    font-size: 18px;
  }
  .pedi-mgt-head {
    font-size: 24px;
  }
  .pricingTable .heading {
    font-size: 24px;
  }
  .pricingTable .pricing-content ul li {
    font-size: 20px;
  }
  .pricingTable .cont-color ul li {
    font-size: 20px !important;
  }
  // .pricingTable {
  //   margin: 0 0px 30px 0;
  // }
  .pedi-select .form-select {
    padding: 0.65rem 2.75rem 0.65rem 0.65rem;
  }
}

@media screen and (max-width: 1422px) {
  .hert-mgt-list h3 {
    font-size: 24px;
  }
  .pricingTable .cont-color ul li {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1399px) {
  .pricingTable .cont-color ul li {
    font-size: 16px !important;
  }

  .hert-mgt-list .res-text {
    font-size: 18px;
  }
  .hert-mgt-list h3,
  .hert-mgt-list .h3 {
    font-size: 22px;
  }
  .pricingTable-footer-price .dis-price {
    font-size: 18px;
  }
  .pre-exh ul li {
    margin-bottom: 7px !important;
  }
  .only-shows {
    padding-top: 20px !important;
  }
}

@media screen and (max-width: 1369px) {
  .herd-mgt .hert-mgt-list ul {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 1260px) {
  .ribbon-ex {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .pre-exh {
    margin-top: 0px !important;
  }
  .top-title {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .pre-tab-space {
    margin-top: 20px !important;
    line-height: 1.2;
  }
  .fixex-btn {
    left: 40%;
  }
  .deceased:before {
    top: -7px;
    font-size: 60px;
  }

  .resp-spac-none {
    margin-top: 0 !important;
    text-align: left;
  }
  .resp-spac-none .top-title {
    text-align: left !important;
  }
}

@media screen and (max-width: 520px) {
  .fixex-btn {
    left: 37%;
  }
}

@media screen and (max-width: 480px) {
  .fixex-btn {
    left: 35%;
  }
}
@media screen and (max-width: 380px) {
  .fixex-btn {
    left: 32%;
  }
  .ribbon-ex {
    font-size: 16px !important;
  }
}
// .myCustomCarousel .slick-arrow {
//   color: #070606 !important;
//   border: none;
//   outline: none;
//   background: #242424 !important;
// }
// .myCustomCarousel .slick-prev {
//   width: '30px';
//   height: '100px';
//   left: -2% !important;
//   z-index: 1;
// }
// .myCustomCarousel .slick-next {
//   width: '30px';
//   height: '100px';
//   right: -2% !important;
//   z-index: 1;
// }
.slider-container {
  position: relative;
}

.carousel-container {
  width: 80%;
  margin: 0 auto;
}

.slide {
  position: relative;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.slide-image {
  width: 100%;
  // height: auto;
  max-height: 650px;
}

// @media (max-width: 1600px) {
//   .slide-image {
//     height: 650px;
//   }
// }

.easy-banner .slide-image {
  width: 100%;
  // height: auto;
  max-height: 650px;
}
@media (max-width: 1599px) {
  .easy-banner .slide-image {
    height: auto;
  }
}

@media (max-width: 1499px) {
  .easy-banner .slide-image {
    margin-top: 12%;
  }
  .login-banner .slide-image {
    margin-top: 10%;
  }
}

@media (max-width: 1399px) {
  .easy-banner .slide-image {
    margin-top: 15%;
  }
  .login-banner .slide-image {
    margin-top: 15%;
  }
}

@media (max-width: 1299px) {
  .login-banner .slide-image {
    margin-top: 20%;
  }
}

@media (max-width: 1199px) {
  .login-banner .slide-image {
    margin-top: 2%;
  }
}

@media (max-width: 991px) {
  .easy-banner .slide-image {
    height: auto;
    margin-top: auto;
  }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
}
.overlay-text {
  // font-size: 24px;
  text-align: center;
  color: white;
}

.custom-arrow {
  position: absolute;
  top: 45%;
  font-size: 65px;
  color: #fff;
  cursor: pointer;
  z-index: 10;
  opacity: 0; /* Initially hidden */
  visibility: hidden;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}
.slider-container:hover .custom-arrow {
  opacity: 1; /* Fade in */
  visibility: visible;
}
.next {
  right: -10px;
  transform: translateX(30px); /* Start off-screen right */
}

.prev {
  left: -10px;
  transform: translateX(-30px); /* Start off-screen left */
}

.slider-container:hover .next {
  transform: translateX(0); /* Slide in from right */
}

.slider-container:hover .prev {
  transform: translateX(0); /* Slide in from left */
}

.custom-arrow:hover {
  color: #ffcc00;
  transform: scale(1.2);
}
.w-break{word-break: break-all;}
.fw-500{font-weight: 500;}
.pr-5{padding-right: 5px;}