// 
// _footer.scss
// 

.footer {
    bottom: 0;
    padding: 40px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    background: rgba(6, 37, 117, 0.1);
    color: #062575;
    height: 100px;
    box-shadow: 0 0px 4px rgba(15,34,58,.12);
    font-size: 18px;
}
  
@media (max-width: 992px) {
    .footer {
        left: 0;
    }
}

// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}