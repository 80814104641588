ol {
  list-style: none;
}

.tree-body {
  /* margin: 50px 0 100px; */
  text-align: center;
}

/* .container-1 {
  max-width: 1000px;
  padding: 0 10px;
  margin: 0 auto;
} */

.rectangle {
  position: relative;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

/* LEVEL-1 STYLES */
.level-1 {
  /* width: 50%; */
  margin: 0 auto 40px;
}

.level-1::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 20px;
  background: black;
}

/* LEVEL-2 STYLES */
.level-2-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.level-2-wrapper::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 26.5%;
  width: 49%;
  height: 2px;
  background: black;
}

.level-2-wrapper::after {
  display: none;
  content: '';
  position: absolute;
  left: -20px;
  bottom: -20px;
  width: calc(100% + 20px);
  height: 2px;
  background: black;
}

.level-2-wrapper li {
  position: relative;
}

.level-2-wrapper > li::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 20px;
  background: black;
}

.level-2 {
  /* width: 70%; */
  margin: 0 auto 40px;
  /* background: #f5cc7f; */
}

.level-2::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 20px;
  background: black;
}

.level-2::after {
  display: none;
  content: '';
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-100%, -50%);
  width: 20px;
  height: 2px;
  background: black;
}

/* LEVEL-3 STYLES */
.level-3-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  width: 90%;
  margin: 0 auto;
}

.level-3-wrapper::before {
  content: '';
  position: absolute;
  top: -20px;
  left: calc(28.4% - 5px);
  width: calc(48% + 10px);
  height: 2px;
  background: black;
}

.level-3-wrapper > li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 2px;
  height: 20px;
  background: black;
}

.level-3 {
  /* width: 100%; */
  margin: 0 auto 40px;
  /* background: #7b9fe0; */
}

.level-3::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 20px;
  background: black;
}

.level-3::after {
  display: none;
  content: '';
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-100%, -50%);
  width: 20px;
  height: 2px;
  background: black;
}

/* LEVEL-4 STYLES */

.level-4-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  width: 100%;
  margin: 0 auto;
}

.level-4-wrapper::before {
  content: '';
  position: absolute;
  top: -20px;
  left: calc(33% - 5px);
  width: calc(45% + 10px);
  height: 2px;
  background: black;
}

.level-4-wrapper > li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 2px;
  height: 20px;
  background: black;
}

.level-4 {
  margin-bottom: 20px;
  /* background: #f27c8d; */
}

/* MQ STYLES*/
/* @media screen and (max-width: 700px) {
  .rectangle {
    padding: 20px 10px;
  }

  .level-1,
  .level-2 {
    width: 100%;
  }

  .level-1 {
    margin-bottom: 20px;
  }

  .level-1::before,
  .level-2-wrapper > li::before {
    display: none;
  }

  .level-2-wrapper,
  .level-2-wrapper::after,
  .level-2::after {
    display: block;
  }

  .level-2-wrapper {
    width: 90%;
    margin-left: 10%;
  }

  .level-2-wrapper::before {
    left: -20px;
    width: 2px;
    height: calc(100% + 40px);
  }

  .level-2-wrapper > li:not(:first-child) {
    margin-top: 50px;
  }
} */
